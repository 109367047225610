
import React,{useEffect,useState} from 'react'
import axios from 'axios';
import { Helmet } from 'react-helmet';
import './JobPage.css'
import Footer from '../../../Footer';
import Heading from '../../../Heading';
import NavbarComponent from '../../../NavbarComponent';

function Pageid655edd10e0e46b0f7c75d751() {
 const[admitcardList,setadmitcardList]=useState([]);

  useEffect(()=>{
    //console.log("hello")
    axios.get('https://naukridekho-backend-92571da7a098.herokuapp.com/admitcard')
    .then(res=>{
      //console.log(res.data.admitcardData);
      setadmitcardList(res.data.admitcardData)
    })
    .catch(err=>{
      console.log(err);
    })
  },[]);
  //console.log(jobList);
  return (
   <>
   <Helmet>
    <title>{admitcardList[6]?.name}</title>
    <script async src="https://pagead2.googlesyndication.com/pagead/js/adsbygoogle.js?client=ca-pub-9504527545697458"
     crossorigin="anonymous"></script>
    <meta name='description'
    content={admitcardList[6]?.information} 
    />
    <meta name='keyword'
    content={admitcardList[6]?.details}
    />
    <meta name='date'content={admitcardList[6]?.important_date}
    />
   </Helmet>
   <Heading/>
   <NavbarComponent/>
   <main>
   <div className='cards'>
   <div className="single-card">
   <div>
        <span>Test Series</span>
          <h2>&nbsp;</h2>
          </div>
          
   </div>
   <div className="single-card">
                  <div>
                     <span>Syllabus</span>
                     <h2>&nbsp;</h2>
                  </div>
                  
               </div>
    <div className="single-card">
                  <div>
                     <span>Previous Year</span>
                     <h2>&nbsp;</h2>
                  </div>
                
                  </div>
    <div className="single-card">
                  <div>
                     <span>Videos</span>
                     <h2>&nbsp;</h2>
                  </div>
                     
                   </div>           

<div className="single-card" >
                  <div>
                     <span>Exam Pattern</span>
                     <h2>&nbsp;</h2>
                  </div>
                           
                   </div>           

</div>
</main>
{/*Job related details*/}
<div className='job-body'>
      <div className='div1'>
        <h2 className='job-h2'>Name of the post</h2>
         <h3>{admitcardList[6]?.name}</h3>
      </div>
      <div className='div1'>
        <h2 className='job-h2'>Date</h2>
          <h3>{admitcardList[6]?.date}</h3>
    </div>
    <div className='div1'>
        <h2 className='job-h2'>Information</h2>
          <h3>{admitcardList[6]?.information}</h3>
    </div>
    <div className='div1'>
        <h2 className ='job-h2'>Important Date </h2>
          <h3>{admitcardList[6]?.important_date}</h3>
    </div>
    <div className='div1'>
        <h2 className='job-h2'> Details</h2>
          <h3>{admitcardList[6]?.details}</h3>
    </div>
    <div className='div1'>
        <h2 className='job-h2'>Important links</h2>
          <h3>Mains Admit card :- < a href={admitcardList[6]?.important_links}>{admitcardList[6]?.important_links}</a></h3>
          <h3>Official site :- < a href="https://sbi.co.in/web/careers/crpd/po-pre-2023"> "https://sbi.co.in/web/careers/crpd/po-pre-2023"</a></h3>
          <h3>Result Link :-  < a href="https://ibpsonline.ibps.in/sbipoaug23/">https://ibpsonline.ibps.in/sbipoaug23/</a></h3>
    </div>
 </div>    
  <Footer/> 



</>
  )}


export default Pageid655edd10e0e46b0f7c75d751