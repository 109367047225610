
import React,{useEffect,useState} from 'react'
import axios from 'axios';
import { Helmet } from 'react-helmet';
import './JobPage.css'
import Footer from '../../../Footer';
import Heading from '../../../Heading';
import NavbarComponent from '../../../NavbarComponent';

function Pageid655e32b2f5a4b9f8210a6f4b() {
 const[admitcardList,setadmitcardList]=useState([]);

  useEffect(()=>{
    //console.log("hello")
    axios.get('https://naukridekho-backend-92571da7a098.herokuapp.com/admitcard')
    .then(res=>{
      //console.log(res.data.admitcardData);
      setadmitcardList(res.data.admitcardData)
    })
    .catch(err=>{
      console.log(err);
    })
  },[]);
  //console.log(jobList);
  return (
   <>
   <Helmet>
    <title>{admitcardList[5]?.name}</title>
    <script async src="https://pagead2.googlesyndication.com/pagead/js/adsbygoogle.js?client=ca-pub-9504527545697458"
     crossorigin="anonymous"></script>
    <meta name='description'
    content={admitcardList[5]?.information} 
    />
    <meta name='keyword'
    content={admitcardList[5]?.details}
    />
    <meta name='date'content={admitcardList[5]?.important_date}
    />
   </Helmet>
   <Heading/>
   <NavbarComponent/>
   <main>
   <div className='cards'>
   <div className="single-card">
   <div>
        <span>Test Series</span>
          <h2>&nbsp;</h2>
          </div>
          
   </div>
   <div className="single-card">
                  <div>
                     <span>Syllabus</span>
                     <h2>&nbsp;</h2>
                  </div>
                  
               </div>
    <div className="single-card">
                  <div>
                     <span>Previous Year</span>
                     <h2>&nbsp;</h2>
                  </div>
                
                  </div>
    <div className="single-card">
                  <div>
                     <span>Videos</span>
                     <h2>&nbsp;</h2>
                  </div>
                     
                   </div>           

<div className="single-card" >
                  <div>
                     <span>Exam Pattern</span>
                     <h2>&nbsp;</h2>
                  </div>
                           
                   </div>           

</div>
</main>
{/*Job related details*/}
<div className='job-body'>
      <div className='div1'>
        <h2 className='job-h2'>Name of the post</h2>
         <h3>{admitcardList[5]?.name}</h3>
      </div>
      <div className='div1'>
        <h2 className='job-h2'>Date</h2>
          <h3>{admitcardList[5]?.date}</h3>
    </div>
    <div className='div1'>
        <h2 className='job-h2'>Information</h2>
          <h3>{admitcardList[5]?.information}</h3>
    </div>
    <div className='div1'>
        <h2 className ='job-h2'>Important Date </h2>
          <h3>{admitcardList[5]?.important_date}</h3>
    </div>
    <div className='div1'>
        <h2 className='job-h2'> Details</h2>
          <h3>agelimit:-max 30 years. minimum:-NA,vacancy_details:-Principal&PGT ,Hostel Warden&Junior Secretariat Assistant,Lab Attendant&TGT,TGT (Misc.) & Accountant</h3>
    </div>
    <div className='div1'>
        <h2 className='job-h2'>Important links</h2>
        <h3>The following links may use to view allotted examination city and date of examination: Eklavya Model Residential Schools (EMRS) Recruitment 2023 for Principal:-</h3>
          <h3>< a href="https://examinationservices.nic.in/recsys23/DownloadAdmitCard/frmAuthforCity.aspx?appformid=102062311">Click Here</a></h3>
          <h3>Eklavya Model Residential Schools (EMRS)    Recruitment 2023 for Post Graduate Teacher:-</h3>
          <h3>< a href="https://examinationservices.nic.in/recsys23/DownloadAdmitCard/frmAuthforCity.aspx?appformid=102072311">Click Here</a></h3>
          <h3>Eklavya Model Residential Schools (EMRS)    Recruitment 2023 for Non Teaching Posts:-</h3>
          <h3>< a href="https://examinationservices.nic.in/recsys23/DownloadAdmitCard/frmAuthforCity.aspx?appformid=102082311">Click Here</a></h3>
          <h3>Eklavya Model Residential Schools (EMRS)  Recruitment 2023 for Trained Graduate Teacher:-</h3>
          <h3>< a href ="https://examinationservices.nic.in/recsys23/DownloadAdmitCard/frmAuthforCity.aspx?appformid=102092311">Click Here</a></h3>
          <h3>Eklavya Model Residential Schools (EMRS)  Recruitment 2023 for Hostel Warden:-</h3>
          <h3><a href="https://examinationservices.nic.in/recsys23/DownloadAdmitCard/frmAuthforCity.aspx?appformid=102102311">Click Here</a></h3>

    </div>
 </div>    
  <Footer/> 



</>
  )}


export default Pageid655e32b2f5a4b9f8210a6f4b