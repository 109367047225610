import React from 'react'
import { Link } from 'react-router-dom'
import { useEffect, useState } from 'react'
import axios from 'axios'
import NavbarComponent from '../NavbarComponent'
import Footer from '../Footer'
import Heading from '../Heading'
import './css/Latestjob.css'
import './Jobs/govermentjob/JobPage.css'
import loader from '../assets/loader.gif'
import { Helmet } from 'react-helmet'
export default function Latestjob() {
  const [admitcardList, setadmitcardList] = useState([]);
  const [isloading, setloading] = useState(true);

  useEffect(() => {
    //console.log("hello")
    axios.get('https://naukridekho-backend-92571da7a098.herokuapp.com/admitcard')
      .then(res => {
        //console.log(res.data.admitcardData);
        setadmitcardList(res.data.admitcardData)
        setloading(false);
      })
      .catch(err => {
        console.log(err);
        setloading(false);
      })
  }, []);

  return (
    <>
      <NavbarComponent />
      <Heading />
      <Helmet>
    <title>Admit Card</title>
    <script async src="https://pagead2.googlesyndication.com/pagead/js/adsbygoogle.js?client=ca-pub-9504527545697458"
     crossorigin="anonymous"></script>
    <meta name='get all notification of Latest admit card'
    content={admitcardList[50]?.information} 
    />
    <meta name='keyword'
    content="
    admit card download 2023,
    cbse admit card download,
    ssc.nic.in admit card 2023,
    online admit card download,
    admit card cbse,
    cbse.nic.in admit card 2023,
    www.rajasthan.gov.in admit card,
    ssc admit card"
    />
    
    <link rel="canonical" href='/Admitcard'/>
   </Helmet>
      {isloading &&
        <img style={{ width: '300px', marginLeft: '38%' }} src={loader} />
      }
      {!isloading &&
        <div className='lat-page-body'>
          <div className='job-body'>
          <div className='div1'>
              <Link to="/HPSC-HCS-Mains-Admit-Card-2024">
                <h3>{admitcardList[89]?.name} {admitcardList[89]?.date}</h3>
              </Link>
            </div>
          <div className='div1'>
              <Link to="/Indian-Airforce-Agniveer-Intake-01/2025-Admit-Card">
                <h3>{admitcardList[88]?.name} {admitcardList[88]?.date}</h3>
              </Link>
            </div>
          <div className='div1'>
              <Link to="/Bihar-School-Teacher-TRE-3-Admit-Card-2024-Exam-Center-Details-Available">
                <h3>{admitcardList[87]?.name} {admitcardList[87]?.date}</h3>
              </Link>
            </div>
          <div className='div1'>
              <Link to="/Jharkhand-JPSC-Pre-2024-Admit Card">
                <h3>{admitcardList[86]?.name} {admitcardList[86]?.date}</h3>
              </Link>
            </div>
          <div className='div1'>
              <Link to="/Bihar-School-Teacher-TRE-3.0-Exam-Date">
                <h3>{admitcardList[85]?.name} {admitcardList[85]?.date}</h3>
              </Link>
            </div>
          <div className='div1'>
              <Link to="/NTA-CUET-PG-2024-Subject-Wise-Exam-Schedule">
                <h3>{admitcardList[84]?.name} {admitcardList[84]?.date}</h3>
              </Link>
            </div>
          <div className='div1'>
              <Link to="/National-Insurance-NIACL-AO-Admit-Card-2024">
                <h3>{admitcardList[83]?.name} {admitcardList[83]?.date}</h3>
              </Link>
            </div>
          <div className='div1'>
              <Link to="/RPSC-Statistical-Officer-Admit-Card-2024">
                <h3>{admitcardList[82]?.name} {admitcardList[82]?.date}</h3>
              </Link>
            </div>
          <div className='div1'>
              <Link to="/National-Insurance-NIACL-AO-Exam-Date-2024">
                <h3>{admitcardList[81]?.name} {admitcardList[81]?.date}</h3>
              </Link>
            </div>
          <div className='div1'>
              <Link to="/UPSSSC-Instructor-2022-Admit-Card">
                <h3>{admitcardList[80]?.name} {admitcardList[80]?.date}</h3>
              </Link>
            </div>
          <div className='div1'>
              <Link to="/Bihar-BSSTET-2023-Admit-Card">
                <h3>{admitcardList[79]?.name} {admitcardList[79]?.date}</h3>
              </Link>
            </div>
          <div className='div1'>
              <Link to="/IBPS-PO-XIII-Interview-Letter">
                <h3>{admitcardList[78]?.name} {admitcardList[78]?.date}</h3>
              </Link>
            </div>
          <div className='div1'>
              <Link to="/BPSC-Various-Agriculture-Post-Exam-Date-2024">
                <h3>{admitcardList[77]?.name} {admitcardList[77]?.date}</h3>
              </Link>
            </div>
          <div className='div1'>
              <Link to="/RSMSSB-Sangnak-Computer-Exam-Date-2024">
                <h3>{admitcardList[76]?.name} {admitcardList[76]?.date}</h3>
              </Link>
            </div>
          <div className='div1'>
              <Link to="/Delhi-High-Court-PA-Stage-II-Admit-Card-2024">
                <h3>{admitcardList[75]?.name} {admitcardList[75]?.date}</h3>
              </Link>
            </div>
          <div className='div1'>
              <Link to="/Bihar-Police-BPSSC-Sub-Inspector-SI-Phase-II-Mains-Admit-Card-2024">
                <h3>{admitcardList[74]?.name} {admitcardList[74]?.date}</h3>
              </Link>
            </div>
          <div className='div1'>
              <Link to="/General-Insurance-GIC-Scale-I-Various-Post-Exam-Date-2024">
                <h3>{admitcardList[73]?.name} {admitcardList[73]?.date}</h3>
              </Link>
            </div>
          <div className='div1'>
              <Link to="/ssc-gd-admit-card-2023">
                <h3>{admitcardList[72]?.name} {admitcardList[72]?.date}</h3>
              </Link>
            </div>
          <div className='div1'>
              <Link to="/NIFT-2024-Admission-Exam-City-Admit-Card">
                <h3>{admitcardList[71]?.name} {admitcardList[71]?.date}</h3>
              </Link>
            </div>
          <div className='div1'>
              <Link to="/NTA-CUREC-IGNOU-Admit-Card-2024">
                <h3>{admitcardList[70]?.name} {admitcardList[70]?.date}</h3>
              </Link>
            </div>
          <div className='div1'>
              <Link to="/CGPSC-Pre-2023-Admit-Card">
                <h3>{admitcardList[69]?.name} {admitcardList[69]?.date}</h3>
              </Link>
            </div>
          <div className='div1'>
              <Link to="/UPPSC-RO-ARO-2023-Admit-Card">
                <h3>{admitcardList[68]?.name} {admitcardList[68]?.date}</h3>
              </Link>
            </div>
          <div className='div1'>
              <Link to="/Patna-High-Court-Assistant-2023-Interview-Letter">
                <h3>{admitcardList[67]?.name} {admitcardList[67]?.date}</h3>
              </Link>
            </div>
          <div className='div1'>
              <Link to="/AFCAT-Admit-Card">
                <h3>{admitcardList[66]?.name} {admitcardList[66]?.date}</h3>
              </Link>
            </div>
          <div className='div1'>
              <Link to="/Navy-INCET-Admit-Card">
                <h3>{admitcardList[65]?.name} {admitcardList[65]?.date}</h3>
              </Link>
            </div>
          <div className='div1'>
              <Link to="/JSSC-CGL-2023-ADMIT-CARD">
                <h3>{admitcardList[64]?.name} {admitcardList[64]?.date}</h3>
              </Link>
            </div>
          <div className='div1'>
              <Link to="/CSIR-CASE-SO-/-ASO-Exam-Date-2024">
                <h3>{admitcardList[63]?.name} {admitcardList[63]?.date}</h3>
              </Link>
            </div>
          <div className='div1'>
              <Link to="/NTA-AISSEE-2024-Admit-Card">
                <h3>{admitcardList[62]?.name} {admitcardList[62]?.date}</h3>
              </Link>
            </div>
          <div className='div1'>
              <Link to="/SSB-HC,-ASI,-SI-Admit-Card-2024">
                <h3>{admitcardList[61]?.name} {admitcardList[61]?.date}</h3>
              </Link>
            </div>
          <div className='div1'>
              <Link to="/SBI-CBO-Admit-Card-2024">
                <h3>{admitcardList[60]?.name} {admitcardList[60]?.date}</h3>
              </Link>
            </div>
          <div className='div1'>
              <Link to="/UP-Police-Workshop-Staff-2022-Exam-Date">
                <h3>{admitcardList[59]?.name} {admitcardList[59]?.date}</h3>
              </Link>
            </div>
          <div className='div1'>
              <Link to="/UP-Police-Head-Operator-2022-Exam-Date">
                <h3>{admitcardList[58]?.name} {admitcardList[58]?.date}</h3>
              </Link>
            </div>
          <div className='div1'>
              <Link to="/UP-Police-Assistant-Operator-2022-Exam-Date">
                <h3>{admitcardList[57]?.name} {admitcardList[57]?.date}</h3>
              </Link>
            </div>
          <div className='div1'>
              <Link to="/DPS-DAE-JPA-/-JSK-Exam-Date-2024">
                <h3>{admitcardList[56]?.name} {admitcardList[56]?.date}</h3>
              </Link>
            </div>
          <div className='div1'>
              <Link to="/UIIC-Assistant-Exam-Date-2024">
                <h3>{admitcardList[55]?.name} {admitcardList[55]?.date}</h3>
              </Link>
            </div>
          <div className='div1'>
              <Link to="/NTA-JEEMAIN-2024-Exam-City-Details">
                <h3>{admitcardList[54]?.name} {admitcardList[54]?.date}</h3>
              </Link>
            </div>
          <div className='div1'>
              <Link to="/RSMSSB-Informatic-Assistant-2023-Admit-Card">
                <h3>{admitcardList[53]?.name} {admitcardList[53]?.date}</h3>
              </Link>
            </div>
          <div className='div1'>
              <Link to="/CTET-January-2024-Exam-City-Details">
                <h3>{admitcardList[52]?.name} {admitcardList[52]?.date}</h3>
              </Link>
            </div>
          <div className='div1'>
              <Link to="/Bihar-Police-BPSSC-SI-Prohibition-Admit-Card-2024">
                <h3>{admitcardList[51]?.name} {admitcardList[51]?.date}</h3>
              </Link>
            </div>
          <div className='div1'>
              <Link to="/CISF-Constable-Tradesman-2022-DME-Admit-Card">
                <h3>{admitcardList[50]?.name} {admitcardList[50]?.date}</h3>
              </Link>
            </div>
            
          <div className='div1'>
              <Link to="/NTA-Military-Nursing-Services-MNS-Exam-Admit-Card-2024">
                <h3>{admitcardList[49]?.name} {admitcardList[49]?.date}</h3>
              </Link>
            </div>
            <div className='div1'>
              <Link to="/MHA-IB-ACIO-2023-Admit Card">
                <h3>{admitcardList[48]?.name} {admitcardList[48]?.date}</h3>
              </Link>
            </div>
          <div className='div1'>
              <Link to="/MHA-IB-ACIO-2023-Admit Card">
                <h3>{admitcardList[48]?.name} {admitcardList[48]?.date}</h3>
              </Link>
            </div>
          <div className='div1'>
              <Link to="/SSC-Delhi-Police-Constable-2023-PET-/-PST-Admit-Card">
                <h3>{admitcardList[47]?.name} {admitcardList[47]?.date}</h3>
              </Link>
            </div>
          <div className='div1'>
              <Link to="/IIT-JAM-2024-Admit-Card">
                <h3>{admitcardList[46]?.name} {admitcardList[46]?.date}</h3>
              </Link>
            </div>
          <div className='div1'>
              <Link to="/SSC-CHSL-2023-Paper-II-Admit-Card">
                <h3>{admitcardList[45]?.name} {admitcardList[45]?.date}</h3>
              </Link>
            </div>
          <div className='div1'>
              <Link to="/SSC-CPO-SI-2023-Paper-II-Admit-Card">
                <h3>{admitcardList[44]?.name} {admitcardList[44]?.date}</h3>
              </Link>
            </div>
          <div className='div1'>
              <Link to="/GATE-2024-Admit-Card">
                <h3>{admitcardList[43]?.name} {admitcardList[43]?.date}</h3>
              </Link>
            </div>
          <div className='div1'>
              <Link to="/CISF-ASI-Steno-/-HC-Ministerial-2022-Skill-Test-Admit-Card">
                <h3>{admitcardList[42]?.name} {admitcardList[42]?.date}</h3>
              </Link>
            </div>
          <div className='div1'>
              <Link to="/ICAR-IARI-Technician-2021-Tier-II-Admit-Card">
                <h3>{admitcardList[41]?.name} {admitcardList[41]?.date}</h3>
              </Link>
            </div>
          <div className='div1'>
              <Link to="/Bihar-BTSC-ANM-2022-Admit-Card-2023">
                <h3>{admitcardList[40]?.name} {admitcardList[40]?.date}</h3>
              </Link>
            </div>
          <div className='div1'>
              <Link to="/RPSC-Assistant-Professor-Admit-Card-2023">
                <h3>{admitcardList[39]?.name} {admitcardList[39]?.date}</h3>
              </Link>
            </div>
          <div className='div1'>
              <Link to="/RPSC-Librarian-/-PTI-Admit-Card-2023">
                <h3>{admitcardList[38]?.name} {admitcardList[38]?.date}</h3>
              </Link>
            </div>
          <div className='div1'>
              <Link to="/SSC-Stenographer-2023-Skill-Test-Admit-Card">
                <h3>{admitcardList[37]?.name} {admitcardList[37]?.date}</h3>
              </Link>
            </div>
          <div className='div1'>
              <Link to="/Pageid6585b9cef952fb0d800b6201">
                <h3>{admitcardList[36]?.name} {admitcardList[36]?.date}</h3>
              </Link>
            </div>
          <div className='div1'>
              <Link to="/Pageid6585b712f952fb0d800b61fd">
                <h3>{admitcardList[35]?.name} {admitcardList[35]?.date}</h3>
              </Link>
            </div>
          <div className='div1'>
              <Link to="/Pageid658466b1351fbc71550574d1">
                <h3>{admitcardList[34]?.name} {admitcardList[34]?.date}</h3>
              </Link>
            </div>
          <div className='div1'>
              <Link to="/Pageid65846508351fbc71550574cf">
                <h3>{admitcardList[33]?.name} {admitcardList[33]?.date}</h3>
              </Link>
            </div>
          <div className='div1'>
              <Link to="/Pageid65846379351fbc71550574cd">
                <h3>{admitcardList[32]?.name} {admitcardList[32]?.date}</h3>
              </Link>
            </div>
          <div className='div1'>
              <Link to="/Pageid658329acf531b4b750ae34e0">
                <h3>{admitcardList[31]?.name} {admitcardList[31]?.date}</h3>
              </Link>
            </div>
          <div className='div1'>
              <Link to="/Pageid65832779f531b4b750ae34c8">
                <h3>{admitcardList[30]?.name} {admitcardList[30]?.date}</h3>
              </Link>
            </div>
          <div className='div1'>
              <Link to="/Pageid6583261cf531b4b750ae34c">
                <h3>{admitcardList[29]?.name} {admitcardList[29]?.date}</h3>
              </Link>
            </div>
          <div className='div1'>
              <Link to="/Pageid6583246ef531b4b750ae34c4">
                <h3>{admitcardList[28]?.name} {admitcardList[28]?.date}</h3>
              </Link>
            </div>
          <div className='div1'>
              <Link to="/Pageid65832370f531b4b750ae34c0">
                <h3>{admitcardList[27]?.name} {admitcardList[27]?.date}</h3>
              </Link>
            </div>
          <div className='div1'>
              <Link to="/Pageid65831ef8f531b4b750ae3474">
                <h3>{admitcardList[26]?.name} {admitcardList[26]?.date}</h3>
              </Link>
            </div>
          <div className='div1'>
              <Link to="/Pageid6581957580e67a73f78e3b1f">
                <h3>{admitcardList[25]?.name} {admitcardList[25]?.date}</h3>
              </Link>
            </div>
          <div className='div1'>
              <Link to="/Pageid6581200080e67a73f78e38e9">
                <h3>{admitcardList[24]?.name} {admitcardList[24]?.date}</h3>
              </Link>
            </div>
          <div className='div1'>
              <Link to="/Pageid657886e60398ae575fa2f02c">
                <h3>{admitcardList[20]?.name} {admitcardList[20]?.date}</h3>
              </Link>
            </div>
          <div className='div1'>
              <Link to="/Pageid6575e429868f14a1b3cf1153">
                <h3>{admitcardList[19]?.name} {admitcardList[19]?.date}</h3>
              </Link>
            </div>
          <div className='div1'>
              <Link to="/Pageid6574674e7b4ad8e67ef3bfb4">
                <h3>{admitcardList[18]?.name} {admitcardList[18]?.date}</h3>
              </Link>
            </div>
          <div className='div1'>
              <Link to="/Pageid6574652b7b4ad8e67ef3bf84">
                <h3>{admitcardList[17]?.name} {admitcardList[17]?.date}</h3>
              </Link>
            </div>
          <div className='div1'>
              <Link to="/Pageid6573483a62f25dfc835fdefa">
                <h3>{admitcardList[16]?.name} {admitcardList[16]?.date}</h3>
              </Link>
            </div>
          <div className='div1'>
              <Link to="/Pageid6570aa0408506fd46394e66e">
                <h3>{admitcardList[15]?.name} {admitcardList[15]?.date}</h3>
              </Link>
            </div>
          <div className='div1'>
              <Link to="/Pageid65709e9e08506fd46394e537">
                <h3>{admitcardList[14]?.name} {admitcardList[14]?.date}</h3>
              </Link>
            </div>
          <div className='div1'>
              <Link to="/Pageid656f590e9ab81286ba2ff888">
                <h3>{admitcardList[13]?.name} {admitcardList[13]?.date}</h3>
              </Link>
            </div>
          <div className='div1'>
              <Link to="/Pageid656e0c82335f485f1bbeb9fa">
                <h3>{admitcardList[12]?.name} {admitcardList[12]?.date}</h3>
              </Link>
            </div>
          <div className='div1'>
              <Link to="/Pageid656cbcee0beb0bfb3ea074b2">
                <h3>{admitcardList[11]?.name} {admitcardList[11]?.date}</h3>
              </Link>
            </div>
          <div className='div1'>
              <Link to="/Pageid656ca0760beb0bfb3ea07157">
                <h3>{admitcardList[10]?.name} {admitcardList[10]?.date}</h3>
              </Link>
            </div>
          <div className='div1'>
              <Link to="/Pageid656a0378a40bc2f1aa74cf09">
                <h3>{admitcardList[9]?.name} {admitcardList[9]?.date}</h3>
              </Link>
            </div>
          <div className='div1'>
              <Link to="/Pageid6568b4f72829fd098633eb55">
                <h3>{admitcardList[8]?.name} {admitcardList[8]?.date}</h3>
              </Link>
            </div>
          <div className='div1'>
              <Link to="/Pageid65662664b2e39cfa47b67025">
                <h3>{admitcardList[7]?.name} {admitcardList[7]?.date}</h3>
              </Link>
            </div>
          <div className='div1'>
              <Link to="/Pageid655edd10e0e46b0f7c75d751">
                <h3>{admitcardList[6]?.name} {admitcardList[6]?.date}</h3>
              </Link>
            </div>
          <div className='div1'>
              <Link to="/Pageid655e32b2f5a4b9f8210a6f4b">
                <h3>{admitcardList[5]?.name} {admitcardList[5]?.date}</h3>
              </Link>
            </div>
          <div className='div1'>
              <Link to="/Pageid654678078dcc457c81d5c4f4">
                <h3>{admitcardList[4]?.name} {admitcardList[4]?.date}</h3>
              </Link>
            </div>
          <div className='div1'>
              <Link to="/Pageid6546296e04bae4cc8dbae151">
                <h3>{admitcardList[3]?.name} {admitcardList[3]?.date}</h3>
              </Link>
            </div>
          <div className='div1'>
              <Link to="/Pageid6545fa7404bae4cc8dbae121">
                <h3>{admitcardList[2]?.name} {admitcardList[2]?.date}</h3>
              </Link>
            </div>
            <div className='div1'>
              <Link to="/Pageid6545f3df04bae4cc8dbae10d">
                <h3>{admitcardList[1]?.name} {admitcardList[1]?.date}</h3>
              </Link>
            </div>
            <div className='div1'>
              <Link to="/Pageid654557454fabd5424e65971b">
                <h3>{admitcardList[0]?.name} {admitcardList[0]?.date}</h3>
              </Link>
            </div>
          </div>
        </div>
      }
      <Footer />
    </>

  )
}



