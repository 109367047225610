import React from 'react'
import { Link } from 'react-router-dom';
import Menubar from './Menubar';
import NavbarComponent from './NavbarComponent';
export default function Heading() {
  return (
    <>
    <div>
    <Menubar/>
    <NavbarComponent/>
    </div>
    </>
  )
}
