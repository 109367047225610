
import React,{useEffect,useState} from 'react'
import axios from 'axios';
import loader from '../../../assets/loader.gif'
import '../govermentjob/JobPage.css'
import Footer from '../../../Footer';
import Heading from '../../../Heading';
import NavbarComponent from '../../../NavbarComponent';
import { Helmet } from 'react-helmet';
function Pageid657017429ab81286ba300057() {
 const[importantList,setimportantList]=useState([]);
 const[isloading,setloading]=useState(true);

  useEffect(()=>{
    //console.log("hello")
    axios.get('https://naukridekho-backend-92571da7a098.herokuapp.com/important')
    .then(res=>{
      //console.log(res.data.importantData);
      setimportantList(res.data.importantData)
      setloading(false);
    })
    .catch(err=>{
      console.log(err);
      setloading(false);
    })
  },[]);
  //console.log(jobList);
  return (
   <>
   <Helmet>
   <script async src="https://pagead2.googlesyndication.com/pagead/js/adsbygoogle.js?client=ca-pub-9504527545697458"
     crossorigin="anonymous"></script>
   </Helmet>
   <Heading/>
   <NavbarComponent/>
   <main>
   <div className='cards'>
   <div className="single-card">
   <div>
        <span>Test Series</span>
          <h2>&nbsp;</h2>
          </div>
          
   </div>
   <div className="single-card">
                  <div>
                     <span>Syllabus</span>
                     <h2>&nbsp;</h2>
                  </div>
                  
               </div>
    <div className="single-card">
                  <div>
                     <span>Previous Year</span>
                     <h2>&nbsp;</h2>
                  </div>
                
                  </div>
    <div className="single-card">
                  <div>
                     <span>Videos</span>
                     <h2>&nbsp;</h2>
                  </div>
                     
                   </div>           

<div className="single-card" >
                  <div>
                     <span>Exam Pattern</span>
                     <h2>&nbsp;</h2>
                  </div>
                           
                   </div>           

</div>
</main>
{/*Job related details*/}
{isloading &&
<img style={{width:'300px', marginLeft:'38%'}} src={loader} />
}
{!isloading &&
<div className='job-body'>
      <div className='div1'>
        <h2 className='job-h2'>Name of the post</h2>
         <h3>{importantList[3]?.name}</h3>
      </div>
      <div className='div1'>
        <h2 className='job-h2'>Date</h2>
          <h3>{importantList[3]?.date}</h3>
    </div>
    <div className='div1'>
        <h2 className='job-h2'>Information</h2>
          <h3>{importantList[3]?.information}</h3>
    </div>
    <div className='div1'>
        <h2 className ='job-h2'>Important Date </h2>
        <h3>{importantList[3]?.important_date}</h3>
        
    </div>
    <div className='div1'>
        <h2 className='job-h2'> Details</h2>
          <h3>{importantList[3]?.details}</h3>
    </div>

    
    <div className='div1'>
        <h2 className='job-h2'>Important links</h2>
          <h3>< a href={importantList[3]?.important_links}>Official site {importantList[3]?.important_links}</a></h3>
    </div>
 </div>   
} 
  <Footer/> 



</>
  )}


export default Pageid657017429ab81286ba300057