
import React,{useEffect,useState} from 'react'
import axios from 'axios';

import '../govermentjob/JobPage.css'
import Footer from '../../../Footer';
import Heading from '../../../Heading';
import NavbarComponent from '../../../NavbarComponent';
import { Helmet } from 'react-helmet';
function Pageid655fe7f73a6d7e513a4f2aa7() {
 const[importantList,setimportantList]=useState([]);

  useEffect(()=>{
    //console.log("hello")
    axios.get('https://naukridekho-backend-92571da7a098.herokuapp.com/important')
    .then(res=>{
      //console.log(res.data.importantData);
      setimportantList(res.data.importantData)
    })
    .catch(err=>{
      console.log(err);
    })
  },[]);
  //console.log(jobList);
  return (
   <>
   <Helmet>
   <script async src="https://pagead2.googlesyndication.com/pagead/js/adsbygoogle.js?client=ca-pub-9504527545697458"
     crossorigin="anonymous"></script>
   </Helmet>
   <Heading/>
   <NavbarComponent/>
   <main>
   <div className='cards'>
   <div className="single-card">
   <div>
        <span>Test Series</span>
          <h2>&nbsp;</h2>
          </div>
          
   </div>
   <div className="single-card">
                  <div>
                     <span>Syllabus</span>
                     <h2>&nbsp;</h2>
                  </div>
                  
               </div>
    <div className="single-card">
                  <div>
                     <span>Previous Year</span>
                     <h2>&nbsp;</h2>
                  </div>
                
                  </div>
    <div className="single-card">
                  <div>
                     <span>Videos</span>
                     <h2>&nbsp;</h2>
                  </div>
                     
                   </div>           

<div className="single-card" >
                  <div>
                     <span>Exam Pattern</span>
                     <h2>&nbsp;</h2>
                  </div>
                           
                   </div>           

</div>
</main>
{/*Job related details*/}
<div className='job-body'>
      <div className='div1'>
        <h2 className='job-h2'>Name of the post</h2>
         <h3>{importantList[0]?.name}</h3>
      </div>
      <div className='div1'>
        <h2 className='job-h2'>Date</h2>
          <h3>{importantList[0]?.date}</h3>
    </div>
    <div className='div1'>
        <h2 className='job-h2'>Information</h2>
          <h3>{importantList[0]?.information}</h3>
    </div>
    <div className='div1'>
        <h2 className ='job-h2'>Important Date </h2>
        <h3>OMR sheet 9:45 am to 11:20 am</h3>
        <h3>Question-Answer Booklet 11:25am to 1:05pm</h3>
          <h3>06/02/2024(Tuesday):-IIT & other vocational subjects</h3>
          <h3>07/02/2024(Wednesday):-Commerce/Home Science</h3>
          <h3>08/02/2024(Thursday):-Kharia/khortha/kurmali/Nagpuri/panch pargania</h3>
          <h3>09/02/2024(Friday):-Arabic/Persian/Ho/Mundari/Santhali/Oraon</h3>
          <h3>10/02/2024(Saturday):-urdu/Bengali/Oriya</h3>
          <h3>12/02/2024(Monday):-Social Science</h3>
          <h3>13/02/2024(Tuesday):-Music</h3>
          <h3>16/02/2024(Friday):-Mathematics</h3>
          <h3>19/02/2024(Monday):-Hindi(course Aand course B )</h3>
          <h3>21/02/2024(Wednesday):-Science</h3>
          <h3> 23/02/2024(Friday):-Sanskrit</h3>
          <h3>26/02/2024(Monday):-English</h3>
    </div>
    <div className='div1'>
        <h2 className='job-h2'> Details</h2>
          <h3>{importantList[0]?.details}</h3>
    </div>

    
    <div className='div1'>
        <h2 className='job-h2'>Important links</h2>
          <h3>< a href={importantList[0]?.important_links}>Official site {importantList[0]?.important_links}</a></h3>
    </div>
 </div>    
  <Footer/> 



</>
  )}


export default Pageid655fe7f73a6d7e513a4f2aa7