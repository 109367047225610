import React from 'react'
//import './css/Topheader.css'

export default function Topheader() {
  return (
    <>
 <div className='job-card-head'>
    <div className='job-card1'>
        <h2>RAILWAYS</h2>
    </div>
    <div className='job-card2'>
        <h2>SSC</h2>
    </div>
    <div className='job-card3'>
        <h2>BANKING</h2>
    </div>
    <div className='job-card4'>
        <h2>UPSC</h2>
    </div>
 </div>
 <div className='job-card-head'>
 <div className='job-card5'>
        <h2>NTA</h2>
    </div>
    <div className='job-card6'>
        <h2>CBSE</h2>
    </div>
    <div className='job-card7'>
        <h2>STATE BOARD</h2>
    </div>
    <div className='job-card8'>
        <h2>PRIVATE COMPANY</h2>
    </div>
    </div>
 
 
 
 
 </>
 
  
 
 
 
 
  )
}
