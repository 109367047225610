import React from 'react'
import './css/LoginPage.css'
import { Link } from 'react-router-dom';
import { GoogleLogin } from '@react-oauth/google';
import { jwtDecode } from "jwt-decode";
import Footer from './Footer';
import Heading from './Heading';
import Menubar from './Menubar';
import NavbarComponent from './NavbarComponent';
import { Helmet } from 'react-helmet';

export default function LoginPage() {
  return (
    <>
    <Helmet>
    <title>Login</title>
    <script async src="https://pagead2.googlesyndication.com/pagead/js/adsbygoogle.js?client=ca-pub-9504527545697458"
     crossorigin="anonymous"></script>
    <meta name='description'
    content="Login to get access of exam pattern ,syllabus
    and  live exam prier to enter into final exam "
    />
    <meta name='keyword'
    content="naukridekho login"
    />
    
   </Helmet>
    <Heading/>
    <NavbarComponent/>
      <div className='bdy'>
        <div className="center">
          <h1> Login</h1>
          <form method='post'>
            <div className='txt_field' required>
              <input type="password" required></input>
              <span></span>
              <label>EMAIL</label>
            </div>
            <div className='txt_field' required>
              <input type="password" required></input>
              <span></span>
              <div>
              </div>
              <label>PASSWORD</label>
            </div>
            <input type="submit" value="LOGIN"></input>
            <GoogleLogin
              onSuccess={credentialResponse => {
                const decoded = jwtDecode(credentialResponse.credential);

                //console.log(credentialResponse);
                console.log(decoded);
              }}
              onError={() => {
                console.log('Login Failed');
              }}
            />
            <br></br>
            <div class="pass">FORGOT YOUR PASSWORD?
            </div>
            <Link to="/Register">Sign Up</Link><br></br>
            <Link to="/AdminLogin">Login as Admin</Link>
          </form>
        </div>

      </div>
<Footer/>
    </>
  )
}
