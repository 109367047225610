import React, { useState } from 'react'
import './Navbarcopy.css'
import { Link } from 'react-router-dom';
//import logo from '../assets/logo.jpg'
import menu from './assets/menu.png'
//import image from '../assets/bzeetech-main.png'
export default function Navbar() {
    const [mobMenu,setmobMenu] = useState(false);
    const tooglemenu =()=>{
        mobMenu ? setmobMenu(false) :setmobMenu(true);
    }
  return (
    <>
    <nav className='container1'>
    <h2>NAUKRIDEkHO <span>.INFO</span></h2>
    <ul className={mobMenu?'': 'hide-menu'}>
        <li><Link  to="/">HOME</Link></li>
        <li><Link  to="/Latestjob">LATEST JOBS</Link></li>
        <li><Link  to="/">PRIVATE JOBS</Link></li>
        <li><Link  to="/Results">RESULTS</Link></li>
        <li><Link  to="/admitcard">ADMIT CARDS</Link></li>
        <li><Link  to="/">SYLLABUS</Link></li>
        <li><button className='btn-btn'>LOGIN</button></li>
    </ul>
   <img src={menu} alt="" className='menu' alt="bzeetech" onClick={tooglemenu}/>
    </nav>
    <div className='bzeetech-main'>
    {/*<img src={image} alt="" className='main-image'/>*/}
    </div>
    </>
  )
}
