import React from 'react'
import { Link } from 'react-router-dom';
import './css/Heading.css'
import logo from './images/logo .jpg'
export default function Menubar() {
  
  return (
    <div className='background'>
    <div>
  <div className='btn-class'>
    <button className="bt1"><Link className="link" to="/LoginPage">Login/Register</Link></button>
  </div>
    <div className='nav-div'>
       <div className='nav-head'>
        <h1><img src={logo}alt=''/>
          Naukridekho.Info
          </h1>
        <div className='head-span'>
        <h1>Aap taiyari kare naukari khojna hamara kam hai,Latest Jobs of 2023 Har naukari ki jankari aab aap tak</h1>
        </div>
      </div>

    </div>
    </div>   
    <div/>
    </div>
  )
}
