import './App.css';
import { HashRouter as Router, Routes, Route, BrowserRouter,  } from "react-router-dom";
import HomePage from './HomePage';
import LoginPage from './LoginPage';
import AdminLogin from './admin/AdminLogin'
import Register from './Register';
import Admitcard from './home-content/Admitcard';
import Latestjob from './home-content/Latestjob';
import Results from './home-content/Results';
import Governmentjob from './home-content/Results';
import Jobnews from './home-content/Jobnews';
import Syllabus from './home-content/Syllabus';
import Latestjobformat from './home-content/Jobs/govermentjob/Latestjobformat';
import Footer_content from './Footer_content';
import Footer from './Footer.js';

//import for exam pattern
import ExamPatternsbi from './home-content/Jobs/ExamPattern/ExamPatternsbi'
//import Panjabpolice from './home-content/Jobs/ExamPattern/Panjabpolice'




//importa for admin page
import Dashboard from './admin/Dashboard';
import AddLatestjob from './admin/AddLatestjob'
import Addadmitcard from './admin/Addadmitcard';
import Addupdates from './admin/Addupdates';
import Addresult from './admin/Addresult';
import Addimportant from './admin/Addimportant';
import Addjobnews from './admin/Addjobnews';

//import for footer pages
import Contactus from './FooterPages/Contactus'
import Privacy from './FooterPages/Privacy'
import Aboutus from './FooterPages/Aboutus';
import Conditions from './FooterPages/Condition';
import Disclaimer from './FooterPages/Disclaimer';



//import for admit card
import Pageid654557454fabd5424e65971b from "./home-content/Jobs/admitcard/Pageid654557454fabd5424e65971b";
import Pageid6545f3df04bae4cc8dbae10d from "./home-content/Jobs/admitcard/Pageid6545f3df04bae4cc8dbae10d";
import Pageid6545fa7404bae4cc8dbae121 from "./home-content/Jobs/admitcard/Pageid6545fa7404bae4cc8dbae121";
import Pageid6546296e04bae4cc8dbae151 from "./home-content/Jobs/admitcard/Pageid6546296e04bae4cc8dbae151";
import Pageid654678078dcc457c81d5c4f4 from "./home-content/Jobs/admitcard/Pageid654678078dcc457c81d5c4f4";
import Pageid655e32b2f5a4b9f8210a6f4b from "./home-content/Jobs/admitcard/Pageid655e32b2f5a4b9f8210a6f4b";
import Pageid655edd10e0e46b0f7c75d751 from "./home-content/Jobs/admitcard/Pageid655edd10e0e46b0f7c75d751";
import Pageid65662664b2e39cfa47b67025 from "./home-content/Jobs/admitcard/Pageid65662664b2e39cfa47b67025";
import Pageid6568b4f72829fd098633eb55 from "./home-content/Jobs/admitcard/Pageid6568b4f72829fd098633eb55";
import Pageid656a0378a40bc2f1aa74cf09 from "./home-content/Jobs/admitcard/Pageid656a0378a40bc2f1aa74cf09";
import Pageid656ca0760beb0bfb3ea07157 from "./home-content/Jobs/admitcard/Pageid656ca0760beb0bfb3ea07157";
import Pageid656cbcee0beb0bfb3ea074b2 from "./home-content/Jobs/admitcard/Pageid656cbcee0beb0bfb3ea074b2";
import Pageid656e0c82335f485f1bbeb9fa from "./home-content/Jobs/admitcard/Pageid656e0c82335f485f1bbeb9fa";
import Pageid656f590e9ab81286ba2ff888 from "./home-content/Jobs/admitcard/Pageid656f590e9ab81286ba2ff888";
import Pageid65709e9e08506fd46394e537 from "./home-content/Jobs/admitcard/Pageid65709e9e08506fd46394e537";
import Pageid657345a162f25dfc835fdef8 from "./home-content/Jobs/admitcard/Pageid657345a162f25dfc835fdef8";
import Pageid6573483a62f25dfc835fdefa from "./home-content/Jobs/admitcard/Pageid6573483a62f25dfc835fdefa";
import Pageid6574652b7b4ad8e67ef3bf84 from "./home-content/Jobs/admitcard/Pageid6574652b7b4ad8e67ef3bf84";
import Pageid6574674e7b4ad8e67ef3bfb4 from "./home-content/Jobs/admitcard/Pageid6574674e7b4ad8e67ef3bfb4";
import Pageid6575e429868f14a1b3cf1153 from "./home-content/Jobs/admitcard/Pageid6575e429868f14a1b3cf1153";
import Pageid657883450398ae575fa2f02a from "./home-content/Jobs/admitcard/Pageid657883450398ae575fa2f02a";
import Pageid657b332682fbf0c0e7cb1908 from "./home-content/Jobs/admitcard/Pageid657b332682fbf0c0e7cb1908";
import Pageid657b363682fbf0c0e7cb1950 from "./home-content/Jobs/admitcard/Pageid657b363682fbf0c0e7cb1950";
import Pageid657e76d8fd43e58859f6a315 from "./home-content/Jobs/admitcard/Pageid657e76d8fd43e58859f6a315";
import Pageid6581200080e67a73f78e38e9 from "./home-content/Jobs/admitcard/Pageid6581200080e67a73f78e38e9";
import Pageid6581957580e67a73f78e3b1f from "./home-content/Jobs/admitcard/Pageid6581957580e67a73f78e3b1f";
import Pageid65831ef8f531b4b750ae3474 from "./home-content/Jobs/admitcard/Pageid65831ef8f531b4b750ae3474";
import Pageid65832370f531b4b750ae34c0 from "./home-content/Jobs/admitcard/Pageid65832370f531b4b750ae34c0";
import Pageid6583246ef531b4b750ae34c4 from "./home-content/Jobs/admitcard/Pageid6583246ef531b4b750ae34c4";
import Pageid6583261cf531b4b750ae34c6 from "./home-content/Jobs/admitcard/Pageid6583261cf531b4b750ae34c6";
import Pageid65832779f531b4b750ae34c8 from "./home-content/Jobs/admitcard/Pageid65832779f531b4b750ae34c8";
import Pageid658329acf531b4b750ae34e0 from "./home-content/Jobs/admitcard/Pageid658329acf531b4b750ae34e0";
import Pageid65846379351fbc71550574cd from "./home-content/Jobs/admitcard/Pageid65846379351fbc71550574cd";
import Pageid65846508351fbc71550574cf from "./home-content/Jobs/admitcard/Pageid65846508351fbc71550574cf";
import Pageid658466b1351fbc71550574d1 from "./home-content/Jobs/admitcard/Pageid658466b1351fbc71550574d1";
import Pageid6585b712f952fb0d800b61fd from "./home-content/Jobs/admitcard/Pageid6585b712f952fb0d800b61fd";
import Pageid6585b9cef952fb0d800b6201 from "./home-content/Jobs/admitcard/Pageid6585b9cef952fb0d800b6201";
import Pageid658db78b6ede4354aec46810 from "./home-content/Jobs/admitcard/Pageid658db78b6ede4354aec46810";
import Pageid65905d9e6bf148f58644b56d from "./home-content/Jobs/admitcard/Pageid65905d9e6bf148f58644b56d";
import Pageid6590603c6bf148f58644b56f from "./home-content/Jobs/admitcard/Pageid6590603c6bf148f58644b56f";
import Pageid6590620f6bf148f58644b571 from "./home-content/Jobs/admitcard/Pageid6590620f6bf148f58644b571";
import Pageid6596d1823daba23251e28eef from "./home-content/Jobs/admitcard/Pageid6596d1823daba23251e28eef";
import Pageid65973ff7011eedafc436dd37 from "./home-content/Jobs/admitcard/Pageid65973ff7011eedafc436dd37";
import Pageid65983155011eedafc436df7b from "./home-content/Jobs/admitcard/Pageid65983155011eedafc436df7b";
import Pageid6598328e011eedafc436df7d from "./home-content/Jobs/admitcard/Pageid6598328e011eedafc436df7d";
import Pageid659833a8011eedafc436df97 from "./home-content/Jobs/admitcard/Pageid659833a8011eedafc436df97";
import Pageid659c31780283ce1ccb67f8e1 from "./home-content/Jobs/admitcard/Pageid659c31780283ce1ccb67f8e1";
import Pageid659c33210283ce1ccb67f945 from "./home-content/Jobs/admitcard/Pageid659c33210283ce1ccb67f945";
import Pageid65a017547ee171e8ea214fcf from "./home-content/Jobs/admitcard/Pageid65a017547ee171e8ea214fcf";
import Pageid65a0189c7ee171e8ea214fd3 from "./home-content/Jobs/admitcard/Pageid65a0189c7ee171e8ea214fd3";
import Pageid65a01a657ee171e8ea214fd5 from "./home-content/Jobs/admitcard/Pageid65a01a657ee171e8ea214fd5";
import Pageid65a01c207ee171e8ea214ff1 from "./home-content/Jobs/admitcard/Pageid65a01c207ee171e8ea214ff1";
import Pageid65a1073b7742a9d7bd14da11 from "./home-content/Jobs/admitcard/Pageid65a1073b7742a9d7bd14da11";
import Pageid65a2bc47233e0f147da0a119 from "./home-content/Jobs/admitcard/Pageid65a2bc47233e0f147da0a119";
import Pageid65a2bea2233e0f147da0a133 from "./home-content/Jobs/admitcard/Pageid65a2bea2233e0f147da0a133";
import Pageid65a2c1c9233e0f147da0a167 from "./home-content/Jobs/admitcard/Pageid65a2c1c9233e0f147da0a167";
import Pageid65a2c378233e0f147da0a181 from "./home-content/Jobs/admitcard/Pageid65a2c378233e0f147da0a181";
import Pageid65a6a1d29c722d2408f9d2f7 from "./home-content/Jobs/admitcard/Pageid65a6a1d29c722d2408f9d2f7";
import Pageid65a6a2fa9c722d2408f9d2f9 from "./home-content/Jobs/admitcard/Pageid65a6a2fa9c722d2408f9d2f9";
import Pageid65a6a47f9c722d2408f9d359 from "./home-content/Jobs/admitcard/Pageid65a6a47f9c722d2408f9d359";
import Pageid65a6a8219c722d2408f9d3b1 from "./home-content/Jobs/admitcard/Pageid65a6a8219c722d2408f9d3b1";
import Pageid65a6aa4f9c722d2408f9d3cd from "./home-content/Jobs/admitcard/Pageid65a6aa4f9c722d2408f9d3cd";
import Pageid65a802002c9239e0b719c74c from "./home-content/Jobs/admitcard/Pageid65a802002c9239e0b719c74c";
import Pageid65a807582c9239e0b719c77a from "./home-content/Jobs/admitcard/Pageid65a807582c9239e0b719c77a";
import Pageid65ad1a6bbec1f0bf52b4c6f2 from "./home-content/Jobs/admitcard/Pageid65ad1a6bbec1f0bf52b4c6f2";
import Pageid65b87c46773290f2771911a6 from "./home-content/Jobs/admitcard/Pageid65b87c46773290f2771911a6";
import Pageid65b9210f773290f27719131f from "./home-content/Jobs/admitcard/Pageid65b9210f773290f27719131f";
import Pageid65b92218773290f277191321 from "./home-content/Jobs/admitcard/Pageid65b92218773290f277191321";
import Pageid65bbc4026633c8d4740fa982 from "./home-content/Jobs/admitcard/Pageid65bbc4026633c8d4740fa982";
import Pageid65bbc53b6633c8d4740fa984 from "./home-content/Jobs/admitcard/Pageid65bbc53b6633c8d4740fa984";
import Pageid65bbc7e66633c8d4740fa986 from "./home-content/Jobs/admitcard/Pageid65bbc7e66633c8d4740fa986";
import Pageid65bbc8b36633c8d4740fa98a from "./home-content/Jobs/admitcard/Pageid65bbc8b36633c8d4740fa98a";
import Pageid65bd19683f0c75d734fc12c3 from "./home-content/Jobs/admitcard/Pageid65bd19683f0c75d734fc12c3";
import Pageid65c0f6b13602e8eaa429a551 from "./home-content/Jobs/admitcard/Pageid65c0f6b13602e8eaa429a551";
import Pageid65c2498cc63956a0841a8941 from "./home-content/Jobs/admitcard/Pageid65c2498cc63956a0841a8941";
import Pageid65c24c55c63956a0841a8955 from "./home-content/Jobs/admitcard/Pageid65c24c55c63956a0841a8955";
import Pageid65c50e5e1b7bdbb955226ad2 from "./home-content/Jobs/admitcard/Pageid65c50e5e1b7bdbb955226ad2";
import Pageid65c510431b7bdbb955226aec from "./home-content/Jobs/admitcard/Pageid65c510431b7bdbb955226aec";
import Pageid65ca4e6e38ad41b80489a527 from "./home-content/Jobs/admitcard/Pageid65ca4e6e38ad41b80489a527";
import Pageid65cf7a53cfc1d230ba7b15c6 from "./home-content/Jobs/admitcard/Pageid65cf7a53cfc1d230ba7b15c6";
import Pageid65d22c5ec42fd4e428f026f1 from "./home-content/Jobs/admitcard/Pageid65d22c5ec42fd4e428f026f1";
import Pageid65d6178107137566cd1aa65e from "./home-content/Jobs/admitcard/Pageid65d6178107137566cd1aa65e";
import Pageid65d6188c07137566cd1aa678 from "./home-content/Jobs/admitcard/Pageid65d6188c07137566cd1aa678";
import Pageid65dcc17ffa3982400e8f9635 from "./home-content/Jobs/admitcard/Pageid65dcc17ffa3982400e8f9635";
import Pageid65e0b29109b55e2e7fb0141b from "./home-content/Jobs/admitcard/Pageid65e0b29109b55e2e7fb0141b";
import Pageid65e0b3af09b55e2e7fb0141f from "./home-content/Jobs/admitcard/Pageid65e0b3af09b55e2e7fb0141f";
import Pageid65f1d03886b569fc7c332903 from "./home-content/Jobs/admitcard/Pageid65f1d03886b569fc7c332903";
import Pageid65f1d20086b569fc7c332905 from "./home-content/Jobs/admitcard/Pageid65f1d20086b569fc7c332905";
import Pageid65fb13f83102df0d43fa9caa from "./home-content/Jobs/admitcard/Pageid65fb13f83102df0d43fa9caa";
import Pageid6604625eb60f2187fcec0dc1 from "./home-content/Jobs/admitcard/Pageid6604625eb60f2187fcec0dc1";


//import for government job
import Jobid65432be3b3e4541526ab5051 from "./home-content/Jobs/govermentjob/Jobid65432be3b3e4541526ab5051";
import Jobid65433c19b3e4541526ab5072 from "./home-content/Jobs/govermentjob/Jobid65433c19b3e4541526ab5072";
import Jobid65434aaeb3e4541526ab507f from "./home-content/Jobs/govermentjob/Jobid65434aaeb3e4541526ab507f";
import Jobid65438f0c0fd1deedae737880 from "./home-content/Jobs/govermentjob/Jobid65438f0c0fd1deedae737880";
import Jobid6543953a0fd1deedae737886 from "./home-content/Jobs/govermentjob/Jobid6543953a0fd1deedae737886";
import Jobid654fb873a07f074d70e10af9 from "./home-content/Jobs/govermentjob/Jobid654fb873a07f074d70e10af9";
import Jobid655ccc39af88ec4d0ffa4d22 from "./home-content/Jobs/govermentjob/Jobid655ccc39af88ec4d0ffa4d22";
import Jobid655d4a83f5a4b9f8210a68aa from "./home-content/Jobs/govermentjob/Jobid655d4a83f5a4b9f8210a68aa";
import Jobid655d773cf5a4b9f8210a6b4c from "./home-content/Jobs/govermentjob/Jobid655d773cf5a4b9f8210a6b4c";
import Jobid655ec878e0e46b0f7c75d695 from "./home-content/Jobs/govermentjob/Jobid655ec878e0e46b0f7c75d695";
import Jobid6564ca564493cdae5c9f4a95 from "./home-content/Jobs/govermentjob/Jobid6564ca564493cdae5c9f4a95";
import Jobid6564d3c64493cdae5c9f4b3d from "./home-content/Jobs/govermentjob/Jobid6564d3c64493cdae5c9f4b3d";
import Jobid65656cd87d2f49298c723aac from "./home-content/Jobs/govermentjob/Jobid65656cd87d2f49298c723aac";
import Jobid6568589a2829fd098633de82 from "./home-content/Jobs/govermentjob/Jobid6568589a2829fd098633de82";
import Jobid6568b1bb2829fd098633e959 from "./home-content/Jobs/govermentjob/Jobid6568b1bb2829fd098633e959";
import Jobid6568beca2829fd098633efcc from "./home-content/Jobs/govermentjob/Jobid6568beca2829fd098633efcc";
import Jobid656989d4a40bc2f1aa74c66b from "./home-content/Jobs/govermentjob/Jobid656989d4a40bc2f1aa74c66b";
import Jobid656a06dca40bc2f1aa74cf21 from "./home-content/Jobs/govermentjob/Jobid656a06dca40bc2f1aa74cf21";
import Jobid656ace1fa40bc2f1aa74d7b0 from "./home-content/Jobs/govermentjob/Jobid656ace1fa40bc2f1aa74d7b0";
import Jobid656b67818f20c17da5b5e862 from "./home-content/Jobs/govermentjob/Jobid656b67818f20c17da5b5e862";
import Jobid656f060d9ab81286ba2ff341 from "./home-content/Jobs/govermentjob/Jobid656f060d9ab81286ba2ff341";
import Jobid656f5dd59ab81286ba2ff9b9 from "./home-content/Jobs/govermentjob/Jobid656f5dd59ab81286ba2ff9b9";
import Jobid656f602c9ab81286ba2ffa81 from "./home-content/Jobs/govermentjob/Jobid656f602c9ab81286ba2ffa81";
import Jobid65716d3508506fd46394f362 from "./home-content/Jobs/govermentjob/Jobid65716d3508506fd46394f362";
import Jobid6571cb775e552cb5247c82eb from "./home-content/Jobs/govermentjob/Jobid6571cb775e552cb5247c82eb";
import Jobid65746c337b4ad8e67ef3c029 from "./home-content/Jobs/govermentjob/Jobid65746c337b4ad8e67ef3c029";
import Jobid65746e597b4ad8e67ef3c02b from "./home-content/Jobs/govermentjob/Jobid65746e597b4ad8e67ef3c02b";
import Jobid6575e9ca868f14a1b3cf11da from "./home-content/Jobs/govermentjob/Jobid6575e9ca868f14a1b3cf11da";
import Jobid6575f132868f14a1b3cf1244 from "./home-content/Jobs/govermentjob/Jobid6575f132868f14a1b3cf1244";
import Jobid6577c85b47c06059e60b0481 from "./home-content/Jobs/govermentjob/Jobid6577c85b47c06059e60b0481";
import Jobid65770a7a47c06059e60b03b7 from "./home-content/Jobs/govermentjob/Jobid65770a7a47c06059e60b03b7";
import Jobid6577df0c47c06059e60b050f from "./home-content/Jobs/govermentjob/Jobid6577df0c47c06059e60b050f";
import Jobid657ab43f79d7362543de9cc0 from "./home-content/Jobs/govermentjob/Jobid657ab43f79d7362543de9cc0";
import Jobid657ab4ae79d7362543de9cc2 from "./home-content/Jobs/govermentjob/Jobid657ab4ae79d7362543de9cc2";
import Jobid657b3cfd82fbf0c0e7cb19ba from "./home-content/Jobs/govermentjob/Jobid657b3cfd82fbf0c0e7cb19ba";
import Jobid657b3fba82fbf0c0e7cb19d4 from "./home-content/Jobs/govermentjob/Jobid657b3fba82fbf0c0e7cb19d4";
import Jobid657b416782fbf0c0e7cb19f2 from "./home-content/Jobs/govermentjob/Jobid657b416782fbf0c0e7cb19f2";
import Jobid657c814291e3a09a6b3b1a3d from "./home-content/Jobs/govermentjob/Jobid657c814291e3a09a6b3b1a3d";
import Jobid657e7a14fd43e58859f6a32d from "./home-content/Jobs/govermentjob/Jobid657e7a14fd43e58859f6a32d";
import Jobid658125ca80e67a73f78e38eb from "./home-content/Jobs/govermentjob/Jobid658125ca80e67a73f78e38eb";
import Jobid65814a7e80e67a73f78e3a19 from "./home-content/Jobs/govermentjob/Jobid65814a7e80e67a73f78e3a19";
import Jobid65830574f531b4b750ae32ec from "./home-content/Jobs/govermentjob/Jobid65830574f531b4b750ae32ec";
import Jobid65830d78f531b4b750ae32ee from "./home-content/Jobs/govermentjob/Jobid65830d78f531b4b750ae32ee";
import Jobid658468b5351fbc7155057505 from "./home-content/Jobs/govermentjob/Jobid658468b5351fbc7155057505";
import Jobid658469fb351fbc7155057555 from "./home-content/Jobs/govermentjob/Jobid658469fb351fbc7155057555";
import Jobid65846baf351fbc715505759d from "./home-content/Jobs/govermentjob/Jobid65846baf351fbc715505759d";
import Jobid65846d2f351fbc71550575b7 from "./home-content/Jobs/govermentjob/Jobid65846d2f351fbc71550575b7";
import Jobid658715f87b2c7cdff234d190 from "./home-content/Jobs/govermentjob/Jobid658715f87b2c7cdff234d190";
import Jobid658717b57b2c7cdff234d192 from "./home-content/Jobs/govermentjob/Jobid658717b57b2c7cdff234d192";
import Jobid658719547b2c7cdff234d198 from "./home-content/Jobs/govermentjob/Jobid658719547b2c7cdff234d198";
import Jobid658724d37b2c7cdff234d1ce from "./home-content/Jobs/govermentjob/Jobid658724d37b2c7cdff234d1ce";
import Jobid6588e0ca55664aeb258967ce from "./home-content/Jobs/govermentjob/Jobid6588e0ca55664aeb258967ce";
import Jobid6588e5e655664aeb258967d0 from "./home-content/Jobs/govermentjob/Jobid6588e5e655664aeb258967d0";
import Jobid6588eeed55664aeb25896876 from "./home-content/Jobs/govermentjob/Jobid6588eeed55664aeb25896876";
import Jobid658af78bdf51c4be8d61b4f8 from "./home-content/Jobs/govermentjob/Jobid658af78bdf51c4be8d61b4f8";
import Jobid658afa2bdf51c4be8d61b4fa from "./home-content/Jobs/govermentjob/Jobid658afa2bdf51c4be8d61b4fa";
import Jobid658dba976ede4354aec46812 from "./home-content/Jobs/govermentjob/Jobid658dba976ede4354aec46812";
import Jobid659051076e3fbd9babbf1e85 from "./home-content/Jobs/govermentjob/Jobid659051076e3fbd9babbf1e85";
import Jobid659055596e3fbd9babbf1e89 from "./home-content/Jobs/govermentjob/Jobid659055596e3fbd9babbf1e89";
import Jobid65905a3b6e3fbd9babbf1ea5 from "./home-content/Jobs/govermentjob/Jobid65905a3b6e3fbd9babbf1ea5";
import Jobid659575e0954732b01d17bf08 from "./home-content/Jobs/govermentjob/Jobid659575e0954732b01d17bf08";
import Jobid659577fb954732b01d17bf24 from "./home-content/Jobs/govermentjob/Jobid659577fb954732b01d17bf24";
import Jobid65982e29011eedafc436df5f from "./home-content/Jobs/govermentjob/Jobid65982e29011eedafc436df5f";
import Jobid65998da85951f30848368acc from "./home-content/Jobs/govermentjob/Jobid65998da85951f30848368acc";
import Jobid659b6ecb0283ce1ccb67f75f from "./home-content/Jobs/govermentjob/Jobid659b6ecb0283ce1ccb67f75f";
import Jobid659c305f0283ce1ccb67f8df from "./home-content/Jobs/govermentjob/Jobid659c305f0283ce1ccb67f8df";
import Jobid65a25d2a233e0f147da09fc3 from "./home-content/Jobs/govermentjob/Jobid65a25d2a233e0f147da09fc3";
import Jobid65a25e61233e0f147da09fc5 from "./home-content/Jobs/govermentjob/Jobid65a25e61233e0f147da09fc5";
import Jobid65a2c6b5233e0f147da0a183 from "./home-content/Jobs/govermentjob/Jobid65a2c6b5233e0f147da0a183";
import Jobid65a69dcc9c722d2408f9d299 from "./home-content/Jobs/govermentjob/Jobid65a69dcc9c722d2408f9d299";
import Jobid65a69fd19c722d2408f9d2b1 from "./home-content/Jobs/govermentjob/Jobid65a69fd19c722d2408f9d2b1";
import Jobid65b111b2cc886cd2f1b8af8c from "./home-content/Jobs/govermentjob/Jobid65b111b2cc886cd2f1b8af8c";
import Jobid65b1135dcc886cd2f1b8af8e from "./home-content/Jobs/govermentjob/Jobid65b1135dcc886cd2f1b8af8e";
import Jobid65b879f2773290f27719118e from "./home-content/Jobs/govermentjob/Jobid65b879f2773290f27719118e";
import Jobid65b888c8773290f2771911d8 from "./home-content/Jobs/govermentjob/Jobid65b888c8773290f2771911d8";
import Jobid65b91b14773290f2771912e7 from "./home-content/Jobs/govermentjob/Jobid65b91b14773290f2771912e7";
import Jobid65b91c16773290f2771912e9 from "./home-content/Jobs/govermentjob/Jobid65b91c16773290f2771912e9";
import Jobid65b91cd5773290f2771912eb from "./home-content/Jobs/govermentjob/Jobid65b91cd5773290f2771912eb";
import Jobid65bbbd4a6633c8d4740fa976 from "./home-content/Jobs/govermentjob/Jobid65bbbd4a6633c8d4740fa976";
import Jobid65bbbe9f6633c8d4740fa978 from "./home-content/Jobs/govermentjob/Jobid65bbbe9f6633c8d4740fa978";
import Jobid65bbc0146633c8d4740fa97a from "./home-content/Jobs/govermentjob/Jobid65bbc0146633c8d4740fa97a";
import Jobid65bbc1586633c8d4740fa97e from "./home-content/Jobs/govermentjob/Jobid65bbc1586633c8d4740fa97e";
import Jobid65bbc2cc6633c8d4740fa980 from "./home-content/Jobs/govermentjob/Jobid65bbc2cc6633c8d4740fa980";
import Jobid65bd1fad3f0c75d734fc12e3 from "./home-content/Jobs/govermentjob/Jobid65bd1fad3f0c75d734fc12e3";
import Jobid65c0ef0b3602e8eaa429a4e9 from "./home-content/Jobs/govermentjob/Jobid65c0ef0b3602e8eaa429a4e9";
import Jobid65c0f02e3602e8eaa429a501 from "./home-content/Jobs/govermentjob/Jobid65c0f02e3602e8eaa429a501";
import Jobid65c0f1b03602e8eaa429a505 from "./home-content/Jobs/govermentjob/Jobid65c0f1b03602e8eaa429a505";
import Jobid65c5036a1b7bdbb95522699a from "./home-content/Jobs/govermentjob/Jobid65c5036a1b7bdbb95522699a";
import Jobid65c508211b7bdbb955226aa4 from "./home-content/Jobs/govermentjob/Jobid65c508211b7bdbb955226aa4";
import Jobid65c653df8b7582cec6f505fb from "./home-content/Jobs/govermentjob/Jobid65c653df8b7582cec6f505fb";
import Jobid65ca4b3438ad41b80489a4f7 from "./home-content/Jobs/govermentjob/Jobid65ca4b3438ad41b80489a4f7";
import Jobid65ce241553821e026f6f843a from "./home-content/Jobs/govermentjob/Jobid65ce241553821e026f6f843a";
import Jobid65ce25d153821e026f6f843c from "./home-content/Jobs/govermentjob/Jobid65ce25d153821e026f6f843c";
import Jobid65d22a8dc42fd4e428f026ef from "./home-content/Jobs/govermentjob/Jobid65d22a8dc42fd4e428f026ef";
import Jobid65d37fc4dd7715fdc5147b5b from "./home-content/Jobs/govermentjob/Jobid65d37fc4dd7715fdc5147b5b";
import Jobid65d4dcbd6c00a7fb139a8e82 from "./home-content/Jobs/govermentjob/Jobid65d4dcbd6c00a7fb139a8e82";
import Jobid65d4ddbb6c00a7fb139a8e84 from "./home-content/Jobs/govermentjob/Jobid65d4ddbb6c00a7fb139a8e84";
import Jobid65dcbe14fa3982400e8f962f from "./home-content/Jobs/govermentjob/Jobid65dcbe14fa3982400e8f962f";
import Jobid65dcbfa4fa3982400e8f9631 from "./home-content/Jobs/govermentjob/Jobid65dcbfa4fa3982400e8f9631";
import Jobid65dcc0a5fa3982400e8f9633 from "./home-content/Jobs/govermentjob/Jobid65dcc0a5fa3982400e8f9633";
import Jobid65e0b15209b55e2e7fb013fb from "./home-content/Jobs/govermentjob/Jobid65e0b15209b55e2e7fb013fb";
import Jobid65e1ff259cccb1daaa07a8e4 from "./home-content/Jobs/govermentjob/Jobid65e1ff259cccb1daaa07a8e4";
import Jobid65f1cace86b569fc7c3328f9 from "./home-content/Jobs/govermentjob/Jobid65f1cace86b569fc7c3328f9";
import Jobid65f1cbeb86b569fc7c3328fb from "./home-content/Jobs/govermentjob/Jobid65f1cbeb86b569fc7c3328fb";
import Jobid65f1ccf486b569fc7c3328fd from "./home-content/Jobs/govermentjob/Jobid65f1ccf486b569fc7c3328fd";
import Jobid65f1ce1486b569fc7c3328ff from "./home-content/Jobs/govermentjob/Jobid65f1ce1486b569fc7c3328ff";
import Jobid65f1cf0f86b569fc7c332901 from "./home-content/Jobs/govermentjob/Jobid65f1cf0f86b569fc7c332901";
import Jobid65f31a14bab34a5aed9355b6 from "./home-content/Jobs/govermentjob/Jobid65f31a14bab34a5aed9355b6";
import Jobid65f31b32bab34a5aed9355ba from "./home-content/Jobs/govermentjob/Jobid65f31b32bab34a5aed9355ba";
import Jobid65f31d31bab34a5aed9355bc from "./home-content/Jobs/govermentjob/Jobid65f31d31bab34a5aed9355bc";
import Jobid65f5c5fb485b8b382aea6219 from "./home-content/Jobs/govermentjob/Jobid65f5c5fb485b8b382aea6219";
import Jobid65f5c988485b8b382aea621b from "./home-content/Jobs/govermentjob/Jobid65f5c988485b8b382aea621b";
import Jobid65fb0ea63102df0d43fa9ca2 from "./home-content/Jobs/govermentjob/Jobid65fb0ea63102df0d43fa9ca2";
import Jobid65fb0fe03102df0d43fa9ca6 from "./home-content/Jobs/govermentjob/Jobid65fb0fe03102df0d43fa9ca6";
import Jobid65fb12913102df0d43fa9ca8 from "./home-content/Jobs/govermentjob/Jobid65fb12913102df0d43fa9ca8";
import Jobid66045e5cb60f2187fcec0d77 from "./home-content/Jobs/govermentjob/Jobid66045e5cb60f2187fcec0d77";
import Jobid66046078b60f2187fcec0da9 from "./home-content/Jobs/govermentjob/Jobid66046078b60f2187fcec0da9";
import Jobid660ba382d163e51a3636926a from "./home-content/Jobs/govermentjob/Jobid660ba382d163e51a3636926a";
import Jobid660ed9c65bf9ca1f8c3ccbfe from "./home-content/Jobs/govermentjob/Jobid660ed9c65bf9ca1f8c3ccbfe";
import Jobid660edc085bf9ca1f8c3ccc00 from "./home-content/Jobs/govermentjob/Jobid660edc085bf9ca1f8c3ccc00";
import Jobid6637b1dace9a02a79b792171 from "./home-content/Jobs/govermentjob/Jobid6637b1dace9a02a79b792171";
import Jobid6637b329ce9a02a79b792173 from "./home-content/Jobs/govermentjob/Jobid6637b329ce9a02a79b792173";
import Jobid6637b4cece9a02a79b792175 from "./home-content/Jobs/govermentjob/Jobid6637b4cece9a02a79b792175";










//import job news
import Newsid65479d252d9a4ffaef2bf534 from './home-content/Jobs/jobnews/Newsid65478563cbd49717a40142f8';
import Newsid657e809bfd43e58859f6a371 from './home-content/Jobs/jobnews/Newsid657e809bfd43e58859f6a371';
import Newsid657e859dfd43e58859f6a389 from './home-content/Jobs/jobnews/Newsid657e859dfd43e58859f6a389';


//import for result page
import Pageid6546817c929bd0db64758256 from "./home-content/Jobs/Results/Pageid6546817c929bd0db64758256";
import Pageid6546fe907b9877dc01782f38 from "./home-content/Jobs/Results/Pageid6546fe907b9877dc01782f38";
import Pageid654701287b9877dc01782f3a from "./home-content/Jobs/Results/Pageid654701287b9877dc01782f3a";
import Pageid65472dd32441dbd7091f7d2b from "./home-content/Jobs/Results/Pageid65472dd32441dbd7091f7d2b";
import Pageid6547312c2441dbd7091f7d3e from "./home-content/Jobs/Results/Pageid6547312c2441dbd7091f7d3e";
import Pageid655d392bf5a4b9f8210a6703 from "./home-content/Jobs/Results/Pageid655d392bf5a4b9f8210a6703";
import Pageid6560bb1b2c391fde12d3c447 from "./home-content/Jobs/Results/Pageid6560bb1b2c391fde12d3c447";
import Pageid656169f1eabef241d18f9544 from "./home-content/Jobs/Results/Pageid656169f1eabef241d18f9544";
import Pageid65662eb128ba3728112ce88b from "./home-content/Jobs/Results/Pageid65662eb128ba3728112ce88b";
import Pageid656630ed28ba3728112ce88d from "./home-content/Jobs/Results/Pageid656630ed28ba3728112ce88d";
import Pageid65695fcf8b86ad1249ca4823 from "./home-content/Jobs/Results/Pageid65695fcf8b86ad1249ca4823";
import Pageid656b61cc8f20c17da5b5e750 from "./home-content/Jobs/Results/Pageid656b61cc8f20c17da5b5e750";
import Pageid656ca7590beb0bfb3ea0718b from "./home-content/Jobs/Results/Pageid656ca7590beb0bfb3ea0718b";
import Pageid656e0b96335f485f1bbeb9e0 from "./home-content/Jobs/Results/Pageid656e0b96335f485f1bbeb9e0";
import Pageid656f30939ab81286ba2ff5e5 from "./home-content/Jobs/Results/Pageid656f30939ab81286ba2ff5e5";
import Pageid656f51c59ab81286ba2ff86e from "./home-content/Jobs/Results/Pageid656f51c59ab81286ba2ff86e";
import Pageid656f54ea9ab81286ba2ff870 from "./home-content/Jobs/Results/Pageid656f54ea9ab81286ba2ff870";
import Pageid656f66e89ab81286ba2ffcb1 from "./home-content/Jobs/Results/Pageid656f66e89ab81286ba2ffcb1";
import Pageid6570a38f08506fd46394e551 from "./home-content/Jobs/Results/Pageid6570a38f08506fd46394e551";
import Pageid6570aa0408506fd46394e66e from "./home-content/Jobs/Results/Pageid6570aa0408506fd46394e66e";
import Pageid6570b35b08506fd46394e80a from "./home-content/Jobs/Results/Pageid6570b35b08506fd46394e80a";
import Pageid6573308c62f25dfc835fdeb4 from "./home-content/Jobs/Results/Pageid6573308c62f25dfc835fdeb4";
import Pageid657469217b4ad8e67ef3bfb6 from "./home-content/Jobs/Results/Pageid657469217b4ad8e67ef3bfb6";
import Pageid65748f5e7b4ad8e67ef3c12f from "./home-content/Jobs/Results/Pageid65748f5e7b4ad8e67ef3c12f";
import Pageid6575de5f868f14a1b3cf1045 from "./home-content/Jobs/Results/Pageid6575de5f868f14a1b3cf1045";
import Pageid6575e06d868f14a1b3cf1078 from "./home-content/Jobs/Results/Pageid6575e06d868f14a1b3cf1078";
import Pageid6575e23a868f14a1b3cf10bd from "./home-content/Jobs/Results/Pageid6575e23a868f14a1b3cf10bd";
import Pageid657886e60398ae575fa2f02c from "./home-content/Jobs/Results/Pageid657886e60398ae575fa2f02c";
import Pageid657adfc179d7362543de9d4c from "./home-content/Jobs/Results/Pageid657adfc179d7362543de9d4c";
import Pageid657b39ec82fbf0c0e7cb199c from "./home-content/Jobs/Results/Pageid657b39ec82fbf0c0e7cb199c";
import Pageid657c7c6291e3a09a6b3b1a23 from "./home-content/Jobs/Results/Pageid657c7c6291e3a09a6b3b1a23";
import Pageid65810c3880e67a73f78e37e from "./home-content/Jobs/Results/Pageid65810c3880e67a73f78e37e";
import Pageid65810f1180e67a73f78e3822 from "./home-content/Jobs/Results/Pageid65810f1180e67a73f78e3822";
import Pageid65832baff531b4b750ae34e2 from "./home-content/Jobs/Results/Pageid65832baff531b4b750ae34e2";
import Pageid65832cf9f531b4b750ae34e4 from "./home-content/Jobs/Results/Pageid65832cf9f531b4b750ae34e4.js";
import Pageid65832f55f531b4b750ae34e6 from "./home-content/Jobs/Results/Pageid65832f55f531b4b750ae34e6";
import Pageid65845ca0351fbc715505749b from "./home-content/Jobs/Results/Pageid65845ca0351fbc715505749b";
import Pageid65845e2e351fbc715505749d from "./home-content/Jobs/Results/Pageid65845e2e351fbc715505749d";
import Pageid65845f51351fbc71550574b5 from "./home-content/Jobs/Results/Pageid65845f51351fbc71550574b5";
import Pageid6585bdedf952fb0d800b622f from "./home-content/Jobs/Results/Pageid6585bdedf952fb0d800b622f";
import Pageid6585c1ecf952fb0d800b6249 from "./home-content/Jobs/Results/Pageid6585c1ecf952fb0d800b6249";
import Pageid658720737b2c7cdff234d1b2 from "./home-content/Jobs/Results/Pageid658720737b2c7cdff234d1b2";
import Pageid658af618df51c4be8d61b4de from "./home-content/Jobs/Results/Pageid658af618df51c4be8d61b4de";
import Pageid658dc0826ede4354aec4682c from "./home-content/Jobs/Results/Pageid658dc0826ede4354aec4682c";
import Pageid658dc26f6ede4354aec4682e from "./home-content/Jobs/Results/Pageid658dc26f6ede4354aec4682e";
import Pageid6595863e954732b01d17bf3c from "./home-content/Jobs/Results/Pageid6595863e954732b01d17bf3c";
import Pageid6596d34f3daba23251e28f1f from "./home-content/Jobs/Results/Pageid6596d34f3daba23251e28f1f";
import Pageid6596d7713daba23251e28f7d from "./home-content/Jobs/Results/Pageid6596d7713daba23251e28f7d";
import Pageid6596dae93daba23251e28fab from "./home-content/Jobs/Results/Pageid6596dae93daba23251e28fab";
import Pageid659834c6011eedafc436df99 from "./home-content/Jobs/Results/Pageid659834c6011eedafc436df99";
import Pageid65983618011eedafc436df9b from "./home-content/Jobs/Results/Pageid65983618011eedafc436df9b";
import Pageid659a14efb6268f7865337bd8 from "./home-content/Jobs/Results/Pageid659a14efb6268f7865337bd8";
import Pageid65a01ea17742a9d7bd14d807 from "./home-content/Jobs/Results/Pageid65a01ea17742a9d7bd14d807";
import Pageid65a0203f7742a9d7bd14d821 from "./home-content/Jobs/Results/Pageid65a0203f7742a9d7bd14d821";
import Pageid65a021b17742a9d7bd14d87f from "./home-content/Jobs/Results/Pageid65a021b17742a9d7bd14d87f";
import Pageid65a160137742a9d7bd14db67 from "./home-content/Jobs/Results/Pageid65a160137742a9d7bd14db67";
import Pageid65a69bd09c722d2408f9d27f from "./home-content/Jobs/Results/Pageid65a69bd09c722d2408f9d27f";
import Pageid65a7ffe72c9239e0b719c730 from "./home-content/Jobs/Results/Pageid65a7ffe72c9239e0b719c730";
import Pageid65b91e76773290f27719131b from "./home-content/Jobs/Results/Pageid65b91e76773290f27719131b";
import Pageid65b91f88773290f27719131d from "./home-content/Jobs/Results/Pageid65b91f88773290f27719131d";
import Pageid65bbcae36633c8d4740fa9e0 from "./home-content/Jobs/Results/Pageid65bbcae36633c8d4740fa9e0";
import Pageid65bbcd3e6633c8d4740fa9fa from "./home-content/Jobs/Results/Pageid65bbcd3e6633c8d4740fa9fa";
import Pageid65bd21453f0c75d734fc1315 from "./home-content/Jobs/Results/Pageid65bd21453f0c75d734fc1315";
import Pageid65c24e19c63956a0841a8957 from "./home-content/Jobs/Results/Pageid65c24e19c63956a0841a8957";
import Pageid65c24f3cc63956a0841a8959 from "./home-content/Jobs/Results/Pageid65c24f3cc63956a0841a8959";
import Pageid65c5154a1b7bdbb955226aee from "./home-content/Jobs/Results/Pageid65c5154a1b7bdbb955226aee";
import Pageid65c655cd8b7582cec6f505fd from "./home-content/Jobs/Results/Pageid65c655cd8b7582cec6f505fd";
import Pageid65ca4c7938ad41b80489a50f from "./home-content/Jobs/Results/Pageid65ca4c7938ad41b80489a50f";
import Pageid65ce2c4953821e026f6f84e6 from "./home-content/Jobs/Results/Pageid65ce2c4953821e026f6f84e6";
import Pageid65cf78edcfc1d230ba7b15c4 from "./home-content/Jobs/Results/Pageid65cf78edcfc1d230ba7b15c4";
import Pageid65d22ef8c42fd4e428f026f3 from "./home-content/Jobs/Results/Pageid65d22ef8c42fd4e428f026f3";
import Pageid65d4def76c00a7fb139a8e9c from "./home-content/Jobs/Results/Pageid65d4def76c00a7fb139a8e9c";
import Pageid65dcc26ffa3982400e8f9637 from "./home-content/Jobs/Results/Pageid65dcc26ffa3982400e8f9637";
import Pageid65e0b4fa09b55e2e7fb01452 from "./home-content/Jobs/Results/Pageid65e0b4fa09b55e2e7fb01452";
import Pageid65e0b70609b55e2e7fb01488 from "./home-content/Jobs/Results/Pageid65e0b70609b55e2e7fb01488";
import Pageid65f1d48686b569fc7c332907 from "./home-content/Jobs/Results/Pageid65f1d48686b569fc7c332907";
import Pageid65f1d54286b569fc7c332909 from "./home-content/Jobs/Results/Pageid65f1d54286b569fc7c332909";
import Pageid65f31e0fbab34a5aed9355be from "./home-content/Jobs/Results/Pageid65f31e0fbab34a5aed9355be";
import Pageid65f31f37bab34a5aed9355c0 from "./home-content/Jobs/Results/Pageid65f31f37bab34a5aed9355c0";
import Pageid65f5ccab485b8b382aea621d from "./home-content/Jobs/Results/Pageid65f5ccab485b8b382aea621d";
import Pageid6605ac88c9bd03979ec61d5a from "./home-content/Jobs/Results/Pageid6605ac88c9bd03979ec61d5a";
import Pageid6607683a7ebc26e8e269f042 from "./home-content/Jobs/Results/Pageid6607683a7ebc26e8e269f042";














//import for updates page
import Jobid654dee73738b62cb12722434 from "./home-content/Jobs/updates/Jobid654dee73738b62cb12722434";
import Jobid654e46f5738b62cb12722436 from "./home-content/Jobs/updates/Jobid654e46f5738b62cb12722436";
import Jobid654e483e738b62cb12722438 from "./home-content/Jobs/updates/Jobid654e483e738b62cb12722438";
import Jobid654e4981738b62cb1272243c from "./home-content/Jobs/updates/Jobid654e4981738b62cb1272243c";
import Jobid654e494a738b62cb1272243a from "./home-content/Jobs/updates/Jobid654e494a738b62cb1272243a";
import Jobid6568b8f32829fd098633ed4d from "./home-content/Jobs/updates/Jobid6568b8f32829fd098633ed4d";
import Jobid656a10e9a40bc2f1aa74d091 from "./home-content/Jobs/updates/Jobid656a10e9a40bc2f1aa74d091";

//import for important pagess
import Pageid655fe7f73a6d7e513a4f2aa7 from "./home-content/Jobs/important/Pageid655fe7f73a6d7e513a4f2aa7";
import Pageid656006992c391fde12d3bd03 from "./home-content/Jobs/important/Pageid656006992c391fde12d3bd03";
import Pageid656f6d399ab81286ba2ffddb from "./home-content/Jobs/important/Pageid656f6d399ab81286ba2ffddb";
import Pageid657017429ab81286ba300057 from "./home-content/Jobs/important/Pageid657017429ab81286ba300057";
import Pageid6570713308506fd46394e339 from "./home-content/Jobs/important/Pageid6570713308506fd46394e339";
import Pageid6570717a08506fd46394e33b from "./home-content/Jobs/important/Pageid6570717a08506fd46394e33b";
import Pageid65788b6a0398ae575fa2f044 from "./home-content/Jobs/important/Pageid65788b6a0398ae575fa2f044";
import Heading from './Heading.js';













function App() {
  return (
    <div>
      
      <div>
        <BrowserRouter>
          <Routes>
            {/*routing for home pages*/}
            <Route exact path='/' element={< HomePage />}></Route>
            <Route exact path='/LoginPage' element={<LoginPage />}></Route>
            <Route exact path='/Register' element={<Register />}></Route>
            <Route exact path='/Government' element={<Governmentjob />}></Route>
            <Route exact path='/jobnews' element={<Jobnews />}></Route>
            <Route exact path='/Latestjob' element={<Latestjob />}></Route>
            <Route exact path='/Admitcard' element={<Admitcard />}></Route>
            <Route exact path='/Syllabus' element={<Syllabus />}></Route>
            <Route exact path='/Admitcard' element={<Admitcard />}></Route>
            <Route exact path='/AdminLogin' element={<AdminLogin />}></Route>
            <Route exact path='/Results' element={<Results />}></Route>
            <Route exact path='/Latestjobformat' element={<Latestjobformat />}></Route>
            <Route exact path='/Sitemap' element={<sitemap />}></Route>
            {/*routing from Footer Pages*/}
            <Route exact path='/Contactus' element={<Contactus />}></Route>
            <Route exact path='/Privacy' element={<Privacy />}></Route>
            <Route exact path='/Aboutus' element={<Aboutus />}></Route>
            <Route exact path='/Condition' element={<Conditions />}></Route>
            <Route exact path='/Disclaimer' element={<Disclaimer />}></Route>

            {/*routing from admin pages*/}
            <Route exact path='/Dashboard' element={<Dashboard />}></Route>
            <Route exact path='/AddLatestjob' element={<AddLatestjob />}></Route>
            <Route exact path='/Addadmitcard' element={<Addadmitcard />}></Route>
            <Route exact path='/Addupdates' element={<Addupdates />}></Route>
            <Route exact path='/Addresult' element={<Addresult />}></Route>
            <Route exact path='/Addimportant' element={<Addimportant />}></Route>
            <Route exact path='/Addjobnews' element={<Addjobnews />}></Route>
{/*Routing for admit card*/}
            <Route exact path="/Pageid654557454fabd5424e65971b" element={<Pageid654557454fabd5424e65971b />}></Route>
            <Route exact path="/Pageid6545f3df04bae4cc8dbae10d" element={<Pageid6545f3df04bae4cc8dbae10d />}></Route>
            <Route exact path="/Pageid6545fa7404bae4cc8dbae121" element={<Pageid6545fa7404bae4cc8dbae121 />}></Route>
            <Route exact path="/Pageid6546296e04bae4cc8dbae151" element={<Pageid6546296e04bae4cc8dbae151 />}></Route>
            <Route exact path="/Pageid654678078dcc457c81d5c4f4" element={<Pageid654678078dcc457c81d5c4f4 />}></Route>
            <Route exact path="/Pageid655e32b2f5a4b9f8210a6f4b" element={<Pageid655e32b2f5a4b9f8210a6f4b />}></Route>
            <Route exact path="/Pageid655edd10e0e46b0f7c75d751" element={<Pageid655edd10e0e46b0f7c75d751 />}></Route>
            <Route exact path="/Pageid65662664b2e39cfa47b67025" element={<Pageid65662664b2e39cfa47b67025 />}></Route>
            <Route exact path="/Pageid6568b4f72829fd098633eb55" element={<Pageid6568b4f72829fd098633eb55 />}></Route>
            <Route exact path="/Pageid656a0378a40bc2f1aa74cf09" element={<Pageid656a0378a40bc2f1aa74cf09 />}></Route>
            <Route exact path="/Pageid656ca0760beb0bfb3ea07157" element={<Pageid656ca0760beb0bfb3ea07157 />}></Route>
            <Route exact path="/Pageid656cbcee0beb0bfb3ea074b2" element={<Pageid656cbcee0beb0bfb3ea074b2 />}></Route>
            <Route exact path="/Pageid656e0c82335f485f1bbeb9fa" element={<Pageid656e0c82335f485f1bbeb9fa />}></Route>
            <Route exact path="/Pageid656f590e9ab81286ba2ff888" element={<Pageid656f590e9ab81286ba2ff888 />}></Route>
            <Route exact path="/Pageid65709e9e08506fd46394e537" element={<Pageid65709e9e08506fd46394e537 />}></Route>
            <Route exact path="/Pageid657345a162f25dfc835fdef8" element={<Pageid657345a162f25dfc835fdef8 />}></Route>
            <Route exact path="/Pageid6573483a62f25dfc835fdefa" element={<Pageid6573483a62f25dfc835fdefa />}></Route>
            <Route exact path="/Pageid6574652b7b4ad8e67ef3bf84" element={<Pageid6574652b7b4ad8e67ef3bf84 />}></Route>
            <Route exact path="/Pageid6574674e7b4ad8e67ef3bfb4" element={<Pageid6574674e7b4ad8e67ef3bfb4 />}></Route>
            <Route exact path="/Pageid6575e429868f14a1b3cf1153" element={<Pageid6575e429868f14a1b3cf1153 />}></Route>
            <Route exact path="/Pageid657883450398ae575fa2f02a" element={<Pageid657883450398ae575fa2f02a />}></Route>
            <Route exact path="/Pageid657b332682fbf0c0e7cb1908" element={<Pageid657b332682fbf0c0e7cb1908 />}></Route>
            <Route exact path="/Pageid657b363682fbf0c0e7cb1950" element={<Pageid657b363682fbf0c0e7cb1950 />}></Route>
            <Route exact path="/Pageid657e76d8fd43e58859f6a315" element={<Pageid657e76d8fd43e58859f6a315 />}></Route>
            <Route exact path="/Pageid6581200080e67a73f78e38e9" element={<Pageid6581200080e67a73f78e38e9 />}></Route>
            <Route exact path="/Pageid6581957580e67a73f78e3b1f" element={<Pageid6581957580e67a73f78e3b1f />}></Route>
            <Route exact path="/Pageid65831ef8f531b4b750ae3474" element={<Pageid65831ef8f531b4b750ae3474 />}></Route>
            <Route exact path="/Pageid65832370f531b4b750ae34c0" element={<Pageid65832370f531b4b750ae34c0 />}></Route>
            <Route exact path="/Pageid6583246ef531b4b750ae34c4" element={<Pageid6583246ef531b4b750ae34c4 />}></Route>
            <Route exact path="/Pageid6583261cf531b4b750ae34c6" element={<Pageid6583261cf531b4b750ae34c6 />}></Route>
            <Route exact path="/Pageid65832779f531b4b750ae34c8" element={<Pageid65832779f531b4b750ae34c8 />}></Route>
            <Route exact path="/Pageid658329acf531b4b750ae34e0" element={<Pageid658329acf531b4b750ae34e0 />}></Route>
            <Route exact path="/Pageid65846379351fbc71550574cd" element={<Pageid65846379351fbc71550574cd />}></Route>
            <Route exact path="/Pageid65846379351fbc71550574cd" element={<Pageid65846379351fbc71550574cd />}></Route>
            <Route exact path="/Pageid65846508351fbc71550574cf" element={<Pageid65846508351fbc71550574cf />}></Route>
            <Route exact path="/Pageid658466b1351fbc71550574d1" element={<Pageid658466b1351fbc71550574d1 />}></Route>
            <Route exact path="/Pageid6585b712f952fb0d800b61fd" element={<Pageid6585b712f952fb0d800b61fd />}></Route>
            <Route exact path="/Pageid6585b9cef952fb0d800b6201" element={<Pageid6585b9cef952fb0d800b6201 />}></Route>
            <Route exact path="/SSC-Stenographer-2023-Skill-Test-Admit-Card" element={<Pageid658db78b6ede4354aec46810 />}></Route>
            <Route exact path="/RPSC-Librarian-/-PTI-Admit-Card-2023" element={<Pageid65905d9e6bf148f58644b56d />}></Route>
            <Route exact path="/RPSC-Assistant-Professor-Admit-Card-2023" element={<Pageid6590603c6bf148f58644b56f />}></Route>
            <Route exact path="/Bihar-BTSC-ANM-2022-Admit-Card-2023" element={<Pageid6590620f6bf148f58644b571 />}></Route>
            <Route exact path="/ICAR-IARI-Technician-2021-Tier-II-Admit-Card" element={<Pageid6596d1823daba23251e28eef />}></Route>
            <Route exact path="/CISF-ASI-Steno-/-HC-Ministerial-2022-Skill-Test-Admit-Card" element={<Pageid65973ff7011eedafc436dd37 />}></Route>
            <Route exact path="/GATE-2024-Admit-Card" element={<Pageid65983155011eedafc436df7b />}></Route>
            <Route exact path="/SSC-CPO-SI-2023-Paper-II-Admit-Card" element={<Pageid6598328e011eedafc436df7d />}></Route>
            <Route exact path="/SSC-CHSL-2023-Paper-II-Admit-Card" element={<Pageid659833a8011eedafc436df97 />}></Route>
            <Route exact path="/IIT-JAM-2024-Admit-Card" element={<Pageid659c31780283ce1ccb67f8e1 />}></Route>
            <Route exact path="/SSC-Delhi-Police-Constable-2023-PET-/-PST-Admit-Card" element={<Pageid659c33210283ce1ccb67f945 />}></Route>
            <Route exact path="/MHA-IB-ACIO-2023-Admit Card" element={<Pageid65a017547ee171e8ea214fcf />}></Route>
            <Route exact path="/NTA-Military-Nursing-Services-MNS-Exam-Admit-Card-2024" element={<Pageid65a0189c7ee171e8ea214fd3 />}></Route>
            <Route exact path="/CISF-Constable-Tradesman-2022-DME-Admit-Card" element={<Pageid65a01a657ee171e8ea214fd5 />}></Route>
            <Route exact path="/Bihar-Police-BPSSC-SI-Prohibition-Admit-Card-2024" element={<Pageid65a01c207ee171e8ea214ff1 />}></Route>
            <Route exact path="/CTET-January-2024-Exam-City-Details" element={<Pageid65a1073b7742a9d7bd14da11 />}></Route>
            <Route exact path="/RSMSSB-Informatic-Assistant-2023-Admit-Card" element={<Pageid65a2bc47233e0f147da0a119 />}></Route>
            <Route exact path="/NTA-JEEMAIN-2024-Exam-City-Details" element={<Pageid65a2bea2233e0f147da0a133 />}></Route>
            <Route exact path="/UIIC-Assistant-Exam-Date-2024" element={<Pageid65a2c1c9233e0f147da0a167 />}></Route>
            <Route exact path="/DPS-DAE-JPA-/-JSK-Exam-Date-2024" element={<Pageid65a2c378233e0f147da0a181 />}></Route>
            <Route exact path="/UP-Police-Assistant-Operator-2022-Exam-Date" element={<Pageid65a6a1d29c722d2408f9d2f7 />}></Route>
            <Route exact path="/UP-Police-Head-Operator-2022-Exam-Date" element={<Pageid65a6a2fa9c722d2408f9d2f9 />}></Route>
            <Route exact path="/UP-Police-Workshop-Staff-2022-Exam-Date" element={<Pageid65a6a47f9c722d2408f9d359 />}></Route>
            <Route exact path="/SBI-CBO-Admit-Card-2024" element={<Pageid65a6a8219c722d2408f9d3b1 />}></Route>
            <Route exact path="/SSB-HC,-ASI,-SI-Admit-Card-2024" element={<Pageid65a6aa4f9c722d2408f9d3cd />}></Route>
            <Route exact path="/NTA-AISSEE-2024-Admit-Card" element={<Pageid65a802002c9239e0b719c74c />}></Route>
            <Route exact path="/CSIR-CASE-SO-/-ASO-Exam-Date-2024" element={<Pageid65a807582c9239e0b719c77a />}></Route>
            <Route exact path="/JSSC-CGL-2023-ADMIT-CARD" element={<Pageid65ad1a6bbec1f0bf52b4c6f2 />}></Route>
            <Route exact path="/Navy-INCET-Admit-Card" element={<Pageid65b87c46773290f2771911a6 />}></Route>
            <Route exact path="/AFCAT-Admit-Card" element={<Pageid65b9210f773290f27719131f />}></Route>
            <Route exact path="/Patna-High-Court-Assistant-2023-Interview-Letter" element={<Pageid65b92218773290f277191321 />}></Route>
            <Route exact path="/UPPSC-RO-ARO-2023-Admit-Card" element={<Pageid65bbc4026633c8d4740fa982 />}></Route>
            <Route exact path="/CGPSC-Pre-2023-Admit-Card" element={<Pageid65bbc53b6633c8d4740fa984 />}></Route>
            <Route exact path="/NTA-CUREC-IGNOU-Admit-Card-2024" element={<Pageid65bbc7e66633c8d4740fa986 />}></Route>
            <Route exact path="/NIFT-2024-Admission-Exam-City-Admit-Card" element={<Pageid65bbc8b36633c8d4740fa98a />}></Route>
            <Route exact path="/ssc-gd-admit-card-2023" element={<Pageid65bd19683f0c75d734fc12c3 />}></Route>
            <Route exact path="/General-Insurance-GIC-Scale-I-Various-Post-Exam-Date-2024" element={<Pageid65c0f6b13602e8eaa429a551 />}></Route>
            <Route exact path="/Bihar-Police-BPSSC-Sub-Inspector-SI-Phase-II-Mains-Admit-Card-2024" element={<Pageid65c2498cc63956a0841a8941 />}></Route>
            <Route exact path="/Delhi-High-Court-PA-Stage-II-Admit-Card-2024" element={<Pageid65c24c55c63956a0841a8955 />}></Route>
            <Route exact path="/RSMSSB-Sangnak-Computer-Exam-Date-2024" element={<Pageid65c50e5e1b7bdbb955226ad2 />}></Route>
            <Route exact path="/BPSC-Various-Agriculture-Post-Exam-Date-2024" element={<Pageid65c510431b7bdbb955226aec />}></Route>
            <Route exact path="/IBPS-PO-XIII-Interview-Letter" element={<Pageid65ca4e6e38ad41b80489a527 />}></Route>
            <Route exact path="/Bihar-BSSTET-2023-Admit-Card" element={<Pageid65cf7a53cfc1d230ba7b15c6 />}></Route>
            <Route exact path="/UPSSSC-Instructor-2022-Admit-Card" element={<Pageid65d22c5ec42fd4e428f026f1 />}></Route>
            <Route exact path="/National-Insurance-NIACL-AO-Exam-Date-2024" element={<Pageid65d6178107137566cd1aa65e />}></Route>
            <Route exact path="/RPSC-Statistical-Officer-Admit-Card-2024" element={<Pageid65d6188c07137566cd1aa678 />}></Route>
            <Route exact path="/National-Insurance-NIACL-AO-Admit-Card-2024" element={<Pageid65dcc17ffa3982400e8f9635 />}></Route>
            <Route exact path="/NTA-CUET-PG-2024-Subject-Wise-Exam-Schedule" element={<Pageid65e0b29109b55e2e7fb0141b />}></Route>
            <Route exact path="/Bihar-School-Teacher-TRE-3.0-Exam-Date" element={<Pageid65e0b3af09b55e2e7fb0141f />}></Route>
            <Route exact path="/Jharkhand-JPSC-Pre-2024-Admit Card" element={<Pageid65f1d03886b569fc7c332903/>}></Route>
            <Route exact path="/Bihar-School-Teacher-TRE-3-Admit-Card-2024-Exam-Center-Details-Available" element={<Pageid65f1d20086b569fc7c332905/>}></Route>
            <Route exact path="/Indian-Airforce-Agniveer-Intake-01/2025-Admit-Card" element={<Pageid65fb13f83102df0d43fa9caa/>}></Route>
            <Route exact path="/HPSC-HCS-Mains-Admit-Card-2024" element={<Pageid6604625eb60f2187fcec0dc1/>}></Route>

            
{/*Routing for latest job*/}
            <Route exact path="/Jobid65432be3b3e4541526ab5051" element={<Jobid65432be3b3e4541526ab5051 />}></Route>
            <Route exact path="/UPPSC-Additional-Private-Secretary-Recruitment-2023-Apply-Online-for-328-Post" element={<Jobid65433c19b3e4541526ab5072 />}></Route>
            <Route exact path="/AIIMS-Deoghar-Non-Faculty-Recruitment-2023-Apply-Online-91-Various-Post-Form" element={<Jobid65434aaeb3e4541526ab507f />}></Route>
            <Route exact path="/Railway-Banaras-Locomotive-Works-BLW-Apprentices-2023-Apply-Online-for-374-Post" element={<Jobid65438f0c0fd1deedae737880 />}></Route>
            <Route exact path="/Airport-Authority-of-India-AAI-Recruitment-2023-Apply-Online-Junior-Executive-496-Post" element={<Jobid6543953a0fd1deedae737886 />}></Route>
            <Route exact path="/SIDBI-grade-A(Assistent-manager-recruitment-2023) -SIDBI-Advent-No.04-/Grade-A/2023-24-/-short-Details-of-notification." element={<Jobid654fb873a07f074d70e10af9 />}></Route>
            <Route exact path="/SBI-JUNIOR-ASSOCIATES-CLERK-FOR-POST-8283-2023" element={<Jobid655ccc39af88ec4d0ffa4d22 />}></Route>
            <Route exact path="/SBI-circle-based-officer-recruitment-2023" element={<Jobid655d4a83f5a4b9f8210a68aa />}></Route>
            <Route exact path="/Intelligence-Bureau-ACIO-Grade-II-/-Executive-Online-Form-2023" element={<Jobid655d773cf5a4b9f8210a6b4c />}></Route>
            <Route exact path="/Constable-(GD)-in-Central-Armed-Police-Forces (CAPFs),-SSF-,-Rifleman-(GD)-in-Assam-Rifles-and-Sepoy-in-Narcotics-Control-Bureau-Examination,-2024" element={<Jobid655ec878e0e46b0f7c75d695 />}></Route>
            <Route exact path="/airforce-common-admission-test-(AFCAT)-01/2024" element={<Jobid6564ca564493cdae5c9f4a95 />}></Route>
            <Route exact path="/CGPSC-State-service-exam-2023" element={<Jobid6564d3c64493cdae5c9f4b3d />}></Route>
            <Route exact path="/Jobid65656cd87d2f49298c723aac" element={<Jobid65656cd87d2f49298c723aac />}></Route>
            <Route exact path="/(NIOS)-Group-A-B,-C -Vacancy-2023" element={<Jobid6568589a2829fd098633de82 />}></Route>
            <Route exact path="/RRC-SER-Apprentice-form-2023" element={<Jobid6568b1bb2829fd098633e959 />}></Route>
            <Route exact path="/RRC-NER-Apprentice-2022-2023" element={<Jobid6568beca2829fd098633efcc />}></Route>
            <Route exact path="/airforce-common-admission-test-(AFCAT)-01/2024" element={<Jobid656989d4a40bc2f1aa74c66b />}></Route>
            <Route exact path="/BSTET-Bihar-Special-State-Eligibility-Test-2023-Apply-Online" element={<Jobid656a06dca40bc2f1aa74cf21 />}></Route>
            <Route exact path="/HARYANA-PUBLIC-SERVICE-COMMISSTION-2023" element={<Jobid656ace1fa40bc2f1aa74d7b0 />}></Route>
            <Route exact path="/Requirement-to-the-posts-of-Stenographer-(Grade-3),-Junior-Clerk-and-Peon/Hamal-notification-2023" element={<Jobid656b67818f20c17da5b5e862 />}></Route>
            <Route exact path="/IDBI-Bank-Limited-SO-Recruitment-2023" element={<Jobid656f060d9ab81286ba2ff341 />}></Route>
            <Route exact path="/RRC-NR-Delhi -apprentice-Recruitment-2023" element={<Jobid656f5dd59ab81286ba2ff9b9 />}></Route>
            <Route exact path="/NTA-Military-Nursing-Service-2023" element={<Jobid656f602c9ab81286ba2ffa81 />}></Route>
            <Route exact path="/Staff-Nurse-Unani-(Male/Female)-Examination-2023" element={<Jobid65716d3508506fd46394f362 />}></Route>
            <Route exact path="/Bank-of-Baroda-recruitment-2023" element={<Jobid6571cb775e552cb5247c82eb />}></Route>
            <Route exact path="/ISRO-NRSC-Technician-B-Recruitment-2023" element={<Jobid65746c337b4ad8e67ef3c029 />}></Route>
            <Route exact path="/CSIR-CASE-Section-Officer-SO-/-Assistant-Section-Officer-Exam-2023" element={<Jobid65746e597b4ad8e67ef3c02b />}></Route>
            <Route exact path="/Atomic-Energy-DPSDAE-JPA-/-JSK-Online-Form-2023" element={<Jobid6575e9ca868f14a1b3cf11da />}></Route>
            <Route exact path="/NTPC-Various-Post-Recruitment-2023" element={<Jobid6575f132868f14a1b3cf1244 />}></Route>
            <Route exact path="/Jobid6577c85b47c06059e60b0481" element={<Jobid6577c85b47c06059e60b0481 />}></Route>
            <Route exact path="/Jobid65770a7a47c06059e60b03b7" element={<Jobid65770a7a47c06059e60b03b7 />}></Route>
            <Route exact path="/Jobid6577df0c47c06059e60b050f" element={<Jobid6577df0c47c06059e60b050f />}></Route>
            <Route exact path="/Jobid657ab43f79d7362543de9cc0" element={<Jobid657ab43f79d7362543de9cc0 />}></Route>
            <Route exact path="/Jobid657ab4ae79d7362543de9cc2" element={<Jobid657ab4ae79d7362543de9cc2 />}></Route>
            <Route exact path="/Jobid657b3cfd82fbf0c0e7cb19ba" element={<Jobid657b3cfd82fbf0c0e7cb19ba />}></Route>
            <Route exact path="/Jobid657b3fba82fbf0c0e7cb19d4" element={<Jobid657b3fba82fbf0c0e7cb19d4 />}></Route>
            <Route exact path="/Jobid657b416782fbf0c0e7cb19f2" element={<Jobid657b416782fbf0c0e7cb19f2 />}></Route>
            <Route exact path="/Jobid657c814291e3a09a6b3b1a3d" element={<Jobid657c814291e3a09a6b3b1a3d />}></Route>
            <Route exact path="/Jobid657e7a14fd43e58859f6a32d" element={<Jobid657e7a14fd43e58859f6a32d />}></Route>
            <Route exact path="/Jobid658125ca80e67a73f78e38eb" element={<Jobid658125ca80e67a73f78e38eb />}></Route>
            <Route exact path="/Jobid65814a7e80e67a73f78e3a19" element={<Jobid65814a7e80e67a73f78e3a19 />}></Route>
            <Route exact path="/Jobid65830574f531b4b750ae32ec" element={<Jobid65830574f531b4b750ae32ec />}></Route>
            <Route exact path="/Jobid65830d78f531b4b750ae32ee" element={<Jobid65830d78f531b4b750ae32ee />}></Route>
            <Route exact path="/UPPSC-Technical-Education-Service-Exam-Online-Form-2023" element={<Jobid658468b5351fbc7155057505 />}></Route>
            <Route exact path="/Jobid658469fb351fbc7155057555" element={<Jobid658469fb351fbc7155057555 />}></Route>
            <Route exact path="/Central-Bank-of-India-Safai-Karamchari-Online-Form-2023" element={<Jobid65846baf351fbc715505759d />}></Route>
            <Route exact path="/Jharkhand-JSSC-Constable-Online-Form-2024" element={<Jobid65846d2f351fbc71550575b7 />}></Route>
            <Route exact path="/Allahabad-High-Court-UP-HJS-Online-Form-2024" element={<Jobid658715f87b2c7cdff234d190 />}></Route>
            <Route exact path="/IB-ACIO-II-/-Technical-Online Form" element={<Jobid658717b57b2c7cdff234d192 />}></Route>
            <Route exact path="/General-Insurance-GIC-Scale-I-Online-Form-2023" element={<Jobid658719547b2c7cdff234d198 />}></Route>
            <Route exact path="/UP-Police-Constable-60244-Post-Online-Form-2023" element={<Jobid658724d37b2c7cdff234d1ce />}></Route>
            <Route exact path="/Delhi-DSSSB-Assistant-Teacher-Nursery-Online-Form-2024" element={<Jobid6588e0ca55664aeb258967ce />}></Route>
            <Route exact path="/DSSSB-LDC,-Stenographer,-Junior-Assistant,-Other-Post-Online-Form" element={<Jobid6588e5e655664aeb258967d0 />}></Route>
            <Route exact path="/Allahabad-University-Assistant-Professor-Online-Form-2023" element={<Jobid6588eeed55664aeb25896876 />}></Route>
            <Route exact path="/Delhi-DSSSB-PGT-Teacher-Online-Form-2024" element={<Jobid658af78bdf51c4be8d61b4f8 />}></Route>
            <Route exact path="/BHU-Nursing-Officer,-Other-Post-Online-Form-2024" element={<Jobid658af78bdf51c4be8d61b4f8 />}></Route>
            <Route exact path="/UP-Police-SI,-ASI-Online-Form-2024" element={<Jobid658dba976ede4354aec46812 />}></Route>
            <Route exact path="/Bihar-Vidhan-Sabha-Sachivalaya-Various-Post-Online-Form-2024" element={<Jobid659051076e3fbd9babbf1e85 />}></Route>
            <Route exact path="/Delhi-DSSSB-Section-Officer-Online-Form-2024" element={<Jobid659055596e3fbd9babbf1e89 />}></Route>
            <Route exact path="/UP-Police-computer-operator/programmer-online-form-2024" element={<Jobid65905a3b6e3fbd9babbf1ea5 />}></Route>
            <Route exact path="/UPPSC-Pre-2024-Online-Form" element={<Jobid659575e0954732b01d17bf08 />}></Route>
            <Route exact path="/Indian-Airforce-Intake-01/2025-Online-Form" element={<Jobid659577fb954732b01d17bf24 />}></Route>
            <Route exact path="/Navy-B.Tech-Entry-July-2024-Online-Form" element={<Jobid65982e29011eedafc436df5f />}></Route>
            <Route exact path="/RPSC-Vidhi-Rachnakar-Online-Form" element={<Jobid65998da85951f30848368acc />}></Route>
            <Route exact path="/UIIC-Administrative-Officer-Scale-I-Online-Form-2024" element={<Jobid659b6ecb0283ce1ccb67f75f />}></Route>
            <Route exact path="/NWR-RRC-Jaipur-Apprentices-Online-Form-2024" element={<Jobid659c305f0283ce1ccb67f8df />}></Route>
            <Route exact path="/RPSC-Assistant-Professor-Online-Form-2024" element={<Jobid65a25d2a233e0f147da09fc3 />}></Route>
            <Route exact path="/CSIR-CBRI -echnical-Assistant-Online-Form-2024" element={<Jobid65a25e61233e0f147da09fc5 />}></Route>
            <Route exact path="/DSSSB-SPA,-PA,-JJA-Online-Form-2024" element={<Jobid65a2c6b5233e0f147da0a183 />}></Route>
            <Route exact path="/NCL-CIL-Assistant-Foreman-Online-Form-2024" element={<Jobid65a69dcc9c722d2408f9d299 />}></Route>
            <Route exact path="/BPSC-Agriculture-Various-Post-Online-Form-2024" element={<Jobid65a69fd19c722d2408f9d2b1 />}></Route>
            <Route exact path="/RRB-ALP-Recruitment-2024" element={<Jobid65b111b2cc886cd2f1b8af8c />}></Route>
            <Route exact path="/Delhi-Home-Guard-Recruitment-2024" element={<Jobid65b1135dcc886cd2f1b8af8e />}></Route>
            <Route exact path="/Railway-RRB-Assistant-Loco-Pilot-ALP-Online-Form-2024" element={<Jobid65b879f2773290f27719118e />}></Route>
            <Route exact path="/New-India-Assurance-NIACL-Assistant-Online-Form-2024" element={<Jobid65b888c8773290f2771911d8 />}></Route>
            <Route exact path="/Southern-Railway-Apprentices-Online-Form-2024" element={<Jobid65b91b14773290f2771912e7 />}></Route>
            <Route exact path="/Supreme-Court-of-India-SCI-Law-Clerk-Online-Form-2024" element={<Jobid65b91c16773290f2771912e9 />}></Route>
            <Route exact path="/JPSC-Pre-Online-Form-2024" element={<Jobid65b91cd5773290f2771912eb />}></Route>
            <Route exact path="/UKPSC-Uttarakhand-Police-Sub-Inspector-Online-Form-2024" element={<Jobid65bbbd4a6633c8d4740fa976 />}></Route>
            <Route exact path="/Railway-RRB-Technician-CEN-Online-Form" element={<Jobid65bbbe9f6633c8d4740fa978 />}></Route>
            <Route exact path="/RPSC-Senior-Teacher-TGT-Online-Form-2024" element={<Jobid65bbc0146633c8d4740fa97a />}></Route>
            <Route exact path="/Bihar-Sakshamta-Pariksha-Online-Form-2024" element={<Jobid65bbc1586633c8d4740fa97e />}></Route>
            <Route exact path="/SSC-Selection-Post-XII-Online-Form" element={<Jobid65bbc2cc6633c8d4740fa980 />}></Route>
            <Route exact path="/DSSSB-Various-12785-Post-Online-Form-2024" element={<Jobid65bd1fad3f0c75d734fc12e3 />}></Route>
            <Route exact path="/Coast-Guard-Navik-GD-02/2024-Online-Form" element={<Jobid65c0ef0b3602e8eaa429a4e9 />}></Route>
            <Route exact path="/Coast-Guard-Assistant-Commandant-Online-Form-2024" element={<Jobid65c0f02e3602e8eaa429a501 />}></Route>
            <Route exact path="/PNB-SO-Online-Form-2024" element={<Jobid65c0f1b03602e8eaa429a505 />}></Route>
            <Route exact path="/BPSC-School-Teacher-TRE-3.0-Online-Form-2024" element={<Jobid65c5036a1b7bdbb95522699a />}></Route>
            <Route exact path="/UPSSSC-Various-Post-Online-Form-Through-PET-2023" element={<Jobid65c508211b7bdbb955226aa4 />}></Route>
            <Route exact path="/Army-Agniveer-CEE-Online-Form-2024" element={<Jobid65c653df8b7582cec6f505fb />}></Route>
            <Route exact path="/Haryana-Police-Constable-Online-Form-2024" element={<Jobid65ca4b3438ad41b80489a4f7/>}></Route>
            <Route exact path="/UPSC-Civil-Services-IAS-/-IFS-Pre-Online-Form-2024" element={<Jobid65ce241553821e026f6f843a/>}></Route>
            <Route exact path="/SBI-Specialist-Officer-SO-Online-Form-2024" element={<Jobid65ce25d153821e026f6f843c/>}></Route>
            <Route exact path="/RSMSSB-Junior-Assistant-Online-Form-2024" element={<Jobid65d22a8dc42fd4e428f026ef/>}></Route>
            <Route exact path="/RSMSSB-Hostel-Superintendent-Online-Form-2024" element={<Jobid65d37fc4dd7715fdc5147b5b/>}></Route>
            <Route exact path="/Navy-SSC-Officers-January-2025-Batch-Online-Form" element={<Jobid65d4dcbd6c00a7fb139a8e82/>}></Route>
            <Route exact path="/BPSC-Assistant-Architect-Online-Form-2024" element={<Jobid65d4ddbb6c00a7fb139a8e84/>}></Route>
            <Route exact path="/Jharkhand-High-Court-Typist-and-Stenographer-Online-Form-2024" element={<Jobid65dcbe14fa3982400e8f962f/>}></Route>
            <Route exact path="/BPSC-Block-Horticulture-Officer-BHO-Online-Form-2024" element={<Jobid65dcbfa4fa3982400e8f9631/>}></Route>
            <Route exact path="/RSMSSB-Stenographer-PA-Online-Form-2024" element={<Jobid65dcc0a5fa3982400e8f9633/>}></Route>
            <Route exact path="/UPPSC-Staff-Nurse-Mains-Online-Form-2024" element={<Jobid65e0b15209b55e2e7fb013fb/>}></Route>
            <Route exact path="/Railway-RPF-Constable-/-Sub-Inspector-Online-Form-2024" element={<Jobid65e1ff259cccb1daaa07a8e4/>}></Route>
            <Route exact path="/BPSC-Head-Teacher-Online-Form" element={<Jobid65f1cace86b569fc7c3328f9/>}></Route>
            <Route exact path="/CTET-July-2024-Online-Form" element={<Jobid65f1cbeb86b569fc7c3328fb/>}></Route>
            <Route exact path="/Railway-RRB-Technician-Online-Form-2024" element={<Jobid65f1ccf486b569fc7c3328fd/>}></Route>
            <Route exact path="/SSC-Sub-Inspector-CPO-SI-Online-Form-2024" element={<Jobid65f1ce1486b569fc7c3328ff/>}></Route>
            <Route exact path="/UPSC-EPFO-Personal-Assistant-Online-Form-2024" element={<Jobid65f1cf0f86b569fc7c332901/>}></Route>
            <Route exact path="/UPPSC-APS-2023-Mains-Online-Form" element={<Jobid65f31a14bab34a5aed9355b6/>}></Route>
            <Route exact path="/Punjab-Police-Constable-Online-Form-2024" element={<Jobid65f31b32bab34a5aed9355ba/>}></Route>
            <Route exact path="/RCF-Kapurthala-Apprentices-Online-Form-2024" element={<Jobid65f31d31bab34a5aed9355bc/>}></Route>
            <Route exact path="/UKPSC-Sub-Inspector-Online-Form-2024" element={<Jobid65f5c5fb485b8b382aea6219/>}></Route>
            <Route exact path="/UKPSC-Pre-2024-Online-Form" element={<Jobid65f5c988485b8b382aea621b/>}></Route>
            <Route exact path="/MPPSC-State-Eligibility-Test-SET-Online-Form-2024" element={<Jobid65fb0ea63102df0d43fa9ca2/>}></Route>
            <Route exact path="/SSC-Selection-Post-XII-OnlineForm" element={<Jobid65fb0fe03102df0d43fa9ca6/>}></Route>
            <Route exact path="/BSF-Group-B-and-C-Various-Post-Online-Form-2024" element={<Jobid65fb12913102df0d43fa9ca8/>}></Route>
            <Route exact path="/NVS-Non-Teaching-Various-Post-Online-Form-2024" element={<Jobid66045e5cb60f2187fcec0d77/>}></Route>
            <Route exact path="/Oriental-Insurance-OICL-AO-Online-Form-2024" element={<Jobid66046078b60f2187fcec0da9/>}></Route>
            <Route exact path="/SSC-Junior-Engineer-JE-Online-Form-2024" element={<Jobid660ba382d163e51a3636926a/>}></Route>
            <Route exact path="/Railway-SECR-Apprentice-Online-Form-2024" element={<Jobid660ed9c65bf9ca1f8c3ccbfe/>}></Route>
            <Route exact path="/Bihar-BSPHCL-Various-Post-Online-Form-2024" element={<Jobid660edc085bf9ca1f8c3ccc00/>}></Route>
            <Route exact path="/Army-10+2-TES-52-Online-Form-2024" element={<Jobid6637b1dace9a02a79b792171/>}></Route>
            <Route exact path="/Indian-Navy-Agniveer-SSR-/-MR-Online-Form-02/2024-Batch" element={<Jobid6637b329ce9a02a79b792173/>}></Route>
            <Route exact path="/NTA-CSIR-UGC-NET-Online-Form-2024" element={<Jobid6637b4cece9a02a79b792175/>}></Route>

























{/*Routing for job news*/}

            <Route exact path="/Newsid65479d252d9a4ffaef2bf534" element={<Newsid65479d252d9a4ffaef2bf534 />}></Route>
            <Route exact path="/Newsid657e809bfd43e58859f6a371" element={<Newsid657e809bfd43e58859f6a371 />}></Route>
            <Route exact path="/Newsid657e859dfd43e58859f6a389" element={<Newsid657e859dfd43e58859f6a389 />}></Route>
          {/*Routing for Result page*/}

            <Route exact path="/Pageid6546817c929bd0db64758256" element={<Pageid6546817c929bd0db64758256 />}></Route>
            <Route exact path="/Pageid6546fe907b9877dc01782f38" element={<Pageid6546fe907b9877dc01782f38 />}></Route>
            <Route exact path="/Pageid654701287b9877dc01782f3a" element={<Pageid654701287b9877dc01782f3a />}></Route>
            <Route exact path="/Pageid65472dd32441dbd7091f7d2b" element={<Pageid65472dd32441dbd7091f7d2b />}></Route>
            <Route exact path="/Pageid6547312c2441dbd7091f7d3e" element={<Pageid6547312c2441dbd7091f7d3e />}></Route>
            <Route exact path="/Pageid655d392bf5a4b9f8210a6703" element={<Pageid655d392bf5a4b9f8210a6703 />}></Route>
            <Route exact path="/Pageid6560bb1b2c391fde12d3c447" element={<Pageid6560bb1b2c391fde12d3c447 />}></Route>
            <Route exact path="/Pageid656169f1eabef241d18f9544" element={<Pageid656169f1eabef241d18f9544 />}></Route>
            <Route exact path="/Pageid65662eb128ba3728112ce88b" element={<Pageid65662eb128ba3728112ce88b />}></Route>
            <Route exact path="/Pageid656630ed28ba3728112ce88d" element={<Pageid656630ed28ba3728112ce88d />}></Route>
            <Route exact path="/Pageid65695fcf8b86ad1249ca4823" element={<Pageid65695fcf8b86ad1249ca4823 />}></Route>
            <Route exact path="/Pageid656b61cc8f20c17da5b5e750" element={<Pageid656b61cc8f20c17da5b5e750 />}></Route>
            <Route exact path="/Pageid656ca7590beb0bfb3ea0718b" element={<Pageid656ca7590beb0bfb3ea0718b />}></Route>
            <Route exact path="/Pageid656e0b96335f485f1bbeb9e0" element={<Pageid656e0b96335f485f1bbeb9e0 />}></Route>
            <Route exact path="/Pageid656f30939ab81286ba2ff5e5" element={<Pageid656f30939ab81286ba2ff5e5 />}></Route>
            <Route exact path="/Pageid656f51c59ab81286ba2ff86e" element={<Pageid656f51c59ab81286ba2ff86e />}></Route>
            <Route exact path="/Pageid656f54ea9ab81286ba2ff870" element={<Pageid656f54ea9ab81286ba2ff870 />}></Route>
            <Route exact path="/Pageid656f66e89ab81286ba2ffcb1" element={<Pageid656f66e89ab81286ba2ffcb1 />}></Route>
            <Route exact path="/Pageid6570a38f08506fd46394e551" element={<Pageid6570a38f08506fd46394e551 />}></Route>
            <Route exact path="/Pageid6570aa0408506fd46394e66e" element={<Pageid6570aa0408506fd46394e66e />}></Route>
            <Route exact path="/Pageid6570b35b08506fd46394e80a" element={<Pageid6570b35b08506fd46394e80a />}></Route>
            <Route exact path="/Pageid6573308c62f25dfc835fdeb4" element={<Pageid6573308c62f25dfc835fdeb4 />}></Route>
            <Route exact path="/Pageid657469217b4ad8e67ef3bfb6" element={<Pageid657469217b4ad8e67ef3bfb6 />}></Route>
            <Route exact path="/Pageid65748f5e7b4ad8e67ef3c12f" element={<Pageid65748f5e7b4ad8e67ef3c12f />}></Route>
            <Route exact path="/Pageid6575de5f868f14a1b3cf1045" element={<Pageid6575de5f868f14a1b3cf1045 />}></Route>
            <Route exact path="/Pageid6575e06d868f14a1b3cf1078" element={<Pageid6575e06d868f14a1b3cf1078 />}></Route>
            <Route exact path="/Pageid6575e23a868f14a1b3cf10bd" element={<Pageid6575e23a868f14a1b3cf10bd />}></Route>
            <Route exact path="/Pageid657886e60398ae575fa2f02c" element={<Pageid657886e60398ae575fa2f02c />}></Route>
            <Route exact path="/Pageid657adfc179d7362543de9d4c" element={<Pageid657adfc179d7362543de9d4c />}></Route>
            <Route exact path="/Pageid657b39ec82fbf0c0e7cb199c" element={<Pageid657b39ec82fbf0c0e7cb199c />}></Route>
            <Route exact path="/Pageid657c7c6291e3a09a6b3b1a23" element={<Pageid657c7c6291e3a09a6b3b1a23 />}></Route>
            <Route exact path="/Pageid65810c3880e67a73f78e37e" element={<Pageid65810c3880e67a73f78e37e />}></Route>
            <Route exact path="/Pageid65810f1180e67a73f78e3822" element={<Pageid65810f1180e67a73f78e3822 />}></Route>
            <Route exact path="/Pageid65832baff531b4b750ae34e2" element={<Pageid65832baff531b4b750ae34e2 />}></Route>
            <Route exact path="/Pageid65832cf9f531b4b750ae34e4" element={<Pageid65832cf9f531b4b750ae34e4 />}></Route>
            <Route exact path="/Pageid65832f55f531b4b750ae34e6" element={<Pageid65832f55f531b4b750ae34e6 />}></Route>
            <Route exact path="/Pageid65845ca0351fbc715505749b" element={<Pageid65845ca0351fbc715505749b />}></Route>
            <Route exact path="/Pageid65845e2e351fbc715505749d" element={<Pageid65845e2e351fbc715505749d />}></Route>
            <Route exact path="/Pageid65845f51351fbc71550574b5" element={<Pageid65845f51351fbc71550574b5 />}></Route>
            <Route exact path="/Pageid6585bdedf952fb0d800b622f" element={<Pageid6585bdedf952fb0d800b622f />}></Route>
            <Route exact path="/Pageid6585c1ecf952fb0d800b6249" element={<Pageid6585c1ecf952fb0d800b6249 />}></Route>
            <Route exact path="/Pageid658720737b2c7cdff234d1b2" element={<Pageid658720737b2c7cdff234d1b2 />}></Route>
            <Route exact path="/NCR-Railway-RRC-Prayagraj-Apprentices-Merit-List-2023" element={<Pageid658af618df51c4be8d61b4de />}></Route>
            <Route exact path="/SSC-MTS-/-Havaldar-2023-Final-Result-with-Marks" element={<Pageid658dc0826ede4354aec4682c />}></Route>
            <Route exact path="/SSC-Delhi-Police-HC-Ministerial-Final-Result-2023" element={<Pageid658dc26f6ede4354aec4682e />}></Route>
            <Route exact path="/CISF-ASI-Steno-/-HC-Ministerial-2022-CBT-Exam-Result" element={<Pageid6595863e954732b01d17bf3c />}></Route>
            <Route exact path="/UP-NHM-Staff-Nurse-2022-Result-(17000 Post)" element={<Pageid6596d34f3daba23251e28f1f />}></Route>
            <Route exact path="/NTA-EPFO-SSA-2023-Final-Result" element={<Pageid6596d7713daba23251e28f7d />}></Route>
            <Route exact path="/IBPS-RRB-XII-Office-Assistant-Mains-Result-2024" element={<Pageid6596dae93daba23251e28fab />}></Route>
            <Route exact path="/SSC-Junior-Engineer-JE-2023-Final-Result" element={<Pageid659834c6011eedafc436df99 />}></Route>
            <Route exact path="/Railway-ECR-Apprentice-2023-Result" element={<Pageid65983618011eedafc436df9b />}></Route>
            <Route exact path="/UPSSSC-Instructor-2022-Eligibility-Result" element={<Pageid659a14efb6268f7865337bd8 />}></Route>
            <Route exact path="/NTA-UGC-NET-/-JRF-December-2023-Exam-Result-Date" element={<Pageid65a01ea17742a9d7bd14d807 />}></Route>
            <Route exact path="/CISF-Constable-Tradesman-2022-Result" element={<Pageid65a0203f7742a9d7bd14d821 />}></Route>
            <Route exact path="/SBI-PO-2023-Mains-Phase-II-Result" element={<Pageid65a021b17742a9d7bd14d87f />}></Route>
            <Route exact path="/UPSC-Forest-Services-IFS-2023-Main-Result" element={<Pageid65a160137742a9d7bd14db67 />}></Route>
            <Route exact path="/IBPS-SO-13th-Pre-Result-2024" element={<Pageid65a69bd09c722d2408f9d27f />}></Route>
            <Route exact path="/NTA-UGC-NET-December-2023-Result" element={<Pageid65a7ffe72c9239e0b719c730 />}></Route>
            <Route exact path="/IBPS-PO-13th-Mains-Result-2024" element={<Pageid65b91e76773290f27719131b />}></Route>
            <Route exact path="/MPESB-Various-Agriculture-Post-Result-2024" element={<Pageid65b91f88773290f27719131d />}></Route>
            <Route exact path="/UPPCL-Technician-TG-II-Electrical-2022-Result" element={<Pageid65bbcae36633c8d4740fa9e0 />}></Route>
            <Route exact path="/Allahabad-High-Court-APS-2021-Cutoff" element={<Pageid65bbcd3e6633c8d4740fa9fa />}></Route>
            <Route exact path="/AAI-Junior-Executive-ATS-2023-Result" element={<Pageid65bd21453f0c75d734fc1315 />}></Route>
            <Route exact path="/EMRS-Junior-Secretariat-Assistant-2023-Additional-Result" element={<Pageid65c24e19c63956a0841a8957 />}></Route>
            <Route exact path="/Delhi-High-Court-Judicial-Services-Pre-2023-Result-with-Marks" element={<Pageid65c24f3cc63956a0841a8959 />}></Route>
            <Route exact path="/BLW-Railway-Apprentices-2023-Merit-List" element={<Pageid65c5154a1b7bdbb955226aee />}></Route>
            <Route exact path="/DFCCIL-Various-Post-2023-Stage-II-Result" element={<Pageid65c655cd8b7582cec6f505fd />}></Route>
            <Route exact path="/Bihar-BTSC-ANM-2022-Result-/-Score-Card" element={<Pageid65ca4c7938ad41b80489a50f />}></Route>
            <Route exact path="/CTET-January-2024-Result" element={<Pageid65ce2c4953821e026f6f84e6 />}></Route>
            <Route exact path="/SBI-Clerk-Pre-Result-2024" element={<Pageid65cf78edcfc1d230ba7b15c4 />}></Route>
            <Route exact path="/UPSSSC-Junior-Engineer-2018-Supplementary-Result" element={<Pageid65d22ef8c42fd4e428f026f3 />}></Route>
            <Route exact path="/UPPSC-Staff-Nurse-2023-Pre-Result" element={<Pageid65d4def76c00a7fb139a8e9c />}></Route>
            <Route exact path="/SBI-Apprentices-2023-Final-Result" element={<Pageid65dcc26ffa3982400e8f9637 />}></Route>
            <Route exact path="/MHA-IB-SA-MTS-2022-Final-Result" element={<Pageid65e0b4fa09b55e2e7fb01452 />}></Route>
            <Route exact path="/UKPSC-RO-ARO-2023-Pre-Result-Cutoff-Marks" element={<Pageid65e0b70609b55e2e7fb01488 />}></Route>
            <Route exact path="/Intelligence-Bureau-IB-SA/MT-and-MTS-Result-2024" element={<Pageid65f1d48686b569fc7c332907/>}></Route>
            <Route exact path="/Chandigarh-Police-Constable-IT-Result-2024" element={<Pageid65f1d54286b569fc7c332909/>}></Route>
            <Route exact path="/Patna-High-Court-Stenographer-2023-Final-Result" element={<Pageid65f31e0fbab34a5aed9355be/>}></Route>
            <Route exact path="/NIC-Scientific-and-Technical-Post-Result-2024" element={<Pageid65f31f37bab34a5aed9355c0/>}></Route>
            <Route exact path="/IIT-GATE-2024-Result-Score-Card" element={<Pageid65f5ccab485b8b382aea621d/>}></Route>
            <Route exact path="/MHA-IB-ACIO-2023-Result" element={<Pageid6605ac88c9bd03979ec61d5a/>}></Route>
            <Route exact path="/BSEB-Bihar-Sakshamta-Pariksha-Result-2024" element={<Pageid6607683a7ebc26e8e269f042/>}></Route>

            
            
            
            
            
            
            
            
            











{/*Routing for updates page*/}
            <Route exact path="/Jobid654dee73738b62cb12722434" element={<Jobid654dee73738b62cb12722434 />}></Route>
            <Route exact path="/Jobid654e46f5738b62cb12722436" element={<Jobid654e46f5738b62cb12722436 />}></Route>
            <Route exact path="/Jobid654e483e738b62cb12722438" element={<Jobid654e483e738b62cb12722438 />}></Route>
            <Route exact path="/Jobid654e4981738b62cb1272243c" element={<Jobid654e4981738b62cb1272243c />}></Route>
            <Route exact path="/Jobid654e494a738b62cb1272243a" element={<Jobid654e494a738b62cb1272243a />}></Route>
            <Route exact path="/Jobid6568b8f32829fd098633ed4d" element={<Jobid6568b8f32829fd098633ed4d />}></Route>
            <Route exact path="/Jobid656a10e9a40bc2f1aa74d091" element={<Jobid656a10e9a40bc2f1aa74d091 />}></Route>


            {/*Routing for important page*/}
            <Route exact path="/Pageid655fe7f73a6d7e513a4f2aa7" element={<Pageid655fe7f73a6d7e513a4f2aa7 />}></Route>
            <Route exact path="/Pageid656006992c391fde12d3bd03" element={<Pageid656006992c391fde12d3bd03 />}></Route>
            <Route exact path="/Pageid656f6d399ab81286ba2ffddb" element={<Pageid656f6d399ab81286ba2ffddb />}></Route>
            <Route exact path="/Pageid657017429ab81286ba300057" element={<Pageid657017429ab81286ba300057 />}></Route>
            <Route exact path="/Pageid6570713308506fd46394e339" element={<Pageid6570713308506fd46394e339 />}></Route>
            <Route exact path="/Pageid6570717a08506fd46394e33b" element={<Pageid6570717a08506fd46394e33b />}></Route>
            <Route exact path="/Pageid65788b6a0398ae575fa2f044" element={<Pageid65788b6a0398ae575fa2f044 />}></Route>

            {/*Exam Pattern*/}
            <Route exact path="/ExamPatternsbi" element={<ExamPatternsbi />}></Route>
            {/*<Route exact path="/Panjabpolice" element={< Panjabpolice/>}></Route>*/}






          </Routes>
        </BrowserRouter>
      </div>
    </div>
  );
}

export default App;
