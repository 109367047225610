
import React,{useEffect,useState} from 'react'
import axios from 'axios';
import './images/test.jpg'
import loader from '../../../assets/loader.gif'
import './JobPage.css'
import './jobhead.css'
import Heading from '../../../Heading';
import Footer from '../../../Footer';
import NavbarComponent from '../../../NavbarComponent';
import { Helmet } from 'react-helmet';
function Jobid659c305f0283ce1ccb67f8df() {
 const[jobList,setjobList]=useState([]);
//const [users,setUser]=useState([])
  //useEffect(()=>{
   // fetch("/data").then((result)=>{
      //result.json().then((resp)=>{
        //console.warn(resp)
       // setUser(resp)
      //})
    //})
 // },[])
  //console.warn(users)

  useEffect(()=>{
    //console.log("hello")
    axios.get('https://naukridekho-backend-92571da7a098.herokuapp.com/govjob')
    .then(res=>{
      //console.log(res.data.govjobData);
      setjobList(res.data.govjobData);
      setloading(false);
    })
    .catch(err=>{
      console.log(err);
      setloading(false);
    })
  },[]);
  const[isloading,setloading]=useState(true);
  //console.log(jobList);
  return (
   <>
   <Helmet>
    <title>{jobList[66]?.name}</title>
    <script async src="https://pagead2.googlesyndication.com/pagead/js/adsbygoogle.js?client=ca-pub-9504527545697458"
     crossorigin="anonymous"></script>
    <meta name='description'
    content={jobList[66]?.information} 
    />
    <meta name='keyword'
    content={jobList[66]?.details}
    />
    <meta name='date'content={jobList[66]?.important_date}
    />
     <link rel="canonical" href='/NWR-RRC-Jaipur-Apprentices-Online-Form-2024'/>
   </Helmet>
   <Heading/>
    <NavbarComponent/>
   
{/*Job related details*/}
{isloading &&
<img style={{width:'300px', marginLeft:'38%'}} src={loader} />
}
{!isloading &&
<div className='job-body' style={{paddingTop:"10%"}}>
      <div className='div1'>
        <h2 className='job-h2'>Name of the post</h2>
         <h3>{jobList[66]?.name}</h3>
      </div>
      <div className='div1'>
        <h2 className='job-h2'>Date</h2>
          <h3>{jobList[66]?.date}</h3>
    </div>
    <div className='div1'>
        <h2 className='job-h2'>Information</h2>
          <h3>{jobList[66]?.information}</h3>
    </div>
    <div className='div1'>
        <h2 className ='job-h2'>Important Date </h2>
          <h3>{jobList[66]?.important_date}</h3>
    </div>
    <div className='div1'>
        <h2 className='job-h2'>Application Fee</h2>
      
          <h3>{jobList[66]?.application_fee}</h3>
    </div>
    <div className='div1'>
        <h2 className='job-h2'>Eligibility</h2>
          <h3>{jobList[66]?.eligibility}</h3>
    </div>
    <div className='div1'>
        <h2 className='job-h2'>Age Limit</h2>
          <h3>{jobList[66]?.agelimit}</h3>
    </div>
    <div className='div1'>
        <h2 className='job-h2'>Vacancy Details</h2>
          <h3>{jobList[66]?.vacancy_details}</h3>
    </div>
    <div className='div1'>
        <h2 className='job-h2'>Important links</h2>
          <h3>Apply Online< a href={jobList[66]?.important_links}> {jobList[66]?.important_links}</a></h3>
    </div>
    <div className='div1'>
        <h2 className='job-h2'>Syllabus</h2>
          <h3>< a href=""> Uploading Soon...</a></h3>
    </div>
    <div className='div1'>
        <h2 className='job-h2'>Exam Pattern</h2>
          <h3>< a href=""> Uploading Soon...</a></h3>
    </div>
    <div className='div1'>
        <h2 className='job-h2'>Test Series</h2>
          <h3>< a href=""> Uploading Soon...</a></h3>
    </div>
    <div className='div1'>
        <h2 className='job-h2'>Previous Year</h2>
          <h3>< a href=""> Uploading Soon...</a></h3>
    </div>
 </div> 
}   
    

<Footer/>

</>
  )}


export default Jobid659c305f0283ce1ccb67f8df