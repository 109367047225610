import React from 'react'
import startlogo from './assets/startlogo.jpg.jpg'
import service from './assets/ghu.jpeg.jpg'
import goals from './assets/goals.jpg.jpg'
import './Aboutus.css'
import Footer from '../Footer'
import NavbarComponent from '../NavbarComponent'
import Menubar from '../Menubar'

export default function Aboutus() {
  return (
    <div>
      <NavbarComponent/>
      <Menubar/>
      <div className='header'>
        
        <div className='header-child'>
        <h1>Naukridekho.info</h1></div>
        <div className='header-child-1'>
        <h2>Our History and Achievements.</h2></div>
        <div className='service1'> 
            <div className='service-heading1'>
            <h3>Startup</h3>
            </div>
            <div className='firstpic1'>
            <img src={startlogo} className= "startlogo.jpg"alt="startup"/>
            </div>
            <div className='service-content1'>
            <p>
            **Greetings, Future Leaders! **
            <br></br><br></br>
            We are thrilled to welcome you to Naukridekho.info, a visionary initiative founded by a dynamic group of B.Tech students. Our journey began in November 2023 with a mission that goes beyond mere business — we aim to foster a community that contributes to the growth and development of our beloved country.<br></br> <br></br>

*Our Unique Vision:*
<br></br>
<br></br>

At Naukridekho.info, our purpose goes beyond profit. We are here to create a collaborative space where knowledge, opportunities, and aspirations converge. This isn't just a startup; it's a movement led by students, for students, and for the greater good of our nation.<br></br><br></br>

*Community First:*
<br></br><br></br>

Unlike traditional platforms, our focus isn't solely on revenue. We are building a community that transcends boundaries and empowers individuals to contribute to the progress of our country. Join us in this collective journey towards a brighter future.<br></br><br></br>

*Student-Led Innovation:*<br></br><br></br>

As a startup initiated by B.Tech students, we understand the aspirations and challenges faced by the student community. Our approach is grounded in the belief that students have the power to drive positive change, and Naukridekho.info is a testament to this spirit.<br></br><br></br>

*Success is a Journey:*<br></br><br></br>

While success is undoubtedly a goal, we measure it not just in terms of milestones but in the impact we collectively make. We believe that by building a strong community, success will naturally follow. Together, let's shape the future.

Thank you for being part of the Naukridekho.info community. Your journey towards success is our inspiration, and we look forward to achieving milestones together.<br></br><br></br>

Best wishes,
The Naukridekho.info Team
            </p>
            </div>
            
            </div>

            <div className='service1'> 
            <div className='service-heading1'>
            <h3>Service</h3>
            </div>
            <div className='firstpic1'>
            <img src={service} className= "services pic.jpeg"alt="service"/>
            </div>
            <div className='service-content1'>
            <p>
              *Welcome, Valued Visitors! 🌟*<br></br><br></br>

At naukridekho.info, we are dedicated to providing unique and insightful content tailored for students aiming to excel in various competitive examinations. Whether you're preparing for UPSC, SSC CGL, or other government job exams, or you're a budding B.Tech or Polytechnic student aspiring for private sector opportunities, we've got you covered!<br></br><br></br>

*Why Choose of naukridekho.info?*<br></br><br></br>

1. *Comprehensive Exam Preparation:*<br></br><br></br>
   Our meticulously curated content is designed to cater to the diverse needs of students preparing for a wide array of competitive exams, ensuring you are well-equipped to tackle any challenge.<br></br><br></br>

2. *Timely Job Information:*<br></br><br></br>
   Stay ahead in your career journey with our prompt updates on job opportunities, both in the government and private sectors. We believe in keeping you informed about the latest employment trends.<br></br><br></br>

3. *Holistic Development:*<br></br><br></br>
   Beyond exam preparation, we are committed to fostering your personal and professional growth. Our platform aims to develop your mindset and personality, empowering you to face the world with confidence.<br></br><br></br>

*Why Stay with Us?*<br></br><br></br>

1. *Knowledge Enhancement:*<br></br><br></br>
   Immerse yourself in a wealth of knowledge that goes beyond textbooks. Our platform is a reservoir of information that will not only help you ace exams but also broaden your intellectual horizons.<br></br><br></br>

2. *Long-Term Learning:*<br></br><br></br>
   We encourage you to stay with us for the long term. Continuous learning is the key to success, and we're here to support your journey every step of the way.<br></br><br></br>

3. *Mindset Transformation:*<br></br><br></br>
   Elevate your mindset and shape your personality for success. Our resources are designed not just to help you pass exams but to transform you into a well-rounded, confident individual ready to conquer any challenge.<br></br><br></br>

Thank you for choosing Naukridekho.info as your partner in success. Explore, learn, and embark on a journey of knowledge and self-discovery with us!<br></br><br></br>

Best regards,
Naukridekho.info Team
            </p>
            </div>
            </div>

            <div className='service1'> 
            <div className='service-heading1'>
            <h3>Our Goals</h3>
            </div>
            <div className='firstpic1'>
            <img src={goals} className= "goals.jpg"alt="Goals"/>
            </div>
            <div className='service-content1'>
            <p>
            **Dear Supporters and Aspiring Achievers! 🚀**<br></br><br></br>

Welcome to Naukridekho.info, where education and job opportunities converge on a journey towards a brighter future. Our startup, initiated with a deep sense of purpose, is fueled by the collective passion of our student founders.
<br></br><br></br>
**Our Motto and Goals:**<br></br><br></br>

At Naukridekho.info, our driving force is education and job empowerment. We are committed to bridging the gap between learning and professional success. Our goal is not just to meet but to exceed the criteria of education and job readiness, ensuring that every individual is well-prepared for the challenges that lie ahead.
<br></br><br></br>
**Slogan: Aap Taiyari Kare, Naukri Khojna Hamara Kaam Hai**<br></br><br></br>

This isn't just a tagline; it's our commitment. We believe that your preparation is crucial, and finding the right job is our responsibility. Together, let's navigate the path to success.
<br></br><br></br>
**Your Support Matters:**<br></br><br></br>

Our success hinges on your support. By sharing and placing your faith in Naukridekho.info, you are contributing to a movement that goes beyond just a startup. You are supporting a community dedicated to the twin pillars of education and job fulfillment.
<br></br><br></br>
**Join Us on this Journey:**<br></br><br></br>

As we embark on this exciting journey, we invite you to be an integral part of our growth. Your support is not just an endorsement; it's a vote of confidence in the transformative power of education and employment.
<br></br><br></br>
Thank you for believing in Naukridekho.info. Together, let's create a future where aspirations are met, and dreams become reality.
<br></br><br></br>
With gratitude,
The Naukridekho.info Team
            </p>
            </div>
            </div>


      </div>
      <Footer/>
      </div>
  )
}
