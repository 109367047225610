
import React,{useEffect,useState} from 'react'
import axios from 'axios';
import loader from '../../../assets/loader.gif'
import '../govermentjob/JobPage.css'
import Footer from '../../../Footer';
import Heading from '../../../Heading';
import NavbarComponent from '../../../NavbarComponent';
import { Helmet } from 'react-helmet';

function Pageid65c5154a1b7bdbb955226aee() {
 const[resultList,setresultList]=useState([]);

  useEffect(()=>{
    //console.log("hello")
    axios.get('https://naukridekho-backend-92571da7a098.herokuapp.com/result')
    .then(res=>{
      //console.log(res.data.resultData);
      setresultList(res.data.resultData)
      setloading(false)
    })
    .catch(err=>{
      console.log(err);
      setloading(false)
    })
  },[]);
  const[isloading,setloading]=useState(true);
  //console.log(jobList);
  return (
   <>
   <Helmet>
    <title>{resultList[66]?.name}</title>
    <script async src="https://pagead2.googlesyndication.com/pagead/js/adsbygoogle.js?client=ca-pub-9504527545697458"
     crossorigin="anonymous"></script>
    <meta name='description'
    content={resultList[66]?.information} 
    />
    <meta name='keyword'
    content={resultList[66]?.details}
    />
    <meta name='date'content={resultList[66]?.important_date}
    />
    <link rel="canonical" href='/BLW-Railway-Apprentices-2023-Merit-List'/>
   </Helmet>
   <Heading/>
   <NavbarComponent/>
  
{/*Job related details*/}
{isloading &&
<img style={{width:'300px', marginLeft:'38%'}} src={loader} />
}
{!isloading &&
<div className='job-body'>
{/*<img style={{marginLeft:"" ,width:"100%"} } src={Poster}  alt='jpsc-pre-online-form-2024'/>*/}
<div className='div1'>
        <h2 className='job-h2'>Name of the Post</h2>
          <h3>{resultList[66]?.name}</h3>
    </div>
      <div className='div1'>
        <h2 className='job-h2'>Date</h2>
          <h3>{resultList[66]?.date}</h3>
    </div>
    <div className='div1'>
        <h2 className='job-h2'>Information</h2>
          <h3>{resultList[66]?.information}</h3>
    </div>
    <div className='div1'>
        <h2 className ='job-h2'>Important Date </h2>
          <h3>{resultList[66]?.important_date}</h3>
    </div>
    <div className='div1'>
        <h2 className='job-h2'> Details</h2>
          <h3>{resultList[66]?.details}</h3>
    </div>
    <div className='div1'>
        <h2 className='job-h2'>Official Site</h2>
          <h3> Visit Official site< a href={resultList[66]?.important_links1}> Click Here</a></h3>
    </div>
    
    <div className='div1'>
        <h2 className='job-h2'>Result</h2>
          <h3>Result Link< a href={resultList[66]?.important_links3}> Click Here</a></h3>
    </div>
    <div className='div1'>
        <h2 className='job-h2'>Syllabus</h2>
          <h3>< a href=""> Uploading Soon...</a></h3>
    </div>
    <div className='div1'>
        <h2 className='job-h2'>Exam Pattern</h2>
          <h3>< a href=""> Uploading Soon...</a></h3>
    </div>
    <div className='div1'>
        <h2 className='job-h2'>Test Series</h2>
          <h3>< a href=""> Uploading Soon...</a></h3>
    </div>
    <div className='div1'>
        <h2 className='job-h2'>Previous Year</h2>
          <h3>< a href=""> Uploading Soon...</a></h3>
    </div>
 </div> 
}   
  <Footer/> 



</>
  )}


export default Pageid65c5154a1b7bdbb955226aee