import React from 'react';
import twitter_logo from './images/social_media_logos/twitter.svg';
import insta_logo from './images/social_media_logos/insta.svg';
import linkedin_logo from './images/social_media_logos/linkedin.svg';
import fb_logo from './images/social_media_logos/fb.svg';
import youtube_logo from './images/social_media_logos/youtube.svg';
import logo from './images/logo .jpg';
import './css/Footer.css'
import { Link } from 'react-router-dom';
import Footer_content from './Footer_content'

function Footer() {
    return (
      <>
      <Footer_content/>
        <div className="footer">
        <div className="footer_upper">
          <div className="footer_list">
            <h3>Company </h3>
            <ul>
              <li>
              <Link to="/Aboutus">About</Link>
              </li>
              <li>
                <Link to="/Privacy">Privacy Policy</Link>
              </li>
              <li>
                <Link to="/Contactus">developer contact</Link>
              </li>

              <li>
                <a href="#">Career</a>
              </li>
              <li>
                <Link to="/Condition">Terms and Condition</Link>
              </li>
              <li>
                <Link to="/Disclaimer">Disclaimer</Link>
              </li>
              <li>
                <a href="https://www.naukridekho.info/sitemap.txt">Sitemap</a>
              </li>



            </ul>
          </div>
          <div className="footer_list">
            <h3>Products</h3>
            <ul>
              <li>
                <a href="#">Web</a>
              </li>
              <li>
                <a href="#">App</a>
              </li>
              <li>
                <a href="#">Latest Jobs</a>
              </li>
              <li>
                <a href="#">Goverment Jobs</a>
              </li>
              <li><a href="#">Private Jobs</a></li>
              <li><a href="#">Jobs News</a></li>
              <li><a href="#">Admit Card</a></li>
              <li><a href="#">Results</a></li>
            </ul>
          </div>
          <div className="footer_list">
            <h3>Community</h3>
            <ul>
              <li><a href="#">IITIANS</a></li>
              <li><a href="#">NITIANS</a></li>
              <li><a href="#">Medical students</a></li>
              <li><a href="#">BSC students</a></li>
              <li><a href="#">BED students</a></li>
              <li><a href="#">Educators</a></li>
              <li><a href="#">teachers</a></li>
              <li><a href="#">Graduates</a></li>
            </ul>
          </div>
          <div className="footer_list">
            <h3>Goals</h3>
            <ul>
              <li><a href="#">Helping to students</a></li>
              <li><a href="#">IIT JEE</a></li>
              <li><a href="#">BITSAT</a></li>
              <li><a href="#">NEET</a></li>
              <li><a href="#">Class 1st-12th</a></li>
              <li><a href="#">CBSE</a></li>
              <li><a href="#">ICSE</a></li>
              <li><a href="#">KVPY</a></li>
              <li><a href="#">NTSE</a></li>
            </ul>
          </div>
          <div className="footer_list">
            <h3>Contact Us</h3>
            <ul>
              <li><a href="#">naukaridekho37@gmail.com</a></li>
              <li><a href="#">linkdin profile</a></li>
              <li><a href="#">insta profile</a></li>
              <li><a href="#">learning app</a></li>
              <li><a href="#">Naukaridekho app</a></li>
            </ul>
          </div>
        </div>
        <div className="footer_lower">
          <div className="footer_lower_left">
            <div className="logo">
              <img src={logo}alt=''/>
            </div>
            <div className="copyright">
              &copy; Copyright 2024. All Rights Reserved.
            </div>
          </div>
          <div className="footer_lower_right">
            <div className="logos">
              <a href="#">
                <img src={fb_logo} alt="" />
              </a>
            </div>
            <div className="logos">
              <a href="#">
                <img src={insta_logo} alt="" />
              </a>
            </div>
            <div className="logos">
              <a href="#">
                <img src={linkedin_logo} alt="" />
              </a>
            </div>
            <div className="logos">
              <a href="#">
                <img src={twitter_logo} alt="" />
              </a>
            </div>
            <div className="logos">
              <a href="#">
                <img src={youtube_logo} alt="" />
              </a>
            </div>
          </div>
        </div>
      </div>
      </>
    )
}

export default Footer
