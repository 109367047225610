import React from 'react'
import './css/JobCard.css'

export default function JobCard() {
  return (
 <>
 <div className='job-card-head'>
    <div className='job-card1'>
        <h2>Test Series</h2>
    </div>
    <div className='job-card2'>
        <h2>Mock Test</h2>
    </div>
    <div className='job-card3'>
        <h2>Exam Pattern</h2>
    </div>
    <div className='job-card4'>
        <h2>Syllabus</h2>
    </div>
 </div>
 <div className='job-card5'>
        <h2>Live Exam</h2>
    </div>
 
 
 </>
  )
}
