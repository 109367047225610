import React from 'react'
import { Link } from 'react-router-dom'
import './css/Marquee.css'
import axios from 'axios';
import { useEffect, useState } from 'react';
function Marquee() {
  const [jobList, setjobList] = useState([]);
  useEffect(() => {
    //console.log("hello")
    axios.get(' https://naukridekho-backend-92571da7a098.herokuapp.com/govjob')
      .then(res => {
        //console.log(res.data.govjobData);
        setjobList(res.data.govjobData);
      })
      .catch(err => {
        console.log(err);
      })
  }, []);
  const [admitcardList, setadmitcardList] = useState([]);

  useEffect(() => {
    //console.log("hello")
    axios.get('https://naukridekho-backend-92571da7a098.herokuapp.com/admitcard')
      .then(res => {
        //console.log(res.data.admitcardData);
        setadmitcardList(res.data.admitcardData)
      })
      .catch(err => {
        console.log(err);
      })
  }, []);
  const [resultList, setresultList] = useState([]);

  useEffect(() => {
    //console.log("hello")
    axios.get(' https://naukridekho-backend-92571da7a098.herokuapp.com/result')
      .then(res => {
        //console.log(res.data.resultData);
        setresultList(res.data.resultData)
      })
      .catch(err => {
        console.log(err);
      })
  }, []);
  const [jobnewsList, setjobnewsList] = useState([]);

  useEffect(() => {
    //console.log("hello")
    axios.get(' https://naukridekho-backend-92571da7a098.herokuapp.com/jobnews',)
      .then(res => {
        //console.log(res.data.jobnewsData);
        setjobnewsList(res.data.jobnewsData)
      })
      .catch(err => {
        console.log(err);
      })
  }, []);

  const [updatesList, setupdatesList] = useState([]);

  useEffect(() => {
    //console.log("hello")
    axios.get(' https://naukridekho-backend-92571da7a098.herokuapp.com/updates',)
      .then(res => {
        //console.log(res.data.updatesData);
        setupdatesList(res.data.updatesData)
      })
      .catch(err => {
        console.log(err);
      })
  }, []);






  return (
    <div className='mar-body'>
      <div className='h3a'>
        <marquee  behavior="scroll" direction="right" scrollamount="5">

          <Link to="/Jobid655ec878e0e46b0f7c75d695">{jobList[28]?.name}<span className='p-date'> {jobList[28]?.date}</span></Link><span className='sp1'>new</span>||
          <Link to="/Pageid655edd10e0e46b0f7c75d751">{admitcardList[6]?.name} <span className='p-date'> {admitcardList[6]?.date}</span></Link>||
          <Link to="/Pageid655edd10e0e46b0f7c75d751">{admitcardList[20]?.name} <span className='p-date'> {admitcardList[20]?.date}</span></Link>
          <span className='sp1'>new</span></marquee>
      </div>
      <div className='h3a'>
        <marquee behavior="scroll" direction="left" scrollamount="5">
          <Link to="/Jobid655ec878e0e46b0f7c75d695">{jobList[29]?.name}<span className='p-date'> {jobList[29]?.date}</span></Link><span className='sp1'>new</span>||
          <Link to="/Jobid655ec878e0e46b0f7c75d695">{jobList[35]?.name}<span className='p-date'> {jobList[35]?.date}</span></Link><span className='sp1'>new</span>||
          <Link to="/Pageid655edd10e0e46b0f7c75d751">{admitcardList[18]?.name} <span className='p-date'> {admitcardList[18]?.date}</span></Link>||
          <Link to="/Pageid655edd10e0e46b0f7c75d751">{admitcardList[22]?.name} <span className='p-date'> {admitcardList[22]?.date}</span></Link>
          <span className='sp1'>new</span></marquee>
      </div>
      <div className='h3a'>
        <marquee behavior="scroll" direction="left" scrollamount="5">
          <a href="" className='marlink'>Datesheet for jac board 10 &12 examination</a><span className='sp1'>new</span>||
          <Link to="/Pageid655d392bf5a4b9f8210a6703">{resultList[5]?.name}<span className='p-date'>{resultList[5]?.date}</span></Link>
          <Link to="/Pageid655d392bf5a4b9f8210a6703">{resultList[30]?.name}<span className='p-date'>{resultList[30]?.date}</span></Link>
        </marquee>
      </div>
      <div className='h3a'>
        <marquee behavior="scroll" direction="right" scrollamount="5">
          <Link to="/Newsid65479d252d9a4ffaef2bf534">{jobnewsList[0]?.title}</Link><span className='sp1'>new</span>||
          <Link to="/Jobid654e4981738b62cb1272243c">{updatesList[4]?.name}</Link><span className='sp1'>new</span></marquee>
        <marquee behavior="scroll" direction="left" scrollamount="5">
          <Link to="/Pageid65479d252d9a4ffaef2bf534">{resultList[8]?.name}<span className='p-date'> {resultList[8]?.date}</span></Link><span className='sp1'>new</span>||
          <Link to="/Pageid65479d252d9a4ffaef2bf534">{resultList[9]?.name}<span className='p-date'> {resultList[9]?.date}</span></Link><span className='sp1'>new</span>
          <Link to="/Pageid654e4981738b62cb1272243c">{admitcardList[7]?.name}</Link><span className='sp1'>new</span></marquee>
      </div>
    </div>
  )
}

export default Marquee