import React from 'react'
import './Panjabpolice.css'
export default function Panjabpolice() {
  return (
    <div>
     <table> <th className='font'> <u>Punjab Police Constable Syllabus And Exam Pattern 2024</u></th></table>
<table><th>The selection process shall be a 3 (three) stage process consisting of the following: 
 <p></p>Stage-I: Stage-I will consist of two common Computer Based Test (CBT), multiple Choice Question (MCQ) type Paper viz. Paper-I and Paper-II, of which Paper-II shall be qualifying in nature.  Stage-II: Stage-II will consist of Physical Screening Test (PST) and Physical Measurement Test (PMT). Both Physical Screening Test and Physical Measurement Test shall be qualifying in nature. 
 <p></p>Stage-III: Stage-III will consist of Document Scrutiny. 
 STAGE–I: Computer Based Test The first stage of the selection process shall be Common Computer Based Test (CBT) comprising of the following: <p></p>
Paper - I Paper-I shall comprise of 100 questions carrying one (01) mark each. <br></br>
Paper - II Paper-II shall be a mandatory qualifying test of Punjabi language of matriculation standard comprising of 50 questions carrying one (01) mark each with 50% marks as the qualifying criteria. The marks obtained in this Paper shall not be counted for determining merit.
</th></table>

<table>
    <tr>  <th>    The syllabus and number of questions from various topics is as follows:-
</th>
</tr>
    <th><tr>
    <td></td>
    <td>	section	</td>
    <td>syllabus</td>
    <td>No. of question  </td>
  
    
  </tr>
  <tr>
    <td>Paper 1</td>
    <td>1. General Awareness <p></p>	English Language Skills <p></p>	Punjabi Language Skills </td>
    <td>	 	(i) Constitution and its features, Central and State Legislature, Executive, Judicial Institutions & Local Government Institutions 
        <br></br>  <br></br> (ii) History, Geography, Culture and Economy of Punjab(iii) Basics of Science & Technology
 (iv) Current Affair <br></br>
(vi) Direction and Distances <br></br>
(vii) Relationship Problems	35<br></br>	 
<td>(i) Reading Comprehension 10  <p></p>(ii) Punjabi to English Translation (iii) Sentence rearrangement and correction (iv) Error Spotting 
   <p></p> (v) Fill in the Blanks (vi) Spelling Correction (vii) Vocabulary (Synonym, Antonym, one word substitution) 
   <p></p>	ਸ਼ੁੱਧ/ਅਸ਼ੁੱਧ
ਸਮਾਨਾਰਥਕ/ਵਿਰੋਧੀਸ਼ਬਦ
ਪੰਜਾਬੀ ਅਖਾਣ ਅਤੇ ਮੁਹਾਵਰੇ
ਅੰਗਰੇਜੀ ਤੋਂ ਪੰਜਾਬੀ ਅਨੁਵਾਦ
ਬਹੁਤੇ ਸ਼ਬਦਾਂ ਦੀ ਥਾਂ ਤੇ ਇੱਕ ਸ਼ਬਦ I
ਅਣਡਿਠਾ ਪੈਰਾ	10
</td>


</td>
<td>35 <p></p>10  <p></p>10</td>

  </tr>

  </th>

</table>

<table>
    <th><tr>
    <td> </td>
    <td></td>
    <td> ਅਨੁਵਾਦ
ਬਹੁਤੇ ਸ਼ਬਦਾਂ ਦੀ ਥਾਂ ਤੇ ਇੱਕ ਸ਼ਬਦ I
ਅਣਡਿਠਾ ਪੈਰਾ
</td>
<td>......</td>

  </tr>
  <tr>
    <td> </td>
    <td>Digital Literacy & Awareness</td>
    <td>		 (i) Fundamentals of Computers 05 (ii) MS Office (Word, PowerPoint) (iii) Internet, Worldwide web and Web search engines. (iv) Email Communication (v) Mobile Phones(basic conceptual knowledge)	
</td>
<td>5 <p></p>20</td>

  </tr>
  <tr>
    <td>Duration 2 hours</td>
    <td>Quantitative Aptitude and Numerical Skills	 </td>
    <td>	(i) Simplification 
 (ii) Average 
(iii) Decimal and Fractions
 (iv) Ratio and Proportion
 (v) Percentages
 (vi) Profit and Loss 
(vii) Simple Interest
 (viii) Time and Work
 (ix) Bar graphs and </td> </tr></th>

 
</table>

	
 <table><th>
    <tr>
        <td>.......</td>
        <td>......</td>
        <td>Line graphs	 </td>
        <td></td>
    </tr>
    <tr>
        <td> 
Max marks 100</td>
        <td>Mental Ability & Logical Reasoning</td>
        <td>	Mental Ability & Logical Reasoning (i)	Number and Letter Series 
(ii)	Sequencing
(iii)	Statements and Conclusions
(iv)	 Pattern Completion
(v)	 Order and Ranking</td>
        <td> 20</td>
    </tr>
    </th></table>
	
    <table><th><tr>
        <td>Paper-II Duration: 1 hour Maximum Marks: 50</td>
        <td>1</td>
        <td>Mandatory Qualifying paper of Punjabi Language</td>
         <td>Mandatory Qualifying paper of Punjabi Language (equivalent to Matriculation standard)</td>
         <td>50</td></tr></th></table>
	

 










			
 <table> <th>Note1 :        Each candidate shall be issued an Admit Card indicating the specific date, time and venue for candidate’s Computer Based Test Papers. The candidate is required to download the Admit Card and bring a hard copy (printout) of the Admit card, along with any one prescribed Photo Identity Proof (Aadhaar Card, Indian Driving License, PAN card, Voter Identity card, Indian Passport) for entry to the venue of the Examination Centre.
 Note2 : No other Identity Proof shall be admissible.<p> </p> Negative Marking There shall be no negative marking. <p><mark>Minimum Marks</mark> </p>
 After normalization is carried out in the above-mentioned manner, candidates belonging to Scheduled Caste, Backward Class, Economically Weaker Section and Exservicemen categories shall be required to score a minimum of 35% marks and candidates belonging to other categories shall be required to score a minimum of 40% marks, in Paper-I, to be eligible to be considered for the next stage of the selection process. The candidates shall also be required to score a minimum of 50% percent marks in Paper-II after normalization is carried out to qualify for the next stage of the selection process. The failure to secure minimum 50% marks in Paper-II shall disqualify the candidate for being considered for the next stage of the selection process irrespective of his score in Paper-I. Note: The marks obtained by a candidate in Paper-II, which is a mandatory qualifying paper of Punjabi Language, shall not be considered in the overall score of the candidate for determining merit.
Answer Key and Seeking of Objections 
Upon completion of the last session of the Computer based tests, the Answer Key shall be uploaded on the Recruitment Portal. Candidates will be given 48 hours to raise objections to the Answer Key. A nominal fee of rupees 50/- shall be charged per objection. The fee shall be refunded, if the objection is sustained.
 <p></p> <mark>Alerts to Candidates</mark> <p></p>
 It shall be the responsibility of the candidates to keep themselves abreast of the Page 10 of 17 updates with respect to the process and stage of the selection process by frequently checking the Recruitment Portal for updates. No individual communication shall be made with the candidates. However, candidates shall be informed at various stages of the selection process, by SMS & E-mail. The alerts are just an additional facilitation provided to the candidates. The department shall not be responsible for non-receipt of an alert due to any reason whatsoever. The candidates are advised to disable ‘Do Not Disturb’ (DND) service on their mobile phones to enable timely receipt of SMS alerts.
<p> <u>STAGE-II PHYSICAL SCREENING TEST AND PHYSICAL MEASUREMENT TEST</u> </p>
Sufficient number of candidates, from amongst the candidates who have obtained required minimum marks in Paper-I and have also qualified Paper-II, would be shortlisted on the basis of merit determined by the marks obtained in Paper-I following normalization, and after factoring in reservation for various categories. The shortlisted candidates would be called for Stage-II. Stage-II of the selection process consisting of Physical Screening Test and Physical Measurement Test shall be conducted at designated place(s), date(s) and time.
  <p></p><u>1 Schedule for Stage-II Tests</u><p></p>
 The schedule for Physical Screening Test (PST) and Physical Measurement Test (PMT) shall be uploaded on the Recruitment Portal. A Separate Admit Card, indicating the date, time and venue, shall be issued for Stage-II tests. The candidate is required to download the Admit card, bring a hard copy (print out) of the same along with any one prescribed Photo Identity Proof (Aadhaar Card, Indian Driving License, PAN card, Voter Identity card, Indian Passport), for entry to the venue of the Stage-II tests. 
 <p></p>Note: No other Identity Proof shall be admissible. <p></p>

8.2 Physical Screening Test (PST) and Physical Measurement Test (PMT) Stage-II Examination will be conducted in the following order:- 
<p></p>
 Running (PST)<br></br>
  Long-Jump (PST) <br></br>
  High Jump (PST)  <br></br>
 Height Measurement (PMT)<br></br>


<table>
    <th className='font1'><p>The Physical Screening Test (PST), which shall be qualifying in nature, shall comprise of the following events:</p> 

        <tr>
            <td>Candidate	</td>
            <td>Physical Screening Test</td>
        </tr>
        <tr>
            <td>For Male Candidates (including Ex-servicemen less than 35 years of age as on 01.01.2024)	
</td>
            <td>(i)	1600 Meters Race to be completed in 6 minutes 30 seconds (only one chance).
(ii)	Long Jump 3.80 Meters (3 chances). 
<p></p>
(iii)	High Jump 1.10 Meters (3 chances).
</td>
        </tr>
        </th></table>
        <mark>THANK YOU </mark>
    <br></br><mark>Naukridekho.info</mark>
</th>
</table> 
           



    </div>
  )
}
