
import React,{useEffect,useState} from 'react'
import axios from 'axios';
import { Helmet } from 'react-helmet';
import './JobPage.css'
import Footer from '../../../Footer';
import Heading from '../../../Heading';
import NavbarComponent from '../../../NavbarComponent';

function Pageid6546296e04bae4cc8dbae151() {
 const[admitcardList,setadmitcardList]=useState([]);

  useEffect(()=>{
    //console.log("hello")
    axios.get('https://naukridekho-backend-92571da7a098.herokuapp.com/admitcard')
    .then(res=>{
      //console.log(res.data.admitcardData);
      setadmitcardList(res.data.admitcardData)
    })
    .catch(err=>{
      console.log(err);
    })
  },[]);
  //console.log(jobList);
  return (
   <>
   <Helmet>
    <title>{admitcardList[3]?.name}</title>
    <script async src="https://pagead2.googlesyndication.com/pagead/js/adsbygoogle.js?client=ca-pub-9504527545697458"
     crossorigin="anonymous"></script>
    <meta name='description'
    content={admitcardList[3]?.information} 
    />
    <meta name='keyword'
    content={admitcardList[3]?.details}
    />
    <meta name='date'content={admitcardList[3]?.important_date}
    />
   </Helmet>
   <Heading/>
   <NavbarComponent/>
   <main>
   <div className='cards'>
   <div className="single-card">
   <div>
        <span>Test Series</span>
          <h2>&nbsp;</h2>
          </div>
          
   </div>
   <div className="single-card">
                  <div>
                     <span>Syllabus</span>
                     <h2>&nbsp;</h2>
                  </div>
                  
               </div>
    <div className="single-card">
                  <div>
                     <span>Previous Year</span>
                     <h2>&nbsp;</h2>
                  </div>
                
                  </div>
    <div className="single-card">
                  <div>
                     <span>Videos</span>
                     <h2>&nbsp;</h2>
                  </div>
                     
                   </div>           

<div className="single-card" >
                  <div>
                     <span>Exam Pattern</span>
                     <h2>&nbsp;</h2>
                  </div>
                           
                   </div>           

</div>
</main>
{/*Job related details*/}
<div className='job-body'>
      <div className='div1'>
        <h2 className='job-h2'>Name of the post</h2>
         <h3>{admitcardList[3]?.name}</h3>
      </div>
      <div className='div1'>
        <h2 className='job-h2'>Date</h2>
          <h3>{admitcardList[3]?.date}</h3>
    </div>
    <div className='div1'>
        <h2 className='job-h2'>Information</h2>
          <h3>{admitcardList[3]?.information}</h3>
    </div>
    <div className='div1'>
        <h2 className ='job-h2'>Important Date </h2>
          <h3>{admitcardList[3]?.important_date}</h3>
    </div>
    <div className='div1'>
        <h2 className='job-h2'> Details</h2>
          <h3>{admitcardList[3]?.details}</h3>
    </div>
    <div className='div1'>
        <h2 className='job-h2'>Important links</h2>
          <h3>< a href={admitcardList[3]?.important_links}>Apply Online {admitcardList[3]?.important_links}</a></h3>
    </div>
 </div>    
  <Footer/> 



</>
  )}


export default Pageid6546296e04bae4cc8dbae151