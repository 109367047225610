import React from 'react';
import './Contactus.css'; // Include your CSS file

const ContactForm = () => {
    return (
        <section id="section-wrapper">
            <div className="box-wrapper">
                <div className="info-wrap">
                    <h2 className="info-title">Contact Information</h2>
                    <h3 className="info-sub-title">Fill up the form and our Team will get back to you within 24 hours</h3>
                    <ul className="info-details">
                        <li>
                            <i className="fas fa-phone-alt"></i>
                            <span>Phone:</span><a href="tel:+6203418568">+ 6203418568</a>
                        </li>
                        <li>
                            <i className="fas fa-envelope"></i>
                            <span>Email:</span><a href="mailto:naukridekho37@gamil.com">naukridekho37@gamil.com</a>
                        </li>
                        <li>
                            <i className="fas fa-globe"></i>
                            <span>Website:</span><ally href="#">bzeetech.com</ally>
                        </li>
                    </ul>
                    <ul className="social-icons">
                    <li><ally href="#" target="_blank" rel="noopener noreferrer"><i className="fab fa-facebook"></i></ally></li>
                    <li><ally href="#" target="_blank" rel="noopener noreferrer"><i className="fab fa-twitter"></i></ally></li>
                    <li><ally href="#" target="_blank" rel="noopener noreferrer"><i className="fab fa-linkedin-in"></i></ally></li>
                        
                    </ul>
                </div>
                <div className="form-wrap">
                    <form action="#" method="POST">
                        <h2 className="form-title">Send us a message</h2>
                        <div className="form-fields">
                            <div className="form-group">
                                <input type="text" className="fname" placeholder="First Name" />
                            </div>
                            <div className="form-group">
                                <input type="text" className="lname" placeholder="Last Name" />
                            </div>
                            <div className="form-group">
                                <input type="email" className="email" placeholder="Mail" />
                            </div>
                            <div className="form-group">
                                <input type="number" className="phone" placeholder="Phone" />
                            </div>
                            <div className="form-group">
                                <textarea name="message" placeholder="Write your message" />
                            </div>
                        </div>
                        <input type="submit" value="Send Message" className="submit-button" />
                    </form>
                </div>
            </div>
        </section>
    );
};

export default ContactForm;
