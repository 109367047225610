import React, { useState } from "react";
import axios from "axios";
import './AddLatestjob.css'
import Footer from '../Footer'
import Heading from "../Heading";
import NavbarComponent from "../NavbarComponent";

const Addjobnews = () => {
  // State to store the form data
  const [formData, setFormData] = useState({
    title: "",
    date: "",
    information:"",
    important_date:"",
    details:"",
    important_links:""
  });

  // Function to handle form input changes
  const handleChange = (e) => {
    setFormData({ ...formData, [e.target.name]: e.target.value });
  };

  // Function to submit the form data using Axios
  const handleSubmit = async (e) => {
    e.preventDefault();
    try {
      const response = await axios.post("https://naukridekho-backend-92571da7a098.herokuapp.com/jobnews", formData);
      //console.log("Post created:", response.data);
      window.alert(" Added Successfully")
    } catch (error) {
      console.error("Error creating post:", error);
    }
  };

  return (
    <>
    <Heading/>
    <NavbarComponent/>
      <div className="form-data">
        <form onSubmit={handleSubmit}>
          <div className="input">
            <div>
            <label>Title</label>
            <input className="input1" type="text" name="title" value={formData.title} onChange={handleChange} />
          </div>
          <div>
            <label>Date</label>
            <input className="input1" type="text" name="date" value={formData.date} onChange={handleChange} />
          </div>
          <div>
            <label>Information</label>
            <textarea className="input1" type="text" name="information" value={formData.information} onChange={handleChange} />
          </div>
          <div>
            <label> Important Date</label>
            <textarea className="input1" type="text" name="important_date" value={formData.important_date} onChange={handleChange} />
          </div>
          <div>
            <label> Details</label>
            <textarea className="input1" type="text" name="details" value={formData.details} onChange={handleChange} />
          </div>
          <div>
            <label> para1</label>
            <textarea className="input1" type="text" name="para1" value={formData.para1} onChange={handleChange} />
          </div>
          <div>
            <label> para2</label>
            <textarea className="input1" type="text" name="para2" value={formData.para2} onChange={handleChange} />
          </div>
          <div>
            <label>Important Links</label>
            <textarea placeholder="Link"className="input1" type="text" name="important_links" value={formData.important_links} onChange={handleChange} />
          </div>
          <button className="add-button"type="submit">Add important</button>
          </div>
        </form>
      </div>
      <Footer/>
    </>
  );
};

export default Addjobnews;