import React, { useState } from "react";
import axios from "axios";
import './AddLatestjob.css'
import Footer from '../Footer'
import Heading from "../Heading";
import NavbarComponent from "../NavbarComponent";

const AddLatestjob = () => {
  // State to store the form data
  const [formData, setFormData] = useState({
    name: "",
    date: "",
    information:"",
    important_date:"",
    application_fee:"",
    eligibility:"",
    agelimit:"",
    vacancy_details:"",
    important_links:""
  });

  // Function to handle form input changes
  const handleChange = (e) => {
    setFormData({ ...formData, [e.target.name]: e.target.value });
  };

  // Function to submit the form data using Axios
  const handleSubmit = async (e) => {
    e.preventDefault();
    try {
      const response = await axios.post("https://naukridekho-backend-92571da7a098.herokuapp.com/govjob", formData);
      //console.log("Post created:", response.data);
      window.alert("New Job Added Successfully")
    } catch (error) {
      console.error("Error creating post:", error);
    }
  };

  return (
    <>
    <Heading/>
    <NavbarComponent/>
      <div className="form-data">
        <form onSubmit={handleSubmit}>
          <div className="input">
            <div>
            <label>Name of the Post</label>
            <input className="input1" type="text" name="name" value={formData.name} onChange={handleChange} />
          </div>
          <div>
            <label>Date</label>
            <input className="input1" type="text" name="date" value={formData.date} onChange={handleChange} />
          </div>
          <div>
            <label>Information</label>
            <textarea className="input1" type="text" name="information" value={formData.information} onChange={handleChange} />
          </div>
          <div>
            <label> Important Date</label>
            <textarea className="input1" type="text" name="important_date" value={formData.important_date} onChange={handleChange} />
          </div>
          <div>
            <label>Application Fee</label>
            <textarea className="input1" type="text" name="application_fee" value={formData.application_fee} onChange={handleChange} />
          </div>
          <div>
            <label>Eligibility</label>
            <textarea className="input1" type="text" name="eligibility" value={formData.eligibility} onChange={handleChange} />
          </div>
          <div>
            <label>Age Limit</label>
            <textarea className="input1" type="text" name="agelimit" value={formData.agelimit} onChange={handleChange} />
          </div>
          <div>
            <label>Vacancy Details</label>
            <textarea className="input1" type="text" name="vacancy_details" value={formData.vacancy_details} onChange={handleChange} />
          </div>
          <div>
            <label>Important Links</label>
            <textarea className="input1" type="text" name="important_links" value={formData.important_links} onChange={handleChange} />
          </div>
          <button className="add-button"type="submit">Add Latestjob</button>
          <button className="add-button1">Clear</button>
          </div>
        </form>
      </div>
      <Footer/>
    </>
  );
};

export default AddLatestjob;