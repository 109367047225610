
import React, { useEffect, useState } from 'react'
import axios from 'axios';
import { Helmet } from 'react-helmet';
import '../govermentjob/JobPage.css'
import Footer from '../../../Footer';
import Heading from '../../../Heading';
import NavbarComponent from '../../../NavbarComponent';

function Pageid656169f1eabef241d18f9544() {
    const [resultList, setresultList] = useState([]);

    useEffect(() => {
        //console.log("hello")
        axios.get('https://naukridekho-backend-92571da7a098.herokuapp.com/result')
            .then(res => {
                //console.log(res.data.resultData);
                setresultList(res.data.resultData)
            })
            .catch(err => {
                console.log(err);
            })
    }, []);
    //console.log(jobList);
    return (
        <>
        <Helmet>
    <title>{resultList[7]?.name}</title>
    <script async src="https://pagead2.googlesyndication.com/pagead/js/adsbygoogle.js?client=ca-pub-9504527545697458"
     crossorigin="anonymous"></script>
    <meta name='description'
    content={resultList[7]?.information} 
    />
    <meta name='keyword'
    content={resultList[7]?.details}
    />
    <meta name='date'content={resultList[7]?.important_date}
    />
   </Helmet>
            <Heading />
            <NavbarComponent />
            <main>
                <div className='cards'>
                    <div className="single-card">
                        <div>
                            <span>Test Series</span>
                            <h2>&nbsp;</h2>
                        </div>

                    </div>
                    <div className="single-card">
                        <div>
                            <span>Syllabus</span>
                            <h2>&nbsp;</h2>
                        </div>

                    </div>
                    <div className="single-card">
                        <div>
                            <span>Previous Year</span>
                            <h2>&nbsp;</h2>
                        </div>

                    </div>
                    <div className="single-card">
                        <div>
                            <span>Videos</span>
                            <h2>&nbsp;</h2>
                        </div>

                    </div>

                    <div className="single-card" >
                        <div>
                            <span>Exam Pattern</span>
                            <h2>&nbsp;</h2>
                        </div>

                    </div>

                </div>
            </main>
            {/*Job related details*/}
            <div className='job-body'>
                <div className='div1'>
                    <h2 className='job-h2'>Name of the post</h2>
                    <h3>{resultList[7]?.name}</h3>
                </div>
                <div className='div1'>
                    <h2 className='job-h2'>Date</h2>
                    <h3>{resultList[7]?.date}</h3>
                </div>
                <div className='div1'>
                    <h2 className='job-h2'>Information</h2>
                    <h3>{resultList[7]?.information}</h3>
                </div>
                <div className='div1'>
                    <h2 className='job-h2'>Candidates qualified for Skill Test in Stenography for Stenographer Grade ‘C’ </h2>
                    <table style={{ marginLeft: '25%' }}>
                        <tr>
                            <td>
                                <h3>Category</h3>
                                <h3> SC</h3>
                                <h3>ST</h3>
                                <h3>OBC</h3>
                                <h3>EWS</h3>
                                <h3>UR</h3>
                                <h3>OH </h3>
                                <h3>VH</h3>
                            </td>
                            <td>
                                <h3>Cut-off Marks</h3>
                                <h3>136.08122</h3>
                                <h3>128.04746</h3>
                                <h3>145.03097  </h3>
                                <h3>147.45086@ </h3>
                                <h3>147.45086  </h3>
                                <h3>103.89802  </h3>
                                <h3>115.92474 </h3>

                            </td>
                            <td>
                                <h3>Candidates available</h3>
                                <h3>650 </h3>
                                <h3>174</h3>
                                <h3>1156</h3>
                                <h3>428</h3>
                                <h3>975*</h3>
                                <h3>156</h3>
                                <h3>57 </h3>
                            </td>

                        </tr>
                    </table>

                </div>
                <div className='div1'>
                    <h2 className='job-h2'>Candidates qualified for Skill Test in Stenography for Stenographer Grade ‘D’: </h2>
                    <table style={{ marginLeft: '25%' }}>
                        <tr>
                            <td>
                                <h3>Category</h3>
                                <h3> SC</h3>
                                <h3>ST</h3>
                                <h3>OBC</h3>
                                <h3>EWS</h3>
                                <h3>UR</h3>
                                <h3>ESM </h3>
                                <h3>OH </h3>
                                <h3>HH</h3>
                                <h3>VH</h3>
                                <h3>Others- PWD</h3>
                            </td>
                            <td>
                                <h3>Cut off Marks</h3>
                                <h3>109.41004</h3>
                                <h3>88.71217</h3>
                                <h3>125.02167 </h3>
                                <h3>126.29805</h3>
                                <h3>131.32184 </h3>
                                <h3>40.04736  </h3>
                                <h3>85.85777  </h3>
                                <h3>40.15035</h3>
                                <h3>40.04736 </h3>
                                <h3>40.15035</h3>
                            </td>
                            <td>
                                <h3>Candidates available</h3>
                                <h3>3807 </h3>
                                <h3>1887</h3>
                                <h3>5939 </h3>
                                <h3>2299 </h3>
                                <h3>3130</h3>
                                <h3>220</h3>
                                <h3>385 </h3>
                                <h3>139 </h3>
                                <h3>220</h3>
                                <h3>109 </h3>
                            </td>
                        </tr>
                    </table>
                </div>
                <div className='div1'>
                    <h2 className='job-h2'> Details</h2>
                    <h3>{resultList[7]?.details}</h3>
                </div>
                <div className='div1'>
                    <h2 className='job-h2'>Result Link</h2>
                    <h3>< a href={resultList[7]?.result_link}>Result Link {resultList[7]?.result_link}</a></h3>
                </div>

                <div className='div1'>
                    <h2 className='job-h2'>Important links</h2>
                    <h3>< a href={resultList[7]?.important_links}>Official site {resultList[7]?.important_links}</a></h3>
                </div>
            </div>
            <Footer />



        </>
    )
}


export default Pageid656169f1eabef241d18f9544