
import React,{useEffect,useState} from 'react'
import { Link } from 'react-router-dom';
import axios from 'axios';
import './images/test.jpg'
import './JobPage.css'
import './jobhead.css'
import Footer from '../../../Footer';
import Heading from '../../../Heading';
import NavbarComponent from '../../../NavbarComponent';
import { Helmet } from 'react-helmet';
function Jobid655ec878e0e46b0f7c75d695() {
 const[jobList,setjobList]=useState([]);
//const [users,setUser]=useState([])
  //useEffect(()=>{
   // fetch("/data").then((result)=>{
      //result.json().then((resp)=>{
        //console.warn(resp)
       // setUser(resp)
      //})
    //})
 // },[])
  //console.warn(users)

  useEffect(()=>{
    //console.log("hello")
    axios.get('https://naukridekho-backend-92571da7a098.herokuapp.com/govjob')
    .then(res=>{
      //console.log(res.data.govjobData);
      setjobList(res.data.govjobData);
    })
    .catch(err=>{
      console.log(err);
    })
  },[]);
  //console.log(jobList);
  return (
   <>
   <Helmet>
    <title>{jobList[9]?.name}</title>
    <script async src="https://pagead2.googlesyndication.com/pagead/js/adsbygoogle.js?client=ca-pub-9504527545697458"
     crossorigin="anonymous"></script>
    <meta name='description'
    content={jobList[9]?.information} 
    />
    <meta name='keyword'
    content={jobList[9]?.details}
    />
    <meta name='date'content={jobList[9]?.important_date}
    />
   </Helmet>
   <Heading/>
   <NavbarComponent/>
   <main>
   <div className='cards'>
   <div className="single-card">
   <div>
        <span>Test Series</span>
          <h2>&nbsp;</h2>
          </div>
          
   </div>
   <div className="single-card">
                  <div>
                     <span>Syllabus</span>
                     <h2>&nbsp;</h2>
                  </div>
                  
               </div>
    <div className="single-card">
                  <div>
                     <span>Previous Year</span>
                     <h2>&nbsp;</h2>
                  </div>
                
                  </div>
    <div className="single-card">
                  <div>
                     <span>Videos</span>
                     <h2>&nbsp;</h2>
                  </div>
                     
                   </div>           

<div className="single-card" >
                  <div>
                    <Link to ="/ExamPatternsbi">
                     <span>Exam Pattern</span>
                     <h2>&nbsp;</h2>
                     </Link>
                  </div>
                           
                   </div>           

</div>
</main>
{/*Job related details*/}
<div className='job-body'>
      <div className='div1'>
        <h2 className='job-h2'>Name of the post</h2>
         <h3>{jobList[9]?.name}</h3>
      </div>
      <div className='div1'>
        <h2 className='job-h2'>Date</h2>
          <h3>{jobList[9]?.date}</h3>
    </div>
    <div className='div1'>
        <h2 className='job-h2'>Information</h2>
          <h3>{jobList[9]?.information}</h3>
    </div>
    <div className='div1'>
        <h2 className ='job-h2'>Important Date </h2>
          <h3>{jobList[9]?.important_date}</h3>
    </div>
    <div className='div1'>
        <h2 className='job-h2'>Application Fee</h2>
      
          <h3>{jobList[9]?.application_fee}</h3>
    </div>
    <div className='div1'>
        <h2 className='job-h2'>Eligibility</h2>
          <h3>{jobList[9]?.eligibility}</h3>
    </div>
    <div className='div1'>
        <h2 className='job-h2'>Age Limit</h2>
          <h3>{jobList[9]?.agelimit}</h3>
    </div>
    <div className='div1'>
        <h2 className='job-h2'>Vacancy Details</h2>
        <table style={{marginLeft:'40%'}}>
          <tr>
            <td>
        <h3> For Male</h3>
          <h3>BSF-5211</h3>
          <h3>CISF- 4151</h3>
          <h3>CRPF-1314</h3>
            <h3>SSB-593</h3>
            <h3>ITBP - 2694 </h3>
            <h3>AR-1448 </h3>
            <h3>SSF-222</h3></td>
            <td>
            <h3>For Female</h3>
            <h3>BSF-963</h3>
          <h3>CISF-  1112</h3>
          <h3>CRPF-71</h3>
            <h3>SSB-42</h3>
            <h3>ITBP - 495 </h3>
            <h3>AR-1490 </h3>
            <h3>SSF-296</h3>
            </td>
            </tr>
         </table> 
    </div>
    <div className='div1'>
        <h2 className='job-h2'>Important links</h2>
          <h3>Apply online :-< a href="https://ssc.nic.in/">https://ssc.nic.in/</a></h3>
    </div>
 </div>    
    

<Footer/>

</>
  )}


export default Jobid655ec878e0e46b0f7c75d695