
import React,{useEffect,useState} from 'react'
import axios from 'axios';
import loader from '../../../assets/loader.gif'
import '../govermentjob/JobPage.css'
import '../govermentjob/jobhead.css'
import Heading from '../../../Heading';
import Footer from '../../../Footer';
import NavbarComponent from '../../../NavbarComponent';
import { Helmet } from 'react-helmet';
function Jobid6568b8f32829fd098633ed4d() {
 const[updatesList,setupdatesList]=useState([]);
//const [users,setUser]=useState([])
  //useEffect(()=>{
   // fetch("/data").then((result)=>{
      //result.json().then((resp)=>{
        //console.warn(resp)
       // setUser(resp)
      //})
    //})
 // },[])
  //console.warn(users)

  useEffect(()=>{
    //console.log("hello")
    axios.get('https://naukridekho-backend-92571da7a098.herokuapp.com/updates')
    .then(res=>{
      //console.log(res.data.updatesData);
      setupdatesList(res.data.updatesData);
      setloading(false);

    })
    .catch(err=>{
      console.log(err);
      setloading(false);
    })
  },[]);
  //console.log(jobList);
  const[isloading,setloading]=useState(true);
  return (
   <>
   <Helmet>
   <script async src="https://pagead2.googlesyndication.com/pagead/js/adsbygoogle.js?client=ca-pub-9504527545697458"
     crossorigin="anonymous"></script>
   </Helmet>
   <Heading/>
   <NavbarComponent/>
   <main>
   <div className='cards'>
   <div className="single-card">
   <div>
        <span>Test Series</span>
          <h2>&nbsp;</h2>
          </div>
          
   </div>
   <div className="single-card">
                  <div>
                     <span>Syllabus</span>
                     <h2>&nbsp;</h2>
                  </div>
                  
               </div>
    <div className="single-card">
                  <div>
                     <span>Previous Year</span>
                     <h2>&nbsp;</h2>
                  </div>
                
                  </div>
    <div className="single-card">
                  <div>
                     <span>Videos</span>
                     <h2>&nbsp;</h2>
                  </div>
                     
                   </div>           

<div className="single-card" >
                  <div>
                     <span>Exam Pattern</span>
                     <h2>&nbsp;</h2>
                  </div>
                           
                   </div>           

</div>
</main>
{/*Job related details*/}
{isloading &&
<img style={{width:'300px', marginLeft:'38%'}} src={loader} />
}
{!isloading &&
<div className='job-body'>
      <div className='div1'>
        <h2 className='job-h2'>Name of the post</h2>
         <h3>{updatesList[5]?.name}</h3>
      </div>
      <div className='div1'>
        <h2 className='job-h2'>Date</h2>
          <h3>{updatesList[5]?.date}</h3>
    </div>
    <div className='div1'>
        <h2 className='job-h2'>Information</h2>
          <h3>{updatesList[5]?.information}</h3>
    </div>
    <div className='div1'>
        <h2 className ='job-h2'>Important Date </h2>
          <h3>{updatesList[5]?.important_date}</h3>
    </div>
    <div className='div1'>
        <h2 className='job-h2'>Application Fee</h2>
      
          <h3>{updatesList[5]?.application_fee}</h3>
    </div>
    <div className='div1'>
        <h2 className='job-h2'>Eligibility</h2>
          <h3>{updatesList[5]?.eligibility}</h3>
    </div>
    <div className='div1'>
        <h2 className='job-h2'>Age Limit</h2>
          <h3>{updatesList[5]?.agelimit}</h3>
    </div>
    <div className='div1'>
        <h2 className='job-h2'>Vacancy Details</h2>
          <h3>{updatesList[5]?.vacancy_details}</h3>
    </div>
    <div className='div1'>
        <h2 className='job-h2'>Important links</h2>
          <h3>< a href={updatesList[5]?.important_links1}>Apply Online {updatesList[5]?.important_links1}</a></h3>
    </div>
 </div>    
    
}
<Footer/>

</>
  )}


export default Jobid6568b8f32829fd098633ed4d