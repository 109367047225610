
import React,{useEffect,useState} from 'react'
import axios from 'axios';
import loader from '../../../assets/loader.gif'
import './JobPage.css'
import Footer from '../../../Footer';
import Heading from '../../../Heading';
import NavbarComponent from '../../../NavbarComponent';
import { Helmet } from 'react-helmet';
//import Poster from'./Poster/jpsc-pre-online-form-2024.jpg'

function Pageid65bbc8b36633c8d4740fa98a() {
 const[admitcardList,setadmitcardList]=useState([]);

  useEffect(()=>{
    //console.log("hello")
    axios.get('https://naukridekho-backend-92571da7a098.herokuapp.com/admitcard')
    .then(res=>{
      //console.log(res.data.admitcardData);
      setadmitcardList(res.data.admitcardData)
      setloading(false);
    })
    .catch(err=>{
      console.log(err);
      setloading(false);
    })
  },[]);
  //console.log(jobList);
  const[isloading,setloading]=useState(true);
  return (
   <>
   <Helmet>
    <title>{admitcardList[71]?.name}</title>
    <script async src="https://pagead2.googlesyndication.com/pagead/js/adsbygoogle.js?client=ca-pub-9504527545697458"
     crossorigin="anonymous"></script>
    <meta name='description'
    content={admitcardList[71]?.information} 
    />
    <meta name='keyword'
    content={admitcardList[71]?.details}
    />
    <meta name='date'content={admitcardList[71]?.important_date}
    />
     <link rel="canonical" href='/NIFT-2024-Admission-Exam-City-Admit-Card'/>
   </Helmet>
   <Heading/>
    <NavbarComponent/>
   
{/*Job related details*/}
{isloading &&
<img style={{width:'300px', marginLeft:'38%'}} src={loader} />
}
{!isloading &&
<div className='job-body'>
{/*<img style={{marginLeft:"" ,width:"100%"} } src={Poster}  alt='jpsc-pre-online-form-2024'/>*/}
      <div className='div1'>
        <h2 className='job-h2'>Name of the post</h2>
         <h3>{admitcardList[71]?.name}</h3>
      </div>
      <div className='div1'>
        <h2 className='job-h2'>Date</h2>
          <h3>{admitcardList[71]?.date}</h3>
    </div>
    <div className='div1'>
        <h2 className='job-h2'>Information</h2>
          <h3>{admitcardList[71]?.information}</h3>
    </div>
    <div className='div1'>
        <h2 className ='job-h2'>Important Date </h2>
          <h3>{admitcardList[71]?.important_date}</h3>
    </div>
    <div className='div1'>
        <h2 className='job-h2'> Details</h2>
          <h3>{admitcardList[71]?.details}</h3>
    </div>
    <div className='div1'>
        <h2 className='job-h2'>Important links</h2>
          <h3>Official site< a href={admitcardList[71]?.important_links1}> Click Here</a></h3>
    </div>
    <div className='div1'>
        <h2 className='job-h2'>Important links</h2>
          <h3>Admit card :-< a href={admitcardList[71]?.important_links2}> Click Here</a></h3>
    </div>
    <div className='div1'>
        <h2 className='job-h2'>Syllabus</h2>
          <h3>< a href=""> Uploading Soon...</a></h3>
    </div>
    <div className='div1'>
        <h2 className='job-h2'>Exam Pattern</h2>
          <h3>< a href=""> Uploading Soon...</a></h3>
    </div>
    <div className='div1'>
        <h2 className='job-h2'>Test Series</h2>
          <h3>< a href=""> Uploading Soon...</a></h3>
    </div>
    <div className='div1'>
        <h2 className='job-h2'>Previous Year</h2>
          <h3>< a href=""> Uploading Soon...</a></h3>
    </div>
    
 </div>  
}  
  <Footer/> 



</>
  )}


export default Pageid65bbc8b36633c8d4740fa98a