import React from 'react'
import './ExamPattern.css'
import Footer from '../../../Footer'
import Heading from '../../../Heading'
import NavbarComponent from '../../../NavbarComponent'
import { Helmet } from 'react-helmet'
export default function Exam() {
  return (
<>
<Helmet>
   <script async src="https://pagead2.googlesyndication.com/pagead/js/adsbygoogle.js?client=ca-pub-9504527545697458"
     crossorigin="anonymous"></script>
   </Helmet>
<Heading/>
<NavbarComponent/>
    <div className="exam-div" style={{paddingTop:"10%",fontSize:"30px"}}>
      <tables1> <th>RECRUITMENT OF CIRCLE BASED OFFICERS of SBI ADVERTISEMENT NO: CRPD/ CBO/ 2023-24/18 
Exam pattern for the post is give below.
<br></br>
SELECTION PROCEDURE: The selection process consists of Online Test, Screening and Interview.</th>
</tables1> 

 <table1>
      <thead>
        <tr>
          <th> (A). <u>Online Test: </u>Online test will consist of Objective Tests for 120 marks and Descriptive Test for 50 marks. 
The Descriptive Test will be administered immediately after conclusion of the Objective Test and candidates will have to type their Descriptive Test answers on the computer.<p></p> (i)	Objective Test: The duration of objective test is 2 hours and it consists of 4 Sections of total 120 marks.
 <br></br>(ii)	 There will be separate timing for every section.</th>    
       
        </tr>
      </thead>
       </table1>
       <br></br>

     <table></table>
              <th> <u>Test   Name of the Test</u> <p>I. English Language </p>  <p>II.  Banking Knowledge  </p>   
              <p> III. General Awareness/ Economy</p>  <p>IV.Computer Aptitude </p>  <p>Total=</p></th> 
        <th> <u>No.of questions.</u> <p> 30</p> <p>40</p> <p>30</p> <p>20</p> <p>120</p> </th>             
        <th> <u>Max.</u>  <p>30</p> <p>40</p> <p>30</p> <p>20 </p> <p>120</p></th>        
        <th> <u>Marks Duration</u> <p>30 minutes</p> <p>40 minites</p> <p>30 minites</p> <p>20 minutes</p> <p>2 hours</p></th>        
     
     <table2>
      <th> <u>(iii)	Descriptive Test:</u> The duration of Descriptive Test is 30 minutes. It will be a Test of English Language (Letter Writing & Essay) with two questions for total 50 marks.
There will be no sectional qualifying marks.
 There is minimum qualifying marks on aggregate and the minimum qualifying marks will be as decided by the Bank. Penalty for Wrong Answers:
 There will be no penalty for wrong answers marked in Objective Tests. 
Sectional Marks will not be maintained for Online Examination.
</th>
</table2>

 <table3> <th><u> (B)Screening:</u> Online application and documents of candidates, who qualified in online examination, will be placed before the Screening Committee. The Screening Committee constituted by the Bank will examine whether the candidates fulfil the required eligibility criteria of experience. The Committee will match the job profile submitted by the candidates (certified by their existing/ previous employer(s) with whom they are/were employed) with the job profile of Scale-I Generalist Officer of the State Bank of India.
<p>In case their job profile does not match substantially or is different than the job profile of Scale-I Generalist Officer of State Bank of India, then the application of the candidate will be summarily rejected. Bank may decide the parameters to be adopted as it found suitable for matching the Job Profile of the candidates with job profile of Scale-I Generalist Officer of the State Bank of India. The decision of the Bank in this regard would be final and binding on all candidates. No representation or correspondence will be entertained by the Bank in this regard. Merit list will be drawn Circle wise and Category wise on the basis of the aggregate marks scored in Online Test for candidates who fulfil the required experience criteria as decided by the Screening Committee. 
</p>
 <p> Candidates numbering up to 3 times (approx.) of the Circle wise and Category wise vacancies, subject to availability of qualified candidates, will be called for interview from the top of the merit list drawn on Circle wise and Category wise vacancies. Mere qualifying in online exam will not vest any right in candidate being called for interview.
</p>
<p>correspondence will be entertained by the Bank in this regard. Merit list will be drawn Circle wise and Category wise on the basis of the aggregate marks scored in Online Test for candidates who fulfil the required experience criteria as decided by the Screening Committee. Candidates numbering up to 3 times (approx.) of the Circle wise and Category wise vacancies, subject to availability of qualified candidates, will be called for interview from the top of the merit list drawn on Circle wise and Category wise vacancies. Mere qualifying in online exam will not vest any right in candidate being called for interview.
</p>
</th>
</table3> 

 <tables> <th> <u>(C). Interview:</u> Interview will consist of 50 marks. Candidates will have to score minimum qualifying marks in interview to be considered for final selection. The minimum qualifying marks will be decided by the Bank. Candidates qualifying for interview under 'OBC' category would be required to submit OBC certificate containing the 'Non-Creamy layer' clause. Candidates who have registered themselves as OBC but do not submit OBC 'Non-Creamy layer' certificate and request for interview under General category will not be entertained. Candidates qualifying for interview under EWS category would be required to submit EWS certificate issued based on gross annual income for the financial year 2022-23 as per DoPT guidelines. Candidates who have registered themselves as EWS but do not submit EWS certificate issued based on gross annual income for the financial year 2022-23 as per DoPT guidelines and request for interview under General category will not be entertained.
  <br></br>
   <u>Final Selection:</u> The candidates will have to qualify both in Online Test and Interview separately. The marks obtained in Online Test, both in the Objective Test and the Descriptive Test, will be added to the marks obtained in Interview for preparing the final merit list. Final merit list would be prepared based on normalization of marks scored in Online Test and Interview with 75:25 weightage respectively. Marks secured by the candidates in the Online Test (out of 170 marks) are converted to out of 75 marks and Interview scores of candidates (out of 50 marks) are converted to out of 25 marks. The final merit list (Circle wise and category wise) is arrived at after aggregating (out of 100) converted marks of Online Test and Interview. Selection will be made from the top ranked candidates in merit list prepared on Circle wise and Category wise.
   <p><u>Result Publication:</u></p>
   
 Results of candidates called for Interview, Final Result etc. will be made available on the Bank's website. Final selection will be subject to Qualifying in test of specified opted local language, where applicable, as detailed above.
</th> 
<tr> <b>THANK YOU FOR VISITING OUR SITE.NAUKRIDEKHO.INFO</b></tr>
</tables>
</div>
<Footer/>
</>
  )
}
