import React from 'react'
import './css/Register.css'
export default function Register() {
  return (
    <div className='bdy'>
    <div class="container">
    <div class="title">Registration</div>
    <form action="#">
        <div className="user-details">
            <div className="input-box">
                <span class="details">Full Name</span>
                <input type="text" placeholder="Enter Your Name" required></input>
            </div>
            <div class="input-box">
                <span class="details">UserName</span>
                <input type="text" placeholder="Enter Your UserName" required></input>
            </div>
            <div class="input-box">
                <span class="details">Email</span>
                <input type="email" placeholder="Enter Your Email" required></input>
            </div>
            <div class="input-box">
                <span class="details">Phone Number</span>
                <input type="number" placeholder="Enter Your Number" required></input>
            </div>
            <div class="input-box">
                <span class="details">Password</span>
                <input type="password" placeholder="Enter Your Password" required></input>
            </div>
            <div class="input-box">
                <span class="details">Confirm password</span>
                <input type="password" placeholder="Confirm Your Password" required></input>
            </div>
            <div class="gender-details">
    <input type="radio" name="gender" id="dot-1"></input>
    <input type="radio" name="gender" id="dot-2"></input>
                <span class="gender-title">Gender</span>
                <div class="category">
                    <label for="dot-1">
                        <span class="dot one"></span>
                        <span class="gender">Male</span>
                    </label>
                    <label for="dot-2">
                        <span class="dot two"></span>
                        <span class="gender">Female</span>
                    </label>
                </div>
            </div>
            <div class="button">
                <input type="submit" value="Register"></input>
            </div>

        </div>
    </form>
</div>
</div>
  )
}
