import React from 'react'
import { Link } from 'react-router-dom';
import './navbar.css';
<link rel="stylesheet" href="https://cdn.jsdelivr.net/npm/bootstrap@4.0.0/dist/css/bootstrap.min.css" integrity="sha384-Gn5384xqQ1aoWXA+058RXPxPg6fy4IWvTNh0E263XmFcJlSAwiGgFAW/dAiS6JXm" crossorigin="anonymous"></link>
export default function NavbarComponent() {
  return (
    <div className='nav-body'>
      <ul className="nav justify-content-center">
    <li className="nav-item">
      <a className="nav-link active" ><Link className='linklink' to="/">Home</Link></a>
    </li>
    <li className="nav-item">
      <a className="nav-link"><Link className="linklink" to="/Latestjob">Latest Job</Link></a>
    </li>
    
    <li className="nav-item">
      <a className="nav-link"><Link className="linklink" to="">Private Job</Link></a>
    </li>
    <li className="nav-item">
    <a className="nav-link"><Link className="linklink" to="/Results">Results</Link></a>
    </li>
    <li className="nav-item">
    <a className="nav-link"><Link className="linklink" to=""> Job News</Link></a>
    </li>
    <li className="nav-item">
    <a className="nav-link"><Link className="linklink" to="/Admitcard">Admit Card</Link></a>
    </li>
    <li className="nav-item">
    <a className="nav-link"><Link className="linklink" to="/">Syllabus</Link></a>
    </li>
  </ul>
   </div>
  
  )
}
