import React from 'react'
import '../css/LoginPage.css'
import { Link } from 'react-router-dom';
import Footer from '../Footer';
import Heading from '../Heading';
import NavbarComponent from '../NavbarComponent';

export default function LoginPage() {
  return (
    <>
    <Heading/>
    <NavbarComponent/>
    <div className='bdy'>
       <div className="center">
        <h1> Login</h1>
        
        <form method='post'>
          <div className='txt_field' required>
          <input type="password" required></input>
            <span></span>
            <label>USERID</label>
            </div>
            <div className='txt_field' required>
            <input type="password" required></input>
            <span></span>
            <label>PASSWORD</label>
            </div>
        <Link to="/Dashboard" ></Link>   
            
    <input type="submit" value="LOGIN"></input>
        </form>
        </div>
        
     </div>
     <Footer/>
     </>   
  )
}
