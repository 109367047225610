
import React,{useEffect,useState} from 'react'
import axios from 'axios';
import './Jobnews.css'
import '../govermentjob/JobPage.css'
import Footer from '../../../Footer';
import Heading from '../../../Heading';
import NavbarComponent from '../../../NavbarComponent';

function News65479d252d9a4ffaef2bf534() {
  const[jobnewsList,setjobnewsList]=useState([]);

  useEffect(()=>{
    //console.log("hello")
    axios.get('https://naukridekho-backend-92571da7a098.herokuapp.com/jobnews')
    .then(res=>{
      //console.log(res.data.jobnewsData);
      setjobnewsList(res.data.jobnewsData)
    })
    .catch(err=>{
      console.log(err);
    })
  },[]);

  return (
   <>
   <Heading/>
   <NavbarComponent/>
{/*Job related details*/}

<div>
  <div className='div-body'>
<div className='job-body'>

    <div className='div1'>
        <h2 className='job-h2'>Job News</h2>
          
    </div>
    <div className='div1'>
        <h2 className='job-h2'></h2>
          <h3 className='h3a1'>{jobnewsList[0]?.title}</h3>
    </div>
      <div className='div1'>
        <h2 className='job-h2'>Date</h2>
          <h3>{jobnewsList[0]?.date}</h3>
    </div>
    <div className='div1'>
        <h2 className='job-h2'></h2>
          <h3>{jobnewsList[0]?.information}</h3>
    </div>
    <div className='div1'>
        <h2 className='job-h2'></h2>
         <h3>{jobnewsList[0]?.details}</h3>
      </div>
   
 </div>
 </div>    
  <Footer/> 


</div>
</>
  )}


export default News65479d252d9a4ffaef2bf534