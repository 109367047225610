import React, { useEffect, useState } from 'react'
import axios from 'axios';
import { Link } from 'react-router-dom';
import Footer from './Footer'
import './css/HomePage.css'
import NavbarComponent from './NavbarComponent'
import Marquee from './Marquee';
import Topheader from './Topheader';
import Menubar from './Menubar';
import Heading from './Heading';
import loader from './assets/loader.gif'
import JobCard from './JobCard';
import Footer_content from './Footer_content'
import { Helmet } from 'react-helmet';
import Navbar from './Navbar';

//const date=new Date().toLocaleDateString();
//const time=new Date().toLocaleTimeString();


export default function HomePage() {
  const [isloading, setloading] = useState(true);
  const [jobList, setjobList] = useState([]);
  useEffect(() => {
    //console.log("hello")
    axios.get(' https://naukridekho-backend-92571da7a098.herokuapp.com/govjob')
      .then(res => {
        // console.log(res.data.govjobData);  
        setjobList(res.data.govjobData);
        setloading(false);
      })
      .catch(err => {
        console.log(err);
        setloading(false);
      })
  }, []);
  const [admitcardList, setadmitcardList] = useState([]);

  useEffect(() => {
    //console.log("hello")
    axios.get('https://naukridekho-backend-92571da7a098.herokuapp.com/admitcard')
      .then(res => {
        //console.log(res.data.admitcardData);
        setadmitcardList(res.data.admitcardData)
        setloading(false);
      })
      .catch(err => {
        console.log(err);
        setloading(false);
      })
  }, []);
  const [resultList, setresultList] = useState([]);

  useEffect(() => {
    //console.log("hello")
    axios.get(' https://naukridekho-backend-92571da7a098.herokuapp.com/result')
      .then(res => {
        //console.log(res.data.resultData);
        setresultList(res.data.resultData)
        setloading(false);
      })
      .catch(err => {
        console.log(err);
        setloading(false);
      })
  }, []);
  const [jobnewsList, setjobnewsList] = useState([]);

  useEffect(() => {
    //console.log("hello")
    axios.get(' https://naukridekho-backend-92571da7a098.herokuapp.com/jobnews',)
      .then(res => {
        //console.log(res.data.jobnewsData);
        setjobnewsList(res.data.jobnewsData)
        setloading(false);
      })
      .catch(err => {
        console.log(err);
        setloading(false);
      })
  }, []);

  const [updatesList, setupdatesList] = useState([]);

  useEffect(() => {
    //console.log("hello")
    axios.get(' https://naukridekho-backend-92571da7a098.herokuapp.com/updates',)
      .then(res => {
        //console.log(res.data.updatesData);
        setupdatesList(res.data.updatesData)
        setloading(false);
      })
      .catch(err => {
        console.log(err);
        setloading(false);
      })
  }, []);


  const [importantList, setimportantList] = useState([]);

  useEffect(() => {
    //console.log("hello")
    axios.get('https://naukridekho-backend-92571da7a098.herokuapp.com/important')
      .then(res => {
        //console.log(res.data.importantData);
        setimportantList(res.data.importantData)
        setloading(false);
      })
      .catch(err => {
        console.log(err);
        setloading(false);
      })
  }, []);




  return (
    <>
    <Helmet>
    <script async src="https://pagead2.googlesyndication.com/pagead/js/adsbygoogle.js?client=ca-pub-9504527545697458"
     crossorigin="anonymous"></script>
    </Helmet>
   <div className='home'>
    <Navbar/>
       <Marquee />
        <Topheader />
  <JobCard/>
       
        {isloading &&
            <img style={{ width: '300px', marginLeft: '38%' }} src={loader} />
          }
          {!isloading &&
        <div className="part6">
    
          <div className="part6_container">
            <div className="feature1">
              <h2 className="h2A">Latest Jobs</h2>
              <li className='home-li'><Link to="/NTA-CSIR-UGC-NET-Online-Form-2024">{jobList[123]?.name}<span className='p-date'> {jobList[123]?.date}</span></Link></li>
              <li className='home-li'><Link to="/Indian-Navy-Agniveer-SSR-/-MR-Online-Form-02/2024-Batch">{jobList[122]?.name}<span className='p-date'> {jobList[122]?.date}</span></Link></li>
              <li className='home-li'><Link to="/Army-10+2-TES-52-Online-Form-2024">{jobList[121]?.name}<span className='p-date'> {jobList[121]?.date}</span></Link></li>
              <li className='home-li'><Link to="/Bihar-BSPHCL-Various-Post-Online-Form-2024">{jobList[120]?.name}<span className='p-date'> {jobList[120]?.date}</span></Link></li>
              <li className='home-li'><Link to="/Railway-SECR-Apprentice-Online-Form-2024">{jobList[119]?.name}<span className='p-date'> {jobList[119]?.date}</span></Link></li>
              <li className='home-li'><Link to="/SSC-Junior-Engineer-JE-Online-Form-2024">{jobList[118]?.name}<span className='p-date'> {jobList[118]?.date}</span></Link></li>
              <li className='home-li'><Link to="/Oriental-Insurance-OICL-AO-Online-Form-2024">{jobList[117]?.name}<span className='p-date'> {jobList[117]?.date}</span></Link></li>
              <li className='home-li'><Link to="/NVS-Non-Teaching-Various-Post-Online-Form-2024">{jobList[116]?.name}<span className='p-date'> {jobList[116]?.date}</span></Link></li>
              <li className='home-li'><Link to="/BSF-Group-B-and-C-Various-Post-Online-Form-2024">{jobList[115]?.name}<span className='p-date'> {jobList[115]?.date}</span></Link></li>
              <li className='home-li'><Link to="/SSC-Selection-Post-XII-OnlineForm">{jobList[114]?.name}<span className='p-date'> {jobList[114]?.date}</span></Link></li>
              <li className='home-li'><Link to="/MPPSC-State-Eligibility-Test-SET-Online-Form-2024">{jobList[113]?.name}<span className='p-date'> {jobList[113]?.date}</span></Link></li>
              <li className='home-li'><Link to="/UKPSC-Pre-2024-Online-Form">{jobList[112]?.name}<span className='p-date'> {jobList[112]?.date}</span></Link></li>
              <li className='home-li'><Link to="/UKPSC-Sub-Inspector-Online-Form-2024">{jobList[111]?.name}<span className='p-date'> {jobList[111]?.date}</span></Link></li>
              <li className='home-li'><Link to="/RCF-Kapurthala-Apprentices-Online-Form-2024">{jobList[110]?.name}<span className='p-date'> {jobList[110]?.date}</span></Link></li>
              <li className='home-li'><Link to="/Punjab-Police-Constable-Online-Form-2024">{jobList[109]?.name}<span className='p-date'> {jobList[109]?.date}</span></Link></li>
              <li className='home-li'><Link to="/UPPSC-APS-2023-Mains-Online-Form">{jobList[108]?.name}<span className='p-date'> {jobList[108]?.date}</span></Link></li>
              <li className='home-li'><Link to="/UPSC-EPFO-Personal-Assistant-Online-Form-2024">{jobList[107]?.name}<span className='p-date'> {jobList[107]?.date}</span></Link></li>
              <li className='home-li'><Link to="/SSC-Sub-Inspector-CPO-SI-Online-Form-2024">{jobList[106]?.name}<span className='p-date'> {jobList[106]?.date}</span></Link></li>
              <li className='home-li'><Link to="/Railway-RRB-Technician-Online-Form-2024">{jobList[105]?.name}<span className='p-date'> {jobList[105]?.date}</span></Link></li>
              <li className='home-li'><Link to="/CTET-July-2024-Online-Form">{jobList[104]?.name}<span className='p-date'> {jobList[104]?.date}</span></Link></li>
              <li className='home-li'><Link to="/BPSC-Head-Teacher-Online-Form">{jobList[103]?.name}<span className='p-date'> {jobList[103]?.date}</span></Link></li>
              <li className='home-li'><Link to="/Railway-RPF-Constable-/-Sub-Inspector-Online-Form-2024">{jobList[102]?.name}<span className='p-date'> {jobList[102]?.date}</span></Link></li>
              <li className='home-li'><Link to="/UPPSC-Staff-Nurse-Mains-Online-Form-2024">{jobList[101]?.name}<span className='p-date'> {jobList[101]?.date}</span></Link></li>
              <li className='home-li'><Link to="/RSMSSB-Stenographer-PA-Online-Form-2024">{jobList[100]?.name}<span className='p-date'> {jobList[100]?.date}</span></Link></li>
              <li className='home-li'><Link to="/BPSC-Block-Horticulture-Officer-BHO-Online-Form-2024">{jobList[99]?.name}<span className='p-date'> {jobList[99]?.date}</span></Link></li>
              <li className='home-li'><Link to="/Jharkhand-High-Court-Typist-and-Stenographer-Online-Form-2024">{jobList[98]?.name}<span className='p-date'> {jobList[98]?.date}</span></Link></li>
              <li className='home-li'><Link to="/BPSC-Assistant-Architect-Online-Form-2024">{jobList[97]?.name}<span className='p-date'> {jobList[97]?.date}</span></Link></li>
              <li className='home-li'><Link to="/Navy-SSC-Officers-January-2025-Batch-Online-Form">{jobList[96]?.name}<span className='p-date'> {jobList[96]?.date}</span></Link></li>
              <li className='home-li'><Link to="/RSMSSB-Hostel-Superintendent-Online-Form-2024">{jobList[95]?.name}<span className='p-date'> {jobList[95]?.date}</span></Link></li>
              <li className='home-li'><Link to="/RSMSSB-Junior-Assistant-Online-Form-2024">{jobList[94]?.name}<span className='p-date'> {jobList[94]?.date}</span></Link></li>
              <li className='home-li'><Link to="/SBI-Specialist-Officer-SO-Online-Form-2024">{jobList[93]?.name}<span className='p-date'> {jobList[93]?.date}</span></Link></li>
              <li className='home-li'><Link to="/UPSC-Civil-Services-IAS-/-IFS-Pre-Online-Form-2024">{jobList[92]?.name}<span className='p-date'> {jobList[92]?.date}</span></Link></li>
              <li className='home-li'><Link to="/Haryana-Police-Constable-Online-Form-2024">{jobList[91]?.name}<span className='p-date'> {jobList[91]?.date}</span></Link></li>
              <li className='home-li'><Link to="/Army-Agniveer-CEE-Online-Form-2024">{jobList[90]?.name}<span className='p-date'> {jobList[90]?.date}</span></Link></li>
              <li className='home-li'><Link to="/UPSSSC-Various-Post-Online-Form-Through-PET-2023">{jobList[89]?.name}<span className='p-date'> {jobList[89]?.date}</span></Link></li>
              <li className='home-li'><Link to="/BPSC-School-Teacher-TRE-3.0-Online-Form-2024">{jobList[88]?.name}<span className='p-date'> {jobList[88]?.date}</span></Link></li>
              <li className='home-li'><Link to="/PNB-SO-Online-Form-2024">{jobList[87]?.name}<span className='p-date'> {jobList[87]?.date}</span></Link></li>
              <li className='home-li'><Link to="/Coast-Guard-Assistant-Commandant-Online-Form-2024">{jobList[86]?.name}<span className='p-date'> {jobList[86]?.date}</span></Link></li>
              <li className='home-li'><Link to="/Coast-Guard-Navik-GD-02/2024-Online-Form">{jobList[85]?.name}<span className='p-date'> {jobList[85]?.date}</span></Link></li>
              <li className='home-li'><Link to="/DSSSB-Various-12785-Post-Online-Form-2024">{jobList[84]?.name}<span className='p-date'> {jobList[84]?.date}</span></Link></li>
              <li className='home-li'><Link to="/SSC-Selection-Post-XII-Online-Form">{jobList[83]?.name}<span className='p-date'> {jobList[83]?.date}</span></Link></li>
              
              <button className='home-link-button'><Link to="/Latestjob">More</Link></button>

            </div>
            <div className="feature2">
              <h2 className='h2A'>Admit Card</h2>
              <li className='home-li'><Link to="/HPSC-HCS-Mains-Admit-Card-2024">{admitcardList[89]?.name} <span className='p-date'> {admitcardList[89]?.date}</span></Link></li>
              <li className='home-li'><Link to="/Indian-Airforce-Agniveer-Intake-01/2025-Admit-Card">{admitcardList[88]?.name} <span className='p-date'> {admitcardList[88]?.date}</span></Link></li>
              <li className='home-li'><Link to="/Bihar-School-Teacher-TRE-3-Admit-Card-2024-Exam-Center-Details-Available">{admitcardList[87]?.name} <span className='p-date'> {admitcardList[87]?.date}</span></Link></li>
              <li className='home-li'><Link to="/Jharkhand-JPSC-Pre-2024-Admit Card">{admitcardList[86]?.name} <span className='p-date'> {admitcardList[86]?.date}</span></Link></li>
              <li className='home-li'><Link to="/Bihar-School-Teacher-TRE-3.0-Exam-Date">{admitcardList[85]?.name} <span className='p-date'> {admitcardList[85]?.date}</span></Link></li>
              <li className='home-li'><Link to="/NTA-CUET-PG-2024-Subject-Wise-Exam-Schedule">{admitcardList[84]?.name} <span className='p-date'> {admitcardList[84]?.date}</span></Link></li>
              <li className='home-li'><Link to="/National-Insurance-NIACL-AO-Admit-Card-2024">{admitcardList[83]?.name} <span className='p-date'> {admitcardList[83]?.date}</span></Link></li>
              <li className='home-li'><Link to="/RPSC-Statistical-Officer-Admit-Card-2024">{admitcardList[82]?.name} <span className='p-date'> {admitcardList[82]?.date}</span></Link></li>
              <li className='home-li'><Link to="/National-Insurance-NIACL-AO-Exam-Date-2024">{admitcardList[81]?.name} <span className='p-date'> {admitcardList[81]?.date}</span></Link></li>
              <li className='home-li'><Link to="/UPSSSC-Instructor-2022-Admit-Card">{admitcardList[80]?.name} <span className='p-date'> {admitcardList[80]?.date}</span></Link></li>
              <li className='home-li'><Link to="/Bihar-BSSTET-2023-Admit-Card">{admitcardList[79]?.name} <span className='p-date'> {admitcardList[79]?.date}</span></Link></li>
              <li className='home-li'><Link to="/IBPS-PO-XIII-Interview-Letter">{admitcardList[78]?.name} <span className='p-date'> {admitcardList[78]?.date}</span></Link></li>
              <li className='home-li'><Link to="/UP-Police-Constable-60244-Post-Online-Form-2023">UP Police Constable 60244 Post Exam City<span className='p-date'> 10/02/2024</span></Link></li>
              <li className='home-li'><Link to="/BPSC-Various-Agriculture-Post-Exam-Date-2024">{admitcardList[77]?.name} <span className='p-date'> {admitcardList[77]?.date}</span></Link></li>
              <li className='home-li'><Link to="/RSMSSB-Sangnak-Computer-Exam-Date-2024">{admitcardList[76]?.name} <span className='p-date'> {admitcardList[76]?.date}</span></Link></li>
              <li className='home-li'><Link to="/Delhi-High-Court-PA-Stage-II-Admit-Card-2024">{admitcardList[75]?.name} <span className='p-date'> {admitcardList[75]?.date}</span></Link></li>
              <li className='home-li'><Link to="/Bihar-Police-BPSSC-Sub-Inspector-SI-Phase-II-Mains-Admit-Card-2024">{admitcardList[74]?.name} <span className='p-date'> {admitcardList[74]?.date}</span></Link></li>
              <li className='home-li'><Link to="/General-Insurance-GIC-Scale-I-Various-Post-Exam-Date-2024">{admitcardList[73]?.name} <span className='p-date'> {admitcardList[73]?.date}</span></Link></li>
              <li className='home-li'><Link to="/ssc-gd-admit-card-2023">{admitcardList[72]?.name} <span className='p-date'> {admitcardList[72]?.date}</span></Link></li>
              <li className='home-li'><Link to="/NIFT-2024-Admission-Exam-City-Admit-Card">{admitcardList[71]?.name} <span className='p-date'> {admitcardList[71]?.date}</span></Link></li>
              <li className='home-li'><Link to="/NTA-CUREC-IGNOU-Admit-Card-2024">{admitcardList[70]?.name} <span className='p-date'> {admitcardList[70]?.date}</span></Link></li>
              <li className='home-li'><Link to="/CGPSC-Pre-2023-Admit-Card">{admitcardList[69]?.name} <span className='p-date'> {admitcardList[69]?.date}</span></Link></li>
              <li className='home-li'><Link to="/UPPSC-RO-ARO-2023-Admit-Card">{admitcardList[68]?.name} <span className='p-date'> {admitcardList[68]?.date}</span></Link></li>
              <li className='home-li'><Link to="/Patna-High-Court-Assistant-2023-Interview-Letter">{admitcardList[67]?.name} <span className='p-date'> {admitcardList[67]?.date}</span></Link></li>
              <li className='home-li'><Link to="/AFCAT-Admit-Card">{admitcardList[66]?.name} <span className='p-date'> {admitcardList[66]?.date}</span></Link></li>
              <li className='home-li'><Link to="/Navy-INCET-Admit-Card">{admitcardList[65]?.name} <span className='p-date'> {admitcardList[65]?.date}</span></Link></li>
              <li className='home-li'><Link to="/JSSC-CGL-2023-ADMIT-CARD">{admitcardList[64]?.name} <span className='p-date'> {admitcardList[64]?.date}</span></Link></li>
              <li className='home-li'><Link to="/CTET-January-2024-Exam-City-Details">{admitcardList[52]?.name}  and Admit card <span className='p-date'> 18/01/2024</span></Link></li>
              <li className='home-li'><Link to="/CSIR-CASE-SO-/-ASO-Exam-Date-2024">{admitcardList[63]?.name} <span className='p-date'> {admitcardList[63]?.date}</span></Link></li>
              <li className='home-li'><Link to="/NTA-AISSEE-2024-Admit-Card">{admitcardList[62]?.name} <span className='p-date'> {admitcardList[62]?.date}</span></Link></li>
              <li className='home-li'><Link to="/SSB-HC,-ASI,-SI-Admit-Card-2024">{admitcardList[61]?.name} <span className='p-date'> {admitcardList[61]?.date}</span></Link></li>
              <li className='home-li'><Link to="/SBI-CBO-Admit-Card-2024">{admitcardList[60]?.name} <span className='p-date'> {admitcardList[60]?.date}</span></Link></li>
              
              <button className='home-link-button'><Link to="/Admitcard">More</Link></button>

            </div>
            <div className="feature3">
              <h2 className="h2A">Results</h2>
              <li className='home-li'><Link to="/BSEB-Bihar-Sakshamta-Pariksha-Result-2024">{resultList[82]?.name}<span className='p-date'>{resultList[82]?.date}</span></Link></li>
              <li className='home-li'><Link to="/MHA-IB-ACIO-2023-Result">{resultList[81]?.name}<span className='p-date'>{resultList[81]?.date}</span></Link></li>
              <li className='home-li'><Link to="/IIT-GATE-2024-Result-Score-Card">{resultList[80]?.name}<span className='p-date'>{resultList[80]?.date}</span></Link></li>
              <li className='home-li'><Link to="/NIC-Scientific-and-Technical-Post-Result-2024">{resultList[79]?.name}<span className='p-date'>{resultList[79]?.date}</span></Link></li>
              <li className='home-li'><Link to="/Patna-High-Court-Stenographer-2023-Final-Result">{resultList[78]?.name}<span className='p-date'>{resultList[78]?.date}</span></Link></li>
              <li className='home-li'><Link to="/Chandigarh-Police-Constable-IT-Result-2024">{resultList[77]?.name}<span className='p-date'>{resultList[77]?.date}</span></Link></li>
              <li className='home-li'><Link to="/Intelligence-Bureau-IB-SA/MT-and-MTS-Result-2024">{resultList[76]?.name}<span className='p-date'>{resultList[76]?.date}</span></Link></li>
              <li className='home-li'><Link to="/UKPSC-RO-ARO-2023-Pre-Result-Cutoff-Marks">{resultList[75]?.name}<span className='p-date'>{resultList[75]?.date}</span></Link></li>
              <li className='home-li'><Link to="/MHA-IB-SA-MTS-2022-Final-Result">{resultList[74]?.name}<span className='p-date'>{resultList[74]?.date}</span></Link></li>
              <li className='home-li'><Link to="/SBI-Apprentices-2023-Final-Result">{resultList[73]?.name}<span className='p-date'>{resultList[73]?.date}</span></Link></li>
              <li className='home-li'><Link to="/UPPSC-Staff-Nurse-2023-Pre-Result">{resultList[72]?.name}<span className='p-date'>{resultList[72]?.date}</span></Link></li>
              <li className='home-li'><Link to="/UPSSSC-Junior-Engineer-2018-Supplementary-Result">{resultList[71]?.name}<span className='p-date'>{resultList[71]?.date}</span></Link></li>
              <li className='home-li'><Link to="/SBI-Clerk-Pre-Result-2024">{resultList[70]?.name}<span className='p-date'>{resultList[70]?.date}</span></Link></li>
              <li className='home-li'><Link to="/CTET-January-2024-Result">{resultList[69]?.name}<span className='p-date'>{resultList[69]?.date}</span></Link></li>
              <li className='home-li'><Link to="/Bihar-BTSC-ANM-2022-Result-/-Score-Card">{resultList[68]?.name}<span className='p-date'>{resultList[68]?.date}</span></Link></li>
              <li className='home-li'><Link to="/DFCCIL-Various-Post-2023-Stage-II-Result">{resultList[67]?.name}<span className='p-date'>{resultList[67]?.date}</span></Link></li>
              <li className='home-li'><Link to="/BLW-Railway-Apprentices-2023-Merit-List">{resultList[66]?.name}<span className='p-date'>{resultList[66]?.date}</span></Link></li>
              <li className='home-li'><Link to="/Delhi-High-Court-Judicial-Services-Pre-2023-Result-with-Marks">{resultList[65]?.name}<span className='p-date'>{resultList[65]?.date}</span></Link></li>
              <li className='home-li'><Link to="/EMRS-Junior-Secretariat-Assistant-2023-Additional-Result">{resultList[64]?.name}<span className='p-date'>{resultList[64]?.date}</span></Link></li>
              <li className='home-li'><Link to="/AAI-Junior-Executive-ATS-2023-Result">{resultList[63]?.name}<span className='p-date'>{resultList[63]?.date}</span></Link></li>
              <li className='home-li'><Link to="/Allahabad-High-Court-APS-2021-Cutoff">{resultList[62]?.name}<span className='p-date'>{resultList[62]?.date}</span></Link></li>
              <li className='home-li'><Link to="/UPPCL-Technician-TG-II-Electrical-2022-Result">{resultList[61]?.name}<span className='p-date'>{resultList[61]?.date}</span></Link></li>
              <li className='home-li'><Link to="/MPESB-Various-Agriculture-Post-Result-2024">{resultList[60]?.name}<span className='p-date'>{resultList[60]?.date}</span></Link></li>
              <li className='home-li'><Link to="/IBPS-PO-13th-Mains-Result-2024">{resultList[59]?.name}<span className='p-date'>{resultList[59]?.date}</span></Link></li>
              <li className='home-li'><Link to="/NTA-UGC-NET-December-2023-Result">{resultList[58]?.name}<span className='p-date'>{resultList[58]?.date}</span></Link></li>
              <li className='home-li'><Link to="/IBPS-SO-13th-Pre-Result-2024">{resultList[57]?.name}<span className='p-date'>{resultList[57]?.date}</span></Link></li>
              <li className='home-li'><Link to="/UPSC-Forest-Services-IFS-2023-Main-Result">{resultList[56]?.name}<span className='p-date'>{resultList[56]?.date}</span></Link></li>
              <li className='home-li'><Link to="/SBI-PO-2023-Mains-Phase-II-Result">{resultList[55]?.name}<span className='p-date'>{resultList[55]?.date}</span></Link></li>
              <li className='home-li'><Link to="/CISF-Constable-Tradesman-2022-Result">{resultList[54]?.name}<span className='p-date'>{resultList[54]?.date}</span></Link></li>
              <li className='home-li'><Link to="/NTA-UGC-NET-/-JRF-December-2023-Exam-Result-Date">{resultList[53]?.name}<span className='p-date'>{resultList[53]?.date}</span></Link></li>
              <li className='home-li'><Link to="/UPSSSC-Instructor-2022-Eligibility-Result">{resultList[52]?.name}<span className='p-date'>{resultList[52]?.date}</span></Link></li>
             
              <button className='home-link-button'><Link to="/Results">More</Link></button>

            </div>
          </div>
          </div>
          
}

{!isloading &&

        <div className="part6">

          <div className="part6_container">
            <div className="feature1">

              <h2 className='h2A'>Jobs News </h2>
              <li className='home-li'><Link to="/Newsid657e859dfd43e58859f6a389">{jobnewsList[2]?.title}</Link></li>
              <li className='home-li'><Link to="/Newsid657e809bfd43e58859f6a371">{jobnewsList[1]?.title}</Link></li>
              <li className='home-li'><Link to="/Newsid65479d252d9a4ffaef2bf534">{jobnewsList[0]?.title}</Link></li>
       
              <button className='home-link-button'><Link to="">More</Link></button>


            </div>
            <div className="feature2">
              <h2 className='h2A'>Updates</h2>
              <li className='home-li'><Link to="/Jobid656a10e9a40bc2f1aa74d091">{updatesList[6]?.name} <span className='p-date'> {updatesList[6]?.date} </span></Link></li>
              <li className='home-li'><Link to="/Jobid6568b8f32829fd098633ed4d">{updatesList[5]?.name} <span className='p-date'> {updatesList[5]?.date}  extended upto 04/12/2023 </span></Link></li>
              <li className='home-li'><Link to="/Jobid654e46f5738b62cb12722436">{updatesList[1]?.name} <span className='p-date'> 25/11/2023 extended upto 27-11-2023</span></Link></li>
              <li className='home-li'><Link to="/Jobid655ec878e0e46b0f7c75d695">{jobList[9]?.name}<span className='p-date'> {jobList[9]?.date} Link Activated Apply online</span></Link></li>
              <li className='home-li'><Link to="/Jobid654dee73738b62cb12722434">{updatesList[0]?.name}</Link></li>
              <li className='home-li'><Link to="/Jobid654e46f5738b62cb12722436">{updatesList[1]?.name}</Link></li>
              <li className='home-li'><Link to="/Jobid654e483e738b62cb12722438">{updatesList[2]?.name}</Link></li>
              <li className='home-li'><Link to="/Jobid654e494a738b62cb1272243a">{updatesList[3]?.name}</Link></li>
              <li className='home-li'><Link to="/Jobid654e4981738b62cb1272243c">{updatesList[4]?.name}</Link></li>
              <button className='home-link-button'><Link to="">More</Link></button>

            </div>
            <div className="feature3">
              <h2 className='h2A'> Important</h2>
              <li className='home-li'><Link to="/Pageid65788b6a0398ae575fa2f044">{importantList[6]?.name}<span className='p-date'>{importantList[6]?.date}</span></Link></li>
              <li className='home-li'><Link to="/Pageid6570717a08506fd46394e33b">{importantList[5]?.name}<span className='p-date'>{importantList[3]?.date}</span></Link></li>
              <li className='home-li'><Link to="/Pageid6570713308506fd46394e339">{importantList[4]?.name}<span className='p-date'>{importantList[3]?.date}</span></Link></li>
              <li className='home-li'><Link to="/Pageid657017429ab81286ba300057">{importantList[3]?.name}<span className='p-date'>{importantList[3]?.date}</span></Link></li>
              <li className='home-li'><Link to="/Pageid656f6d399ab81286ba2ffddb">{importantList[2]?.name}<span className='p-date'>{importantList[2]?.date}</span></Link></li>
              <li className='home-li'><Link to="/Pageid656006992c391fde12d3bd03">{importantList[1]?.name}<span className='p-date'>{importantList[1]?.date}</span></Link></li>
              <li className='home-li'><Link to="/Pageid655fe7f73a6d7e513a4f2aa7">{importantList[0]?.name}<span className='p-date'>{importantList[0]?.date}</span></Link></li>
              <button className='home-link-button'><Link to="">More</Link></button>
            </div>
          </div>

          <div className="part6">

          <div className="part6_container">
            <div className="feature1">

              <h2 className='h2A'>Test Series </h2>
              <li className='home-li'><Link to="/">comming soon ....</Link></li>
              <li className='home-li'><Link to="/">comming soon ....</Link></li>
              <li className='home-li'><Link to="/">comming soon ....</Link></li>
             
             
              <h2 className='h2A'>Mock Test </h2>
              <li className='home-li'><Link to="/">comming soon ....</Link></li>
              <li className='home-li'><Link to="/">comming soon ....</Link></li>
             
              <button className='home-link-button'><Link to="">More</Link></button>


            </div>
            <div className="feature2">
              <h2 className='h2A'>Exam Pattern</h2>
              <li className='home-li'><Link to="/Jobid656a10e9a40bc2f1aa74d091">coming soon ... <span className='p-date'> {updatesList[6]?.date} </span></Link></li>
              <li className='home-li'><Link to="/Jobid656a10e9a40bc2f1aa74d091">coming soon ... <span className='p-date'> {updatesList[6]?.date} </span></Link></li>
              <li className='home-li'><Link to="/Jobid656a10e9a40bc2f1aa74d091">coming soon ... <span className='p-date'> {updatesList[6]?.date} </span></Link></li>
              

              

          
          
              <button className='home-link-button'><Link to="">More</Link></button>

            </div>
            <div className="feature3">
              <h2 className='h2A'> Syllabus</h2>
              <li className='home-li'><Link to="/">coming soon ...<span className='p-date'>{importantList[6]?.date}</span></Link></li>
              <li className='home-li'><Link to="/">coming soon ...<span className='p-date'>{importantList[6]?.date}</span></Link></li>
              <li className='home-li'><Link to="/">coming soon ...<span className='p-date'>{importantList[6]?.date}</span></Link></li>
             
             
              <button className='home-link-button'><Link to="">More</Link></button>
            </div>
          </div>
          </div>

          <a href="//www.dmca.com/Protection/Status.aspx?ID=caa28673-53e7-4fad-bc60-db6f78025b0f" title="DMCA.com Protection Status" class="dmca-badge"> 
          <img src ="https://images.dmca.com/Badges/dmca_protected_sml_120m.png?ID=caa28673-53e7-4fad-bc60-db6f78025b0f"  alt="DMCA.com Protection Status" /></a>
            <script src="https://images.dmca.com/Badges/DMCABadgeHelper.min.js"> </script>
        </div>
}

        <Footer />
      </div>

    </>
   
  )
}