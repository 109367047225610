import React, { useState } from "react";
import axios from "axios";
import './AddLatestjob.css'
import Footer from '../Footer'
import Heading from "../Heading";
import NavbarComponent from "../NavbarComponent";

const Addadmitcard = () => {
  // State to store the form data
  const [formData, setFormData] = useState({
    name:String,
    date:String,
    information:String,
    important_date:String,
    important_links1:String,
    important_links2:String,
    important_links3:String,
    details:String

  });

  // Function to handle form input changes
  const handleChange = (e) => {
    setFormData({ ...formData, [e.target.name]: e.target.value });
  };

  // Function to submit the form data using Axios
  const handleSubmit = async (e) => {
    e.preventDefault();
    try {
      const response = await axios.post("https://naukridekho-backend-92571da7a098.herokuapp.com/admitcard", formData);
      //console.log("Post created:", response.data);
      window.alert(" Added Successfully")
    } catch (error) {
      console.error("Error creating post:", error);
    }
  };

  return (
    <>
    <Heading/>
    <NavbarComponent/>
      <div className="form-data">
        <form onSubmit={handleSubmit}>
          <div className="input">
            <div>
            <label>Name of the Post</label>
            <input className="input1" type="text" name="name" value={formData.name} onChange={handleChange} />
          </div>
          <div>
            <label>Date</label>
            <input className="input1" type="text" name="date" value={formData.date} onChange={handleChange} />
          </div>
          <div>
            <label>Information</label>
            <textarea className="input1" type="text" name="information" value={formData.information} onChange={handleChange} />
          </div>
          <div>
            <label> Important Date</label>
            <textarea className="input1" type="text" name="important_date" value={formData.important_date} onChange={handleChange} />
          </div>
          <div>
            <label> Details</label>
            <textarea className="input1" type="text" name="details" value={formData.details} onChange={handleChange} />
          </div>
          <div>
            <label>Important Links1</label>
            <textarea  placeholder="official site" className="input1" type="text" name="important_links1" value={formData.important_links1} onChange={handleChange} />
          </div>
          <div>
            <label>Important Links2</label>
            <textarea  placeholder="admit card Link" className="input1" type="text" name="important_links2" value={formData.important_links2} onChange={handleChange} />
          </div>
          <div>
            <label>Important Links3</label>
            <textarea placeholder="Result link"className="input1" type="text" name="important_links3" value={formData.important_links3} onChange={handleChange} />
          </div>
          <button className="add-button"type="submit">Add admitcard</button>
          </div>
        </form>
      </div>
      <Footer/>
    </>
  );
};

export default Addadmitcard;