
import React,{useEffect,useState} from 'react'
import axios from 'axios';

export default function Syllabus() {
    const[jobList,setjobList]=useState([]);
    useEffect(()=>{
        //console.log("hello")
        axios.get('/student')
        .then(res=>{
          console.log(res.data.studentData);
          setjobList(res.data.studentData);
        })
        .catch(err=>{
          console.log(err);
        })
      },[]);


  return (
    <>
<div>
    <h1>JobLIST</h1>
    <table>
        <thead>
            <tr>
                <th>name</th>
                <th>details</th>
            </tr>
        </thead>
        <tbody>
            <tr>
                <td>{jobList[0]?.email}</td>
            </tr>
        </tbody>
    </table>
</div>

    </>
  )
}
