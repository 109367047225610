import React from 'react'
import './css/Jsscsyllabus.css'
import { Helmet } from 'react-helmet'
export default function Jsscsyllabus() {
  return (
    
    <div>
      <Helmet>
   <script async src="https://pagead2.googlesyndication.com/pagead/js/adsbygoogle.js?client=ca-pub-9504527545697458"
     crossorigin="anonymous"></script>
   </Helmet>
        <table className='santosh'> <th> <u>Jharkhand Staff Selection Commission</u></th> </table>

     <table> <th className='santosh3'>   The syllabus for Jharkhand Police Constable Recruitment 2023-24 has been released and all the candidates who have been preparing for the posts of Police Constable for a long time, let them know that if they are reading something else outside the syllabus then it is good but There are some such topics given in this syllabus, reading about them from outside is a waste of your precious time. Here you have been given information about all the subject wise syllabus of Jharkhand Police Constable Recruitment 2023-24.
 </th>  
 
 </table>  
 

<table className='santosh1'><th>   
        <table>        JSSC Excise Constable Paper l Exam Pattern
</table> 
 S.No. <p>1.</p> 2.</th>  
<th> Subject(L/k) <p> hindi language knowledge</p> hindi language knowledge</th>
<th>Total.(Questions )<p> 80</p> 40 <p>120</p></th>
<th>Total.( Marks) <p>240</p> 120 <p>36</p></th>
<th>Time Period<p> 02 hours 120 minutes </p> </th></table>
 
  <table className='santosh4'> <th> <u>Knowledge of Hindi language –</u>
  <p></p>
1. Questions based on Hindi Paragraph: 20 questions <br></br>
2. Questions based on Hindi grammar: 60 questions
In this subject, there will be questions based on Hindi Unseen Passage and Hindi Grammar.
<p> <u>Knowledge of English language –</u></p>

1. Questions based on English Paragraph: 20 questions<br></br>
2. Questions based on English Grammar: 20 questions
In this subject, there will be questions based on English Unseen Passage and English Grammar.</th></table>
<table className='santosh2'><th>JSSC Excise Constable Paper ll Exam   </th>
     <thable><th>S.No.<p> 1.</p>2.</th> 
      <th>Subject <p>Candidates will be able to give the exam in any one language out of Urdu / Santhali / Bangla / Mundari (Munda) / Ho / Khadiya / Kudukh (Uraon) / Kurmali / Khortha / Nagpuri / Panchparganiya / Oriya on the basis of option.</p></th>
      <th>Total No. of Questions  <p>100</p> 100</th>
      <th>Total No. of Marks <p>300</p> 300</th>
      <th>Time Period <p></p> <b></b>Total 100 300 2 hours i.e. 120 minutes </th>
      <b>      <p>Note- It will be mandatory to obtain minimum 30 percent marks in Paper 02.</p>
</b>
</thable> 
 </table>
 <table> <th className='santosh4'> <u > Jharkhand Police Constable Recruitment Hindi Subject</u>
    <p>book horizon part 2</p>
    • Poetry section
• Udho you are a very big boy – Surdas
• Ram-Lakshman-Parashuram dialogue – Tulsidas
• ‘Dar Drum Palna’ ‘Palni Nupur – Dev
• ‘Enthusiasm’, ‘At Nahi Rahi’ – Suryakant Tripathi Nirala
• ‘Autobiography’ – Jaishankar Prasad
• ‘Harvest’, ‘This toothy smile’ – Nagarjuna
• Kanyadaan – Rituraj
• Shadow should not touch the mind – Girija Kumar Mathur
• Accompanist – Manglosh Dabral
• Prose – section
• Netaji's glasses – Swayam Prakash
• Bal Gobin Bhagat – Ramvriksha Benipuri
• Lucknowi style – Yashpal
• Divine radiance of human compassion – Sarveshwar Dayal Saxena
• This is also a story – Mannu Bhandari
• Opponent of women's education, refutes the fallacies – Mahavir Prasad Dwivedi
• Prayer in need – Yatindra Mishra
• Culture – Bhakta Anand Kausalyan</th>
 </table>

 <table> <th className='santosh4'> <p> <u>Book – Kritika Part-2</u></p>
• Mother's Anchal – Shivpujan Sahay
• Nose of George V – Kamleshwar
• Sana Sana Haath Jodi – Madhu Kankaria
• Ehi thaiyaan jhulni harani ho Rama – Shivprasad Mishra Rudra
• Why I write – Agyeya
• Grammar:- Part of verb, part of sentence, noun, pronoun, participle, adverb, invariant, compound, case, polysemous words, idioms, antonyms, letter writing.</th></table>

<table > <th className='santosh4'><p> <u> Jharkhand Police Constable Recruitment Kurmali Subject</u></p> 
1. Grammar: Noun, pronoun, word, gender, person, antonyms, antonyms, suffix, sentence – purification.<br></br>
2. Elegant Literature: Kurmali- All studies of literature (story, song, poem)<br></br>
3. Folk Literature: Kurmali Folk Tale<br></br>
• Folk songs- Karam, Bihageet, Damkach Bandana (Sohrai)<br></br>
• Freedom fighter of Jharkhand<br></br>
• Kurmali writers and their works<br></br>
• General introduction to Kurmali literature</th></table>
 
 <table> <th className='santosh4'> <u><p>	English Language and Literature</p> </u> 
<p>1.	Language</p>
•	Error Recognition.  
•	Grammar- Adjective, Noun, Pronoun, Verb, Subject-Verb,  Agreement, Interchangeability of Noun and Verb, Gerund,  Participle, Infinitive, Adverb, Tense, Clause, Transformation,  Narration, Voice, Preposition.  
•	Synonyms.  
•	Antonyms.  
•	Idioms & Phrases.  
•	Comprehension Passage etc. 
 <p>2.	Literature</p>
•	Novel– Oliver Twist- Charles Dickens; Animal farm- George  Orwell; Five Point Someone- Chetan Bhagat; Treasure Island R.L. Stevenson; Retold Stories: Lamb’s Tales from  Shakespeare  
•	Poetry– The Daffodils- William Wordsworth; The Donkey Sir J. Arthur Thomson; Death Be Not Proud- John Donne;  Leisure- W.H. Davis; Where The Mind Is Without Fear Rabindranath Tagore; The Loveliest of Tress, The Cherry Now A.E. Housman  
•	Short Stories– Three Questions- Leo Tolstoy; The Tiger’s  Claw- R.K. Narayan; A Man Who Had No Eyes- Mackinley  Kantor; The Lost Jewel- Rabindranath Tagore .  
•	Essay- Good Manners- J.C. Hill; The Sun, the Planets and the  Stars- C. Jones; Animals In Prison– Jawaharlal Nehru;  Forgetting- R. Lynd.  
•	A History of the English Language (in short): A History of  English Language- A.C. Baugh, Origins of the English Language Joseph Willies, Wikipedia.org 
  <u><p> Jharkhand Police Constable Syllabus 2023-24: Urdu
</p></u>
<p>1.	Prose  </p>
•	Qaumi Hamdardi – Hali  
•	Guzra Howa Zamana – Sir Syed Ahmed Khan
•	Achhi Kitab – Molvi Abdul Haque  
 <p>2.	Poems</p>  
•	Tarana-e-Hind – Iqbal  
•	O Desh se Aanewale Bata – Akhtar Sheerani  
•	Nagma-e-Sahar – Josh Malihabadi  
•	Barkha Rut aur Pardes – Hali. 
 <p>3.	Grammar</p>
•	Opposite
•	Gender
•	Singular
•	Plural
•	Meanings.
</th></table>
<table> <th className='santosh4'> <p> <u>Bengali Language and Literature</u> </p> 
 <p>1.	Novel, Drama, Poetry  </p>
•	Ramer Sumati- Sharatchandra  
•	Mukut-Rabindranath Thakur  
•	Shishu Kabyo (Selected)- Rabindranath Thakur- (i) Manjhi, (ii) Pujar Saj (iii) Sukha Dukkha (iv) Kagojer Nouka  
•	Kabyo Sanchayan- Sahyendranath Dutta- (i) Mati (ii) Jharna (iii) palkirgan (iv) Sagar Tarpan  
<p></p>2.Grammar- <br></br>	Bisheshya, Bisheshan, Sarvanam, Kirya, Linga, Bachan
  <p>3.	Essay</p>
 <p>4.Letter writing</p>	
</th></table>

<table> <th className='santosh4'>  <u><p> Jharkhand Police Constable Recruitment Khortha Subject</p></u>
 <p></p>1. Prose part:- <br></br> Story, one-act play, drama, short novel
• Khortha prose-poetry collection – Publisher Khortha Sahitya Sanskriti Parishad, Bokaro.
• du dior jirahul flower
 <p></p>2. Grammar:- <br></br> Noun, pronoun, case, gender decision, idiom, proverb.
• Helpful books :-
• Sadanic Grammar including Khortha – Author A. Of. Jha
• Khortha Grammar – Author Vasudev Mahato
 <p></p>3. Essay: – <br></br> On contemporary topic
• Helpful texts – Khortha Essay Collection
• Editorial Board – Kumari Shashi, Dr. Vinod Kumar, Dr. B. N. official
• Khortha Essay – Author Dr. B. N. official</th></table>

<table><th className='santosh4'> <p> <u> Jharkhand Police Constable Recruitment Khadiya Subject</u></p>
 <p></p>1. Grammar:-  <br></br>Noun, pronoun, word, gender, person, polysemous words, antonyms, prefix, suffix, sentence correction, idiom, buzzword, reversed word.
 <p></p>2. Elegant Literature:- <br></br> All the texts of Khadiya text book (story, song, poem etc.)
 <p></p>3. Folk literature:-<br></br>
• Khadiya story (folktale)
• Khadiya Geet – Parab – Tiha, Biha (Kersod.). Murad, Kasasing.
• Khariya litterateurs – Julius Ba Pyara Kerketta, Joakim Sunghung Paulus Kullu.
 <p></p>4. Essay – <br></br> Shaheed Teleng Khadiya, Khadiya Mahasabha Bandoi, Jad Kor Karam, Madeit, Janam Par B.
 </th></table>

 <table><th className='santosh4'><p><u>  Jharkhand Police Constable Recruitment Panch Pargania Subject
</u></p>
 <p></p>1. Grammar:- <br></br>Noun, pronoun, word, gender, male, similar words meaning many, reversed words, suffix sentence correction, specific words, letter writing etc.
 <p></p>2. Elegant Literature:- <br></br>  All the excerpts from Panchparganiya Sahitya book (story, song, poem etc.)
 <p></p>3. Folk literature- <br></br>
• Marang Buru,
• Place of worship (devsthal)
• Karam Geet, Sahrai Geet, Biha Geet, Pusgeet, Mantar Geet.
• Birsa Munda, people associated with the freedom movement
• Panchparganiya litterateurs and their works
• Introduction to Panchparganiya literature
</th></table>

<table><th className='santosh4'>  <p> <u> Jharkhand Police Constable Recruitment Santhali Language Syllabus Subject</u></p>
 <p></p>1. Grammar –  <br></br>Noun, pronoun, word, gender, male, similar words, meanings, various antonyms, suffixes, sentence purification.
 <p></p>2. Elegant Literature – <br></br>Santali – All the texts of literary book (story, song, poem etc.)
 <p></p>3. Folk literature – <br></br>
• Agil Hapadam Kova; story,
• Agil Hapadam Kova Serenj – Anech – Baha, Dahar, Sohrai Karam, Dasanay.
• Mahatma Gandhi Jiyon Charit.
• Sido Kanhu and Tilka Manjhi life and movement
• Santali writers and works
• Introduction to Santali literature</th></table>

<table><th className='santosh4'><p><u> Jharkhand Police Constable Recruitment Sanskrit Subject</u> <br></br> Questions will be based on matriculation level text books of Jharkhand state. It will mainly include all the lessons of the book Shemushi (Part-2) approved for class 10th in the state of Jharkhand and the grammar applied in it. Apart from these, word forms, metal forms and other parts of grammar will be as follows:
</p>
1. Word form-/,br Child, Fruit, Rama, Husband, Mother, River, Dhenu, Bride, Father, Mother, King, Gachchh Bhavat Atman Vidvas, Tat Kim Idam Asmad Yushmad. <br></br>
2. Metal forms (Lat, Lot, Vidhi Linga, Lang and Lrit in Lakaars) – Path, Gam, Likh, Pa, Stha, Drish, As, Bhagya, Ghra, Han, Shru, Nrit, Sprish, Katha, Kri. Knowledge and action.<br></br>
3. Sandhi – Vowel sandhi (with variations), consonant sandhi, visarga sandhi.<br></br>
4. Samas – Tatpurush, Bahubrihi and Dwandva Samas.<br></br>
5. Factor – Use of all inflections.<br></br>
6. General grammar – Noun, pronoun, adjective, verb, participle, sound, word, root, voice change (only in latkar).
</th></table>

<table> <th className='santosh4'> <p> <u> Jharkhand Police Constable Recruitment Nagpuri Subject</u></p> 
1. From Nagpuri Grammar - Characters, Nouns, Pronouns, Gender, Number, Adjective, Adverb, Case, Tense, Metal, Verb, Object, Antonyms, Polysemous words, Prefix-Suffix, Compound, one word instead of several words, sentence purification. <br></br>
2. From Nagpuri polite literature - Scene - Part 2 - Shakuntala Mishra, Dr. Umeshnand Tiwari<br></br>
3. From Nagpuri folk literature – folk songs, folk stories, proverbs, riddles, idioms<br></br>
</th></table>
<table> <th className='santosh4'> <p> <u> Jharkhand Police Constable Recruitment Mundari Subject</u></p>

1. Grammar- Noun, pronoun, word, gender, male, similar word, meaning, many antonyms, suffix, sentence purification.<br></br>
2. Elegant Literature – All the texts of Mundari Sahitya book – stories, songs and poems.<br></br>
3. Folk Literature – Mundari Folk Songs (Ba, Karam, Jarga, Jatra Japi, Addandi)<br></br>
</th></table>

<table><th className='santosh4'> <p><u> Jharkhand Police Constable Recruitment Oriya Subject</u></p>
 <p></p>1. Prose Department<br></br>
• Literature – 2007 (OBSE)
• Worldly affairs – Gopal Chandra Praharaj
• Narad Stuti – Dr. Sadashiv Mishra
• Caste source and intellectual standard place – Dr. Vaidyanath Mishra
• Swadhin Jatir Nutan Valuevodh – Dr. Golak Vihari Dhal
 <p></p>• General Literature – 2007 (OBSE)
• High Desire – Shashibhushan Rai
• Sehi Memorial Day – Dr. Harekrishna Mahatav
• Vidya o Vidyarthi – Chittaranjan Das
 <p></p>2. Poetry Department<br></br>
• Literature – 2007 (OBSE)
• Utkal Santan – Madhusudan Das
• River copy – Madhusudan Rau
• Dhauli Pahar – Padma Charan Patnaik
• Upcoming – Kalindi Charan Panigrahi
• Bapuji – Dr. Mayadhar Mansingh
 <p>• General literature</p>
• Yudhishthirank Dharma Pariksha – Sharla Das
• Ramcharit – Upendra Bhanj
• Virat from Chhant Puni Ade – Sachi Routray
• Grampath – Vinod Chandra Nayak
 <p>Oriya literary history</p> 
• Oriya literary festival – Surendra Mahanti
• Oriya Literary History Middle East – Surendra Mahanti
• Oriya Literary History – Dr. Mayadhar Mansingh
• Brief history of Oriya literature – Dr. Vrindavan Acharya
 <p>• Poetry o poetry</p>
• Shrimad Bhagwat (Eleventh Skanda) – Jagannath Dash
• Tapaswini – Gangadhar Mehar
• Kara Kavita – Gopvandhu Dash
 <p>•	Novel</p>
• Chha Maan Aathguntha – Fakir Mohan Senapati
• Matir Manish – Kalindi Charan Panigrahi
 <p>• fiction</p>
• Galpa Shwalpa (First Part) – Fakir Mohan Senapati
• Aajir Galp Anthology (Dimiri Full Magunir Shagad, Shikar) – Nimai Charan Patnaik
 <p>•	Drama</p>
• Konark – Ashwini Kumar Ghosh
• Ghar Sansar – Ramchandra Mishra
<p>• Grammar-</p> gender, number, person, inflection, verb, verb, conjunction, compound, stereotyped usage, antonyms, kudant, tadyit.
<p>THANK YOU Naukridekho.Info </p>
</th></table>

    </div>
  )
}
