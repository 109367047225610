
import React,{useEffect,useState} from 'react'
import axios from 'axios';
import loader from '../../../assets/loader.gif'
import '../govermentjob/JobPage.css'
import Footer from '../../../Footer';
import Heading from '../../../Heading';
import NavbarComponent from '../../../NavbarComponent';
import { Helmet } from 'react-helmet';

function Pageid65845ca0351fbc715505749b() {
 const[resultList,setresultList]=useState([]);

  useEffect(()=>{
    //console.log("hello")
    axios.get('https://naukridekho-backend-92571da7a098.herokuapp.com/result')
    .then(res=>{
      //console.log(res.data.resultData);
      setresultList(res.data.resultData)
      setloading(false)
    })
    .catch(err=>{
      console.log(err);
      setloading(false)
    })
  },[]);
  const[isloading,setloading]=useState(true);
  //console.log(jobList);
  return (
   <>
   <Helmet>
    <title>{resultList[36]?.name}</title>
    <script async src="https://pagead2.googlesyndication.com/pagead/js/adsbygoogle.js?client=ca-pub-9504527545697458"
     crossorigin="anonymous"></script>
    <meta name='description'
    content={resultList[36]?.information} 
    />
    <meta name='keyword'
    content={resultList[36]?.details}
    />
    <meta name='date'content={resultList[36]?.important_date}
    />
   </Helmet>
   <Heading/>
   <NavbarComponent/>
   <main>
   <div className='cards'>
   <div className="single-card">
   <div>
        <span>Test Series</span>
          <h2>&nbsp;</h2>
          </div>
          
   </div>
   <div className="single-card">
                  <div>
                     <span>Syllabus</span>
                     <h2>&nbsp;</h2>
                  </div>
                  
               </div>
    <div className="single-card">
                  <div>
                     <span>Previous Year</span>
                     <h2>&nbsp;</h2>
                  </div>
                
                  </div>
    <div className="single-card">
                  <div>
                     <span>Videos</span>
                     <h2>&nbsp;</h2>
                  </div>
                     
                   </div>           

<div className="single-card" >
                  <div>
                     <span>Exam Pattern</span>
                     <h2>&nbsp;</h2>
                  </div>
                           
                   </div>           

</div>
</main>
{/*Job related details*/}
{isloading &&
<img style={{width:'300px', marginLeft:'38%'}} src={loader} />
}
{!isloading &&
<div className='job-body'>
<div className='div1'>
        <h2 className='job-h2'>Name of the Post</h2>
          <h3>{resultList[36]?.name}</h3>
    </div>
      <div className='div1'>
        <h2 className='job-h2'>Date</h2>
          <h3>{resultList[36]?.date}</h3>
    </div>
    <div className='div1'>
        <h2 className='job-h2'>Information</h2>
          <h3>{resultList[36]?.information}</h3>
    </div>
    <div className='div1'>
        <h2 className ='job-h2'>Important Date </h2>
          <h3>{resultList[36]?.important_date}</h3>
    </div>
    <div className='div1'>
        <h2 className='job-h2'> Details</h2>
          <h3>{resultList[36]?.details}</h3>
    </div>
    <div className='div1'>
        <h2 className='job-h2'>Official Site</h2>
          <h3> Visit Official site< a href={resultList[36]?.important_links1}> Click Here</a></h3>
    </div>
    
    <div className='div1'>
        <h2 className='job-h2'>Result</h2>
          <h3>Result Link< a href={resultList[36]?.important_links3}> Click Here</a></h3>
    </div>
 </div> 
}   
  <Footer/> 



</>
  )}


export default Pageid65845ca0351fbc715505749b