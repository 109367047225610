
import React,{useEffect,useState} from 'react'

function App() {
  const [users,setUser]=useState([])
  useEffect(()=>{
    fetch("/student").then((result)=>{
      result.json().then((resp)=>{
        console.warn(resp)
        setUser(resp)
      })
    })
  },[])
  console.warn(users)
  return (
    <div className="App">
      <h1>Get API Call </h1>
      <table border="1">
       <tbody>
       <tr>
          <td>ID</td>
          <td> Post Name</td>
          <td>Date</td>
          <td>Information</td>
          <td>Important Date</td>
          <td>Application fee</td>
           <td> eligibility</td>
           <td>agelimit</td>
           <td>syllabus</td>
           <td>lecture</td>
        </tr>
        {
          users.map((item,i)=>
            <tr key={i}>
                <td>{item.id}</td>
            <td>{item.email}</td>
          <td>{item.phone}</td>
          <td>{item.gender}</td>
          
          </tr>
          )
        }
       </tbody>
      </table>
    </div>
  );
}
export default App;