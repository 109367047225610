import React from 'react'
import { Link } from 'react-router-dom'
import {useEffect,useState} from 'react'
import axios from 'axios'
import NavbarComponent from '../NavbarComponent'
import Footer from '../Footer'
import Heading from '../Heading'
import { Helmet } from 'react-helmet'
import'./css/Latestjob.css'
import'./Jobs/govermentjob/JobPage.css'
export default function Latestjob() {
  const[jobList,setjobList]=useState([]);
  useEffect(()=>{
    //console.log("hello")
    axios.get(' https://naukridekho-backend-92571da7a098.herokuapp.com/govjob')
    .then(res=>{
      //console.log(res.data.govjobData);
      setjobList(res.data.govjobData);
    })
    .catch(err=>{
      console.log(err);
    })
  },[]);

  return (
    <>
    <NavbarComponent/>
    <Heading/>
    <Helmet>
    <title>Latestjob</title>
    <script async src="https://pagead2.googlesyndication.com/pagead/js/adsbygoogle.js?client=ca-pub-9504527545697458"
     crossorigin="anonymous"></script>
    <meta name='description'
    content="get the Latest notification of Latest job " 
    />
    <meta name='keyword'
    content="
    sarkari job,Latest job ,Latest job naukridekho
    latest govt jobs notifications,
    free job alert,
    sarkari result 10+2 latest job,
    sarkari result 10+2 latest job male,
    sarkari result 10+2 latest job female,
    government jobs after 12th,
    sarkari job alert,
    latest govt jobs notifications for 12th pass,
10th pass govt job,
government jobs after 12th,
sarkari job,
govt job alert,
central government jobs list,
free job alert 2024,
free job alert odisha"
    />
    <link rel="canonical" href='/Latestjob'/>
   </Helmet>
    <div className='lat-page-body'>
    <div className='job-body'>
    <div className='div1'>
      <Link  to="/Indian-Navy-Agniveer-SSR-/-MR-Online-Form-02/2024-Batch">
         <h3>{jobList[123]?.name} {jobList[123]?.date}</h3>
         <button className='lat-button'>Test Series</button>
         <button className='lat-button'>Syllabus</button>
         <button className='lat-button'>Previous Year</button>
         <button className='lat-button'>Videos</button>
         <button className='lat-button'>Exam Pattern</button>
         </Link>
      </div>
    <div className='div1'>
      <Link  to="/Indian-Navy-Agniveer-SSR-/-MR-Online-Form-02/2024-Batch">
         <h3>{jobList[122]?.name} {jobList[122]?.date}</h3>
         <button className='lat-button'>Test Series</button>
         <button className='lat-button'>Syllabus</button>
         <button className='lat-button'>Previous Year</button>
         <button className='lat-button'>Videos</button>
         <button className='lat-button'>Exam Pattern</button>
         </Link>
      </div>
    <div className='div1'>
      <Link  to="/Army-10+2-TES-52-Online-Form-2024">
         <h3>{jobList[121]?.name} {jobList[121]?.date}</h3>
         <button className='lat-button'>Test Series</button>
         <button className='lat-button'>Syllabus</button>
         <button className='lat-button'>Previous Year</button>
         <button className='lat-button'>Videos</button>
         <button className='lat-button'>Exam Pattern</button>
         </Link>
      </div>
    <div className='div1'>
      <Link  to="/Bihar-BSPHCL-Various-Post-Online-Form-2024">
         <h3>{jobList[120]?.name} {jobList[120]?.date}</h3>
         <button className='lat-button'>Test Series</button>
         <button className='lat-button'>Syllabus</button>
         <button className='lat-button'>Previous Year</button>
         <button className='lat-button'>Videos</button>
         <button className='lat-button'>Exam Pattern</button>
         </Link>
      </div>
    <div className='div1'>
      <Link  to="/Railway-SECR-Apprentice-Online-Form-2024">
         <h3>{jobList[119]?.name} {jobList[119]?.date}</h3>
         <button className='lat-button'>Test Series</button>
         <button className='lat-button'>Syllabus</button>
         <button className='lat-button'>Previous Year</button>
         <button className='lat-button'>Videos</button>
         <button className='lat-button'>Exam Pattern</button>
         </Link>
      </div>
    <div className='div1'>
      <Link  to="/SSC-Junior-Engineer-JE-Online-Form-2024">
         <h3>{jobList[118]?.name} {jobList[118]?.date}</h3>
         <button className='lat-button'>Test Series</button>
         <button className='lat-button'>Syllabus</button>
         <button className='lat-button'>Previous Year</button>
         <button className='lat-button'>Videos</button>
         <button className='lat-button'>Exam Pattern</button>
         </Link>
      </div>
    <div className='div1'>
      <Link  to="/Oriental-Insurance-OICL-AO-Online-Form-2024">
         <h3>{jobList[117]?.name} {jobList[117]?.date}</h3>
         <button className='lat-button'>Test Series</button>
         <button className='lat-button'>Syllabus</button>
         <button className='lat-button'>Previous Year</button>
         <button className='lat-button'>Videos</button>
         <button className='lat-button'>Exam Pattern</button>
         </Link>
      </div>
    <div className='div1'>
      <Link  to="/NVS-Non-Teaching-Various-Post-Online-Form-2024">
         <h3>{jobList[116]?.name} {jobList[116]?.date}</h3>
         <button className='lat-button'>Test Series</button>
         <button className='lat-button'>Syllabus</button>
         <button className='lat-button'>Previous Year</button>
         <button className='lat-button'>Videos</button>
         <button className='lat-button'>Exam Pattern</button>
         </Link>
      </div>
    <div className='div1'>
      <Link  to="/SSC-Selection-Post-XII-OnlineForm">
         <h3>{jobList[115]?.name} {jobList[115]?.date}</h3>
         <button className='lat-button'>Test Series</button>
         <button className='lat-button'>Syllabus</button>
         <button className='lat-button'>Previous Year</button>
         <button className='lat-button'>Videos</button>
         <button className='lat-button'>Exam Pattern</button>
         </Link>
      </div>
    <div className='div1'>
      <Link  to="/SSC-Selection-Post-XII-OnlineForm">
         <h3>{jobList[114]?.name} {jobList[114]?.date}</h3>
         <button className='lat-button'>Test Series</button>
         <button className='lat-button'>Syllabus</button>
         <button className='lat-button'>Previous Year</button>
         <button className='lat-button'>Videos</button>
         <button className='lat-button'>Exam Pattern</button>
         </Link>
      </div>
    <div className='div1'>
      <Link  to="/MPPSC-State-Eligibility-Test-SET-Online-Form-2024">
         <h3>{jobList[113]?.name} {jobList[113]?.date}</h3>
         <button className='lat-button'>Test Series</button>
         <button className='lat-button'>Syllabus</button>
         <button className='lat-button'>Previous Year</button>
         <button className='lat-button'>Videos</button>
         <button className='lat-button'>Exam Pattern</button>
         </Link>
      </div>
    <div className='div1'>
      <Link  to="/UKPSC-Pre-2024-Online-Form">
         <h3>{jobList[112]?.name} {jobList[112]?.date}</h3>
         <button className='lat-button'>Test Series</button>
         <button className='lat-button'>Syllabus</button>
         <button className='lat-button'>Previous Year</button>
         <button className='lat-button'>Videos</button>
         <button className='lat-button'>Exam Pattern</button>
         </Link>
      </div>
    <div className='div1'>
      <Link  to="/UKPSC-Sub-Inspector-Online-Form-2024">
         <h3>{jobList[111]?.name} {jobList[111]?.date}</h3>
         <button className='lat-button'>Test Series</button>
         <button className='lat-button'>Syllabus</button>
         <button className='lat-button'>Previous Year</button>
         <button className='lat-button'>Videos</button>
         <button className='lat-button'>Exam Pattern</button>
         </Link>
      </div>
    <div className='div1'>
      <Link  to="/RCF-Kapurthala-Apprentices-Online-Form-2024">
         <h3>{jobList[110]?.name} {jobList[110]?.date}</h3>
         <button className='lat-button'>Test Series</button>
         <button className='lat-button'>Syllabus</button>
         <button className='lat-button'>Previous Year</button>
         <button className='lat-button'>Videos</button>
         <button className='lat-button'>Exam Pattern</button>
         </Link>
      </div>
    <div className='div1'>
      <Link  to="/Punjab-Police-Constable-Online-Form-2024">
         <h3>{jobList[109]?.name} {jobList[109]?.date}</h3>
         <button className='lat-button'>Test Series</button>
         <button className='lat-button'>Syllabus</button>
         <button className='lat-button'>Previous Year</button>
         <button className='lat-button'>Videos</button>
         <button className='lat-button'>Exam Pattern</button>
         </Link>
      </div>
    <div className='div1'>
      <Link  to="/UPPSC-APS-2023-Mains-Online-Form">
         <h3>{jobList[108]?.name} {jobList[108]?.date}</h3>
         <button className='lat-button'>Test Series</button>
         <button className='lat-button'>Syllabus</button>
         <button className='lat-button'>Previous Year</button>
         <button className='lat-button'>Videos</button>
         <button className='lat-button'>Exam Pattern</button>
         </Link>
      </div>
    <div className='div1'>
      <Link  to="/UPSC-EPFO-Personal-Assistant-Online-Form-2024">
         <h3>{jobList[107]?.name} {jobList[107]?.date}</h3>
         <button className='lat-button'>Test Series</button>
         <button className='lat-button'>Syllabus</button>
         <button className='lat-button'>Previous Year</button>
         <button className='lat-button'>Videos</button>
         <button className='lat-button'>Exam Pattern</button>
         </Link>
      </div>
    <div className='div1'>
      <Link  to="/SSC-Sub-Inspector-CPO-SI-Online-Form-2024">
         <h3>{jobList[106]?.name} {jobList[106]?.date}</h3>
         <button className='lat-button'>Test Series</button>
         <button className='lat-button'>Syllabus</button>
         <button className='lat-button'>Previous Year</button>
         <button className='lat-button'>Videos</button>
         <button className='lat-button'>Exam Pattern</button>
         </Link>
      </div>
    <div className='div1'>
      <Link  to="/Railway-RRB-Technician-Online-Form-2024">
         <h3>{jobList[105]?.name} {jobList[105]?.date}</h3>
         <button className='lat-button'>Test Series</button>
         <button className='lat-button'>Syllabus</button>
         <button className='lat-button'>Previous Year</button>
         <button className='lat-button'>Videos</button>
         <button className='lat-button'>Exam Pattern</button>
         </Link>
      </div>
    <div className='div1'>
      <Link  to="/CTET-July-2024-Online-Form">
         <h3>{jobList[104]?.name} {jobList[104]?.date}</h3>
         <button className='lat-button'>Test Series</button>
         <button className='lat-button'>Syllabus</button>
         <button className='lat-button'>Previous Year</button>
         <button className='lat-button'>Videos</button>
         <button className='lat-button'>Exam Pattern</button>
         </Link>
      </div>
    <div className='div1'>
      <Link  to="/BPSC-Head-Teacher-Online-Form">
         <h3>{jobList[103]?.name} {jobList[103]?.date}</h3>
         <button className='lat-button'>Test Series</button>
         <button className='lat-button'>Syllabus</button>
         <button className='lat-button'>Previous Year</button>
         <button className='lat-button'>Videos</button>
         <button className='lat-button'>Exam Pattern</button>
         </Link>
      </div>
    <div className='div1'>
      <Link  to="/Railway-RPF-Constable-/-Sub-Inspector-Online-Form-2024">
         <h3>{jobList[102]?.name} {jobList[102]?.date}</h3>
         <button className='lat-button'>Test Series</button>
         <button className='lat-button'>Syllabus</button>
         <button className='lat-button'>Previous Year</button>
         <button className='lat-button'>Videos</button>
         <button className='lat-button'>Exam Pattern</button>
         </Link>
      </div>
    <div className='div1'>
      <Link  to="/UPPSC-Staff-Nurse-Mains-Online-Form-2024">
         <h3>{jobList[101]?.name} {jobList[101]?.date}</h3>
         <button className='lat-button'>Test Series</button>
         <button className='lat-button'>Syllabus</button>
         <button className='lat-button'>Previous Year</button>
         <button className='lat-button'>Videos</button>
         <button className='lat-button'>Exam Pattern</button>
         </Link>
      </div>
    <div className='div1'>
      <Link  to="/RSMSSB-Stenographer-PA-Online-Form-2024">
         <h3>{jobList[100]?.name} {jobList[100]?.date}</h3>
         <button className='lat-button'>Test Series</button>
         <button className='lat-button'>Syllabus</button>
         <button className='lat-button'>Previous Year</button>
         <button className='lat-button'>Videos</button>
         <button className='lat-button'>Exam Pattern</button>
         </Link>
      </div>
    <div className='div1'>
      <Link  to="/BPSC-Block-Horticulture-Officer-BHO-Online-Form-2024">
         <h3>{jobList[99]?.name} {jobList[99]?.date}</h3>
         <button className='lat-button'>Test Series</button>
         <button className='lat-button'>Syllabus</button>
         <button className='lat-button'>Previous Year</button>
         <button className='lat-button'>Videos</button>
         <button className='lat-button'>Exam Pattern</button>
         </Link>
      </div>
    <div className='div1'>
      <Link  to="/Jharkhand-High-Court-Typist-and-Stenographer-Online-Form-2024">
         <h3>{jobList[98]?.name} {jobList[98]?.date}</h3>
         <button className='lat-button'>Test Series</button>
         <button className='lat-button'>Syllabus</button>
         <button className='lat-button'>Previous Year</button>
         <button className='lat-button'>Videos</button>
         <button className='lat-button'>Exam Pattern</button>
         </Link>
      </div>
    <div className='div1'>
      <Link  to="/BPSC-Assistant-Architect-Online-Form-2024">
         <h3>{jobList[97]?.name} {jobList[97]?.date}</h3>
         <button className='lat-button'>Test Series</button>
         <button className='lat-button'>Syllabus</button>
         <button className='lat-button'>Previous Year</button>
         <button className='lat-button'>Videos</button>
         <button className='lat-button'>Exam Pattern</button>
         </Link>
      </div>
    <div className='div1'>
      <Link  to="/Navy-SSC-Officers-January-2025-Batch-Online-Form">
         <h3>{jobList[96]?.name} {jobList[96]?.date}</h3>
         <button className='lat-button'>Test Series</button>
         <button className='lat-button'>Syllabus</button>
         <button className='lat-button'>Previous Year</button>
         <button className='lat-button'>Videos</button>
         <button className='lat-button'>Exam Pattern</button>
         </Link>
      </div>
    <div className='div1'>
      <Link  to="/RSMSSB-Hostel-Superintendent-Online-Form-2024">
         <h3>{jobList[95]?.name} {jobList[95]?.date}</h3>
         <button className='lat-button'>Test Series</button>
         <button className='lat-button'>Syllabus</button>
         <button className='lat-button'>Previous Year</button>
         <button className='lat-button'>Videos</button>
         <button className='lat-button'>Exam Pattern</button>
         </Link>
      </div>
    <div className='div1'>
      <Link  to="/RSMSSB-Junior-Assistant-Online-Form-2024">
         <h3>{jobList[94]?.name} {jobList[94]?.date}</h3>
         <button className='lat-button'>Test Series</button>
         <button className='lat-button'>Syllabus</button>
         <button className='lat-button'>Previous Year</button>
         <button className='lat-button'>Videos</button>
         <button className='lat-button'>Exam Pattern</button>
         </Link>
      </div>
    <div className='div1'>
      <Link  to="/SBI-Specialist-Officer-SO-Online-Form-2024">
         <h3>{jobList[93]?.name} {jobList[93]?.date}</h3>
         <button className='lat-button'>Test Series</button>
         <button className='lat-button'>Syllabus</button>
         <button className='lat-button'>Previous Year</button>
         <button className='lat-button'>Videos</button>
         <button className='lat-button'>Exam Pattern</button>
         </Link>
      </div>
    <div className='div1'>
      <Link  to="/UPSC-Civil-Services-IAS-/-IFS-Pre-Online-Form-2024">
         <h3>{jobList[92]?.name} {jobList[92]?.date}</h3>
         <button className='lat-button'>Test Series</button>
         <button className='lat-button'>Syllabus</button>
         <button className='lat-button'>Previous Year</button>
         <button className='lat-button'>Videos</button>
         <button className='lat-button'>Exam Pattern</button>
         </Link>
      </div>
    <div className='div1'>
      <Link  to="/Haryana-Police-Constable-Online-Form-2024">
         <h3>{jobList[91]?.name} {jobList[91]?.date}</h3>
         <button className='lat-button'>Test Series</button>
         <button className='lat-button'>Syllabus</button>
         <button className='lat-button'>Previous Year</button>
         <button className='lat-button'>Videos</button>
         <button className='lat-button'>Exam Pattern</button>
         </Link>
      </div>
    <div className='div1'>
      <Link  to="/Army-Agniveer-CEE-Online-Form-2024">
         <h3>{jobList[90]?.name} {jobList[90]?.date}</h3>
         <button className='lat-button'>Test Series</button>
         <button className='lat-button'>Syllabus</button>
         <button className='lat-button'>Previous Year</button>
         <button className='lat-button'>Videos</button>
         <button className='lat-button'>Exam Pattern</button>
         </Link>
      </div>
    <div className='div1'>
      <Link  to="/BPSC-School-Teacher-TRE-3.0-Online-Form-2024">
         <h3>{jobList[89]?.name} {jobList[89]?.date}</h3>
         <button className='lat-button'>Test Series</button>
         <button className='lat-button'>Syllabus</button>
         <button className='lat-button'>Previous Year</button>
         <button className='lat-button'>Videos</button>
         <button className='lat-button'>Exam Pattern</button>
         </Link>
      </div>
    <div className='div1'>
      <Link  to="/BPSC-School-Teacher-TRE-3.0-Online-Form-2024">
         <h3>{jobList[88]?.name} {jobList[88]?.date}</h3>
         <button className='lat-button'>Test Series</button>
         <button className='lat-button'>Syllabus</button>
         <button className='lat-button'>Previous Year</button>
         <button className='lat-button'>Videos</button>
         <button className='lat-button'>Exam Pattern</button>
         </Link>
      </div>
    <div className='div1'>
      <Link  to="/PNB-SO-Online-Form-2024">
         <h3>{jobList[87]?.name} {jobList[87]?.date}</h3>
         <button className='lat-button'>Test Series</button>
         <button className='lat-button'>Syllabus</button>
         <button className='lat-button'>Previous Year</button>
         <button className='lat-button'>Videos</button>
         <button className='lat-button'>Exam Pattern</button>
         </Link>
      </div>
    <div className='div1'>
      <Link  to="/Coast-Guard-Assistant-Commandant-Online-Form-2024">
         <h3>{jobList[86]?.name} {jobList[86]?.date}</h3>
         <button className='lat-button'>Test Series</button>
         <button className='lat-button'>Syllabus</button>
         <button className='lat-button'>Previous Year</button>
         <button className='lat-button'>Videos</button>
         <button className='lat-button'>Exam Pattern</button>
         </Link>
      </div>
    <div className='div1'>
      <Link  to="/Coast-Guard-Navik-GD-02/2024-Online-Form">
         <h3>{jobList[85]?.name} {jobList[85]?.date}</h3>
         <button className='lat-button'>Test Series</button>
         <button className='lat-button'>Syllabus</button>
         <button className='lat-button'>Previous Year</button>
         <button className='lat-button'>Videos</button>
         <button className='lat-button'>Exam Pattern</button>
         </Link>
      </div>
    <div className='div1'>
      <Link  to="/DSSSB-Various-12785-Post-Online-Form-2024">
         <h3>{jobList[84]?.name} {jobList[84]?.date}</h3>
         <button className='lat-button'>Test Series</button>
         <button className='lat-button'>Syllabus</button>
         <button className='lat-button'>Previous Year</button>
         <button className='lat-button'>Videos</button>
         <button className='lat-button'>Exam Pattern</button>
         </Link>
      </div>
    <div className='div1'>
      <Link  to="/SSC-Selection-Post-XII-Online-Form">
         <h3>{jobList[83]?.name} {jobList[83]?.date}</h3>
         <button className='lat-button'>Test Series</button>
         <button className='lat-button'>Syllabus</button>
         <button className='lat-button'>Previous Year</button>
         <button className='lat-button'>Videos</button>
         <button className='lat-button'>Exam Pattern</button>
         </Link>
      </div>
    <div className='div1'>
      <Link  to="/Bihar-Sakshamta-Pariksha-Online-Form-2024">
         <h3>{jobList[82]?.name} {jobList[82]?.date}</h3>
         <button className='lat-button'>Test Series</button>
         <button className='lat-button'>Syllabus</button>
         <button className='lat-button'>Previous Year</button>
         <button className='lat-button'>Videos</button>
         <button className='lat-button'>Exam Pattern</button>
         </Link>
      </div>
    <div className='div1'>
      <Link  to="/RPSC-Senior-Teacher-TGT-Online-Form-2024">
         <h3>{jobList[81]?.name} {jobList[81]?.date}</h3>
         <button className='lat-button'>Test Series</button>
         <button className='lat-button'>Syllabus</button>
         <button className='lat-button'>Previous Year</button>
         <button className='lat-button'>Videos</button>
         <button className='lat-button'>Exam Pattern</button>
         </Link>
      </div>
    <div className='div1'>
      <Link  to="/Railway-RRB-Technician-CEN-Online-Form">
         <h3>{jobList[80]?.name} {jobList[80]?.date}</h3>
         <button className='lat-button'>Test Series</button>
         <button className='lat-button'>Syllabus</button>
         <button className='lat-button'>Previous Year</button>
         <button className='lat-button'>Videos</button>
         <button className='lat-button'>Exam Pattern</button>
         </Link>
      </div>
    <div className='div1'>
      <Link  to="/UKPSC-Uttarakhand-Police-Sub-Inspector-Online-Form-2024">
         <h3>{jobList[79]?.name} {jobList[79]?.date}</h3>
         <button className='lat-button'>Test Series</button>
         <button className='lat-button'>Syllabus</button>
         <button className='lat-button'>Previous Year</button>
         <button className='lat-button'>Videos</button>
         <button className='lat-button'>Exam Pattern</button>
         </Link>
      </div>
    <div className='div1'>
      <Link  to="/JPSC-Pre-Online-Form-2024">
         <h3>{jobList[78]?.name} {jobList[78]?.date}</h3>
         <button className='lat-button'>Test Series</button>
         <button className='lat-button'>Syllabus</button>
         <button className='lat-button'>Previous Year</button>
         <button className='lat-button'>Videos</button>
         <button className='lat-button'>Exam Pattern</button>
         </Link>
      </div>
    <div className='div1'>
      <Link  to="/Supreme-Court-of-India-SCI-Law-Clerk-Online-Form-2024">
         <h3>{jobList[77]?.name} {jobList[77]?.date}</h3>
         <button className='lat-button'>Test Series</button>
         <button className='lat-button'>Syllabus</button>
         <button className='lat-button'>Previous Year</button>
         <button className='lat-button'>Videos</button>
         <button className='lat-button'>Exam Pattern</button>
         </Link>
      </div>
    <div className='div1'>
      <Link  to="/Southern-Railway-Apprentices-Online-Form-2024">
         <h3>{jobList[75]?.name} {jobList[75]?.date}</h3>
         <button className='lat-button'>Test Series</button>
         <button className='lat-button'>Syllabus</button>
         <button className='lat-button'>Previous Year</button>
         <button className='lat-button'>Videos</button>
         <button className='lat-button'>Exam Pattern</button>
         </Link>
      </div>
    <div className='div1'>
      <Link  to="/New-India-Assurance-NIACL-Assistant-Online-Form-2024">
         <h3>{jobList[75]?.name} {jobList[75]?.date}</h3>
         <button className='lat-button'>Test Series</button>
         <button className='lat-button'>Syllabus</button>
         <button className='lat-button'>Previous Year</button>
         <button className='lat-button'>Videos</button>
         <button className='lat-button'>Exam Pattern</button>
         </Link>
      </div>
    <div className='div1'>
      <Link  to="/Railway-RRB-Assistant-Loco-Pilot-ALP-Online-Form-2024">
         <h3>{jobList[74]?.name} {jobList[74]?.date}</h3>
         <button className='lat-button'>Test Series</button>
         <button className='lat-button'>Syllabus</button>
         <button className='lat-button'>Previous Year</button>
         <button className='lat-button'>Videos</button>
         <button className='lat-button'>Exam Pattern</button>
         </Link>
      </div>
    <div className='div1'>
      <Link  to="/Delhi-Home-Guard-Recruitment-2024">
         <h3>{jobList[73]?.name} {jobList[73]?.date}</h3>
         <button className='lat-button'>Test Series</button>
         <button className='lat-button'>Syllabus</button>
         <button className='lat-button'>Previous Year</button>
         <button className='lat-button'>Videos</button>
         <button className='lat-button'>Exam Pattern</button>
         </Link>
      </div>
    <div className='div1'>
      <Link  to="/RRB-ALP-Recruitment-2024">
         <h3>{jobList[72]?.name} {jobList[72]?.date}</h3>
         <button className='lat-button'>Test Series</button>
         <button className='lat-button'>Syllabus</button>
         <button className='lat-button'>Previous Year</button>
         <button className='lat-button'>Videos</button>
         <button className='lat-button'>Exam Pattern</button>
         </Link>
      </div>
    <div className='div1'>
      <Link  to="/BPSC-Agriculture-Various-Post-Online-Form-2024">
         <h3>{jobList[71]?.name} {jobList[71]?.date}</h3>
         <button className='lat-button'>Test Series</button>
         <button className='lat-button'>Syllabus</button>
         <button className='lat-button'>Previous Year</button>
         <button className='lat-button'>Videos</button>
         <button className='lat-button'>Exam Pattern</button>
         </Link>
      </div>
    <div className='div1'>
      <Link  to="/NCL-CIL-Assistant-Foreman-Online-Form-2024">
         <h3>{jobList[70]?.name} {jobList[70]?.date}</h3>
         <button className='lat-button'>Test Series</button>
         <button className='lat-button'>Syllabus</button>
         <button className='lat-button'>Previous Year</button>
         <button className='lat-button'>Videos</button>
         <button className='lat-button'>Exam Pattern</button>
         </Link>
      </div>
    <div className='div1'>
      <Link  to="/DSSSB-SPA,-PA,-JJA-Online-Form-2024">
         <h3>{jobList[69]?.name} {jobList[69]?.date}</h3>
         <button className='lat-button'>Test Series</button>
         <button className='lat-button'>Syllabus</button>
         <button className='lat-button'>Previous Year</button>
         <button className='lat-button'>Videos</button>
         <button className='lat-button'>Exam Pattern</button>
         </Link>
      </div>
    <div className='div1'>
      <Link  to="/CSIR-CBRI -echnical-Assistant-Online-Form-2024">
         <h3>{jobList[68]?.name} {jobList[68]?.date}</h3>
         <button className='lat-button'>Test Series</button>
         <button className='lat-button'>Syllabus</button>
         <button className='lat-button'>Previous Year</button>
         <button className='lat-button'>Videos</button>
         <button className='lat-button'>Exam Pattern</button>
         </Link>
      </div>
    <div className='div1'>
      <Link  to="/RPSC-Assistant-Professor-Online-Form-2024">
         <h3>{jobList[67]?.name} {jobList[67]?.date}</h3>
         <button className='lat-button'>Test Series</button>
         <button className='lat-button'>Syllabus</button>
         <button className='lat-button'>Previous Year</button>
         <button className='lat-button'>Videos</button>
         <button className='lat-button'>Exam Pattern</button>
         </Link>
      </div>
    <div className='div1'>
      <Link  to="/NWR-RRC-Jaipur-Apprentices-Online-Form-2024">
         <h3>{jobList[66]?.name} {jobList[66]?.date}</h3>
         <button className='lat-button'>Test Series</button>
         <button className='lat-button'>Syllabus</button>
         <button className='lat-button'>Previous Year</button>
         <button className='lat-button'>Videos</button>
         <button className='lat-button'>Exam Pattern</button>
         </Link>
      </div>
    <div className='div1'>
      <Link  to="/UIIC-Administrative-Officer-Scale-I-Online-Form-2024">
         <h3>{jobList[65]?.name} {jobList[65]?.date}</h3>
         <button className='lat-button'>Test Series</button>
         <button className='lat-button'>Syllabus</button>
         <button className='lat-button'>Previous Year</button>
         <button className='lat-button'>Videos</button>
         <button className='lat-button'>Exam Pattern</button>
         </Link>
      </div>
    <div className='div1'>
      <Link  to="/RPSC-Vidhi-Rachnakar-Online-Form">
         <h3>{jobList[64]?.name} {jobList[64]?.date}</h3>
         <button className='lat-button'>Test Series</button>
         <button className='lat-button'>Syllabus</button>
         <button className='lat-button'>Previous Year</button>
         <button className='lat-button'>Videos</button>
         <button className='lat-button'>Exam Pattern</button>
         </Link>
      </div>
    <div className='div1'>
      <Link  to="/Navy-B.Tech-Entry-July-2024-Online-Form">
         <h3>{jobList[63]?.name} {jobList[63]?.date}</h3>
         <button className='lat-button'>Test Series</button>
         <button className='lat-button'>Syllabus</button>
         <button className='lat-button'>Previous Year</button>
         <button className='lat-button'>Videos</button>
         <button className='lat-button'>Exam Pattern</button>
         </Link>
      </div>
    <div className='div1'>
      <Link  to="/Indian-Airforce-Intake-01/2025-Online-Form">
         <h3>{jobList[62]?.name} {jobList[62]?.date}</h3>
         <button className='lat-button'>Test Series</button>
         <button className='lat-button'>Syllabus</button>
         <button className='lat-button'>Previous Year</button>
         <button className='lat-button'>Videos</button>
         <button className='lat-button'>Exam Pattern</button>
         </Link>
      </div>
    <div className='div1'>
      <Link  to="/UPPSC-Pre-2024-Online-Form">
         <h3>{jobList[61]?.name} {jobList[61]?.date}</h3>
         <button className='lat-button'>Test Series</button>
         <button className='lat-button'>Syllabus</button>
         <button className='lat-button'>Previous Year</button>
         <button className='lat-button'>Videos</button>
         <button className='lat-button'>Exam Pattern</button>
         </Link>
      </div>
    <div className='div1'>
      <Link  to="/BHU-Nursing-Officer,-Other-Post-Online-Form-2024   ">
         <h3>{jobList[60]?.name} {jobList[60]?.date}</h3>
         <button className='lat-button'>Test Series</button>
         <button className='lat-button'>Syllabus</button>
         <button className='lat-button'>Previous Year</button>
         <button className='lat-button'>Videos</button>
         <button className='lat-button'>Exam Pattern</button>
         </Link>
      </div>
    <div className='div1'>
      <Link  to="/BHU-Nursing-Officer,-Other-Post-Online-Form-2024   ">
         <h3>{jobList[59]?.name} {jobList[59]?.date}</h3>
         <button className='lat-button'>Test Series</button>
         <button className='lat-button'>Syllabus</button>
         <button className='lat-button'>Previous Year</button>
         <button className='lat-button'>Videos</button>
         <button className='lat-button'>Exam Pattern</button>
         </Link>
      </div>
    <div className='div1'>
      <Link  to="/Bihar-Vidhan-Sabha-Sachivalaya-Various-Post-Online-Form-2024">
         <h3>{jobList[58]?.name} {jobList[58]?.date}</h3>
         <button className='lat-button'>Test Series</button>
         <button className='lat-button'>Syllabus</button>
         <button className='lat-button'>Previous Year</button>
         <button className='lat-button'>Videos</button>
         <button className='lat-button'>Exam Pattern</button>
         </Link>
      </div>
    <div className='div1'>
      <Link  to="/UP-Police-SI,-ASI-Online-Form-2024">
         <h3>{jobList[57]?.name} {jobList[57]?.date}</h3>
         <button className='lat-button'>Test Series</button>
         <button className='lat-button'>Syllabus</button>
         <button className='lat-button'>Previous Year</button>
         <button className='lat-button'>Videos</button>
         <button className='lat-button'>Exam Pattern</button>
         </Link>
      </div>
    <div className='div1'>
      <Link  to="/BHU-Nursing-Officer,-Other-Post-Online-Form-2024   ">
         <h3>{jobList[56]?.name} {jobList[56]?.date}</h3>
         <button className='lat-button'>Test Series</button>
         <button className='lat-button'>Syllabus</button>
         <button className='lat-button'>Previous Year</button>
         <button className='lat-button'>Videos</button>
         <button className='lat-button'>Exam Pattern</button>
         </Link>
      </div>
    <div className='div1'>
      <Link  to="/Delhi-DSSSB-PGT-Teacher-Online-Form-2024">
         <h3>{jobList[55]?.name} {jobList[55]?.date}</h3>
         <button className='lat-button'>Test Series</button>
         <button className='lat-button'>Syllabus</button>
         <button className='lat-button'>Previous Year</button>
         <button className='lat-button'>Videos</button>
         <button className='lat-button'>Exam Pattern</button>
         </Link>
      </div>
    <div className='div1'>
      <Link  to="/Allahabad-University-Assistant-Professor-Online-Form-2023">
         <h3>{jobList[54]?.name} {jobList[54]?.date}</h3>
         <button className='lat-button'>Test Series</button>
         <button className='lat-button'>Syllabus</button>
         <button className='lat-button'>Previous Year</button>
         <button className='lat-button'>Videos</button>
         <button className='lat-button'>Exam Pattern</button>
         </Link>
      </div>
    <div className='div1'>
      <Link  to="/DSSSB-LDC,-Stenographer,-Junior-Assistant,-Other-Post-Online-Form">
         <h3>{jobList[53]?.name} {jobList[53]?.date}</h3>
         <button className='lat-button'>Test Series</button>
         <button className='lat-button'>Syllabus</button>
         <button className='lat-button'>Previous Year</button>
         <button className='lat-button'>Videos</button>
         <button className='lat-button'>Exam Pattern</button>
         </Link>
      </div>
    <div className='div1'>
      <Link  to="/Delhi-DSSSB-Assistant-Teacher-Nursery-Online-Form-2024">
         <h3>{jobList[52]?.name} {jobList[52]?.date}</h3>
         <button className='lat-button'>Test Series</button>
         <button className='lat-button'>Syllabus</button>
         <button className='lat-button'>Previous Year</button>
         <button className='lat-button'>Videos</button>
         <button className='lat-button'>Exam Pattern</button>
         </Link>
      </div>
    <div className='div1'>
      <Link  to="/UP-Police-Constable-60244-Post-Online-Form-2023">
         <h3>{jobList[51]?.name} {jobList[51]?.date}</h3>
         <button className='lat-button'>Test Series</button>
         <button className='lat-button'>Syllabus</button>
         <button className='lat-button'>Previous Year</button>
         <button className='lat-button'>Videos</button>
         <button className='lat-button'>Exam Pattern</button>
         </Link>
      </div>
    <div className='div1'>
      <Link  to="/General-Insurance-GIC-Scale-I-Online-Form-2023">
         <h3>{jobList[50]?.name} {jobList[50]?.date}</h3>
         <button className='lat-button'>Test Series</button>
         <button className='lat-button'>Syllabus</button>
         <button className='lat-button'>Previous Year</button>
         <button className='lat-button'>Videos</button>
         <button className='lat-button'>Exam Pattern</button>
         </Link>
      </div>
    <div className='div1'>
      <Link  to="/Jobid658717b57b2c7cdff234d192">
         <h3>{jobList[49]?.name} {jobList[49]?.date}</h3>
         <button className='lat-button'>Test Series</button>
         <button className='lat-button'>Syllabus</button>
         <button className='lat-button'>Previous Year</button>
         <button className='lat-button'>Videos</button>
         <button className='lat-button'>Exam Pattern</button>
         </Link>
      </div>
    <div className='div1'>
      <Link  to="/Jobid658715f87b2c7cdff234d190">
         <h3>{jobList[48]?.name} {jobList[48]?.date}</h3>
         <button className='lat-button'>Test Series</button>
         <button className='lat-button'>Syllabus</button>
         <button className='lat-button'>Previous Year</button>
         <button className='lat-button'>Videos</button>
         <button className='lat-button'>Exam Pattern</button>
         </Link>
      </div>
    <div className='div1'>
      <Link  to="/Jobid65846d2f351fbc71550575b7">
         <h3>{jobList[47]?.name} {jobList[47]?.date}</h3>
         <button className='lat-button'>Test Series</button>
         <button className='lat-button'>Syllabus</button>
         <button className='lat-button'>Previous Year</button>
         <button className='lat-button'>Videos</button>
         <button className='lat-button'>Exam Pattern</button>
         </Link>
      </div>
    <div className='div1'>
      <Link  to="/Jobid65846baf351fbc715505759d">
         <h3>{jobList[46]?.name} {jobList[46]?.date}</h3>
         <button className='lat-button'>Test Series</button>
         <button className='lat-button'>Syllabus</button>
         <button className='lat-button'>Previous Year</button>
         <button className='lat-button'>Videos</button>
         <button className='lat-button'>Exam Pattern</button>
         </Link>
      </div>
    <div className='div1'>
      <Link  to="/Jobid658469fb351fbc7155057555">
         <h3>{jobList[45]?.name} {jobList[45]?.date}</h3>
         <button className='lat-button'>Test Series</button>
         <button className='lat-button'>Syllabus</button>
         <button className='lat-button'>Previous Year</button>
         <button className='lat-button'>Videos</button>
         <button className='lat-button'>Exam Pattern</button>
         </Link>
      </div>
    <div className='div1'>
      <Link  to="/Jobid658468b5351fbc7155057505">
         <h3>{jobList[44]?.name} {jobList[44]?.date}</h3>
         <button className='lat-button'>Test Series</button>
         <button className='lat-button'>Syllabus</button>
         <button className='lat-button'>Previous Year</button>
         <button className='lat-button'>Videos</button>
         <button className='lat-button'>Exam Pattern</button>
         </Link>
      </div>
    <div className='div1'>
      <Link  to="/Jobid65830d78f531b4b750ae32ee">
         <h3>{jobList[43]?.name} {jobList[43]?.date}</h3>
         <button className='lat-button'>Test Series</button>
         <button className='lat-button'>Syllabus</button>
         <button className='lat-button'>Previous Year</button>
         <button className='lat-button'>Videos</button>
         <button className='lat-button'>Exam Pattern</button>
         </Link>
      </div>
    <div className='div1'>
      <Link  to="/Jobid65830574f531b4b750ae32ec">
         <h3>{jobList[42]?.name} {jobList[42]?.date}</h3>
         <button className='lat-button'>Test Series</button>
         <button className='lat-button'>Syllabus</button>
         <button className='lat-button'>Previous Year</button>
         <button className='lat-button'>Videos</button>
         <button className='lat-button'>Exam Pattern</button>
         </Link>
      </div>
    <div className='div1'>
      <Link  to="/Jobid65814a7e80e67a73f78e3a19">
         <h3>{jobList[41]?.name} {jobList[41]?.date}</h3>
         <button className='lat-button'>Test Series</button>
         <button className='lat-button'>Syllabus</button>
         <button className='lat-button'>Previous Year</button>
         <button className='lat-button'>Videos</button>
         <button className='lat-button'>Exam Pattern</button>
         </Link>
      </div>
    <div className='div1'>
      <Link  to="/Jobid657e7a14fd43e58859f6a32d">
         <h3>{jobList[40]?.name} {jobList[40]?.date}</h3>
         <button className='lat-button'>Test Series</button>
         <button className='lat-button'>Syllabus</button>
         <button className='lat-button'>Previous Year</button>
         <button className='lat-button'>Videos</button>
         <button className='lat-button'>Exam Pattern</button>
         </Link>
      </div>
    <div className='div1'>
      <Link  to="/Jobid657e7a14fd43e58859f6a32d">
         <h3>{jobList[39]?.name} {jobList[39]?.date}</h3>
         <button className='lat-button'>Test Series</button>
         <button className='lat-button'>Syllabus</button>
         <button className='lat-button'>Previous Year</button>
         <button className='lat-button'>Videos</button>
         <button className='lat-button'>Exam Pattern</button>
         </Link>
      </div>
    <div className='div1'>
      <Link  to="/Jobid657c814291e3a09a6b3b1a3d">
         <h3>{jobList[38]?.name} {jobList[38]?.date}</h3>
         <button className='lat-button'>Test Series</button>
         <button className='lat-button'>Syllabus</button>
         <button className='lat-button'>Previous Year</button>
         <button className='lat-button'>Videos</button>
         <button className='lat-button'>Exam Pattern</button>
         </Link>
      </div>
    <div className='div1'>
      <Link  to="/Jobid657b416782fbf0c0e7cb19f2">
         <h3>{jobList[37]?.name} {jobList[37]?.date}</h3>
         <button className='lat-button'>Test Series</button>
         <button className='lat-button'>Syllabus</button>
         <button className='lat-button'>Previous Year</button>
         <button className='lat-button'>Videos</button>
         <button className='lat-button'>Exam Pattern</button>
         </Link>
      </div>
    <div className='div1'>
      <Link  to="/Jobid657b3fba82fbf0c0e7cb19d4">
         <h3>{jobList[36]?.name} {jobList[36]?.date}</h3>
         <button className='lat-button'>Test Series</button>
         <button className='lat-button'>Syllabus</button>
         <button className='lat-button'>Previous Year</button>
         <button className='lat-button'>Videos</button>
         <button className='lat-button'>Exam Pattern</button>
         </Link>
      </div>
    <div className='div1'>
      <Link  to="/Jobid657b3cfd82fbf0c0e7cb19ba">
         <h3>{jobList[35]?.name} {jobList[35]?.date}</h3>
         <button className='lat-button'>Test Series</button>
         <button className='lat-button'>Syllabus</button>
         <button className='lat-button'>Previous Year</button>
         <button className='lat-button'>Videos</button>
         <button className='lat-button'>Exam Pattern</button>
         </Link>
      </div>
    <div className='div1'>
      <Link  to="/Jobid6577df0c47c06059e60b050f">
         <h3>{jobList[34]?.name} {jobList[34]?.date}</h3>
         <button className='lat-button'>Test Series</button>
         <button className='lat-button'>Syllabus</button>
         <button className='lat-button'>Previous Year</button>
         <button className='lat-button'>Videos</button>
         <button className='lat-button'>Exam Pattern</button>
         </Link>
      </div>
    <div className='div1'>
      <Link  to="/Jobid6577df0c47c06059e60b050f">
         <h3>{jobList[33]?.name} {jobList[33]?.date}</h3>
         <button className='lat-button'>Test Series</button>
         <button className='lat-button'>Syllabus</button>
         <button className='lat-button'>Previous Year</button>
         <button className='lat-button'>Videos</button>
         <button className='lat-button'>Exam Pattern</button>
         </Link>
      </div>
    <div className='div1'>
      <Link  to="/Jobid6577df0c47c06059e60b050f">
         <h3>{jobList[32]?.name} {jobList[32]?.date}</h3>
         <button className='lat-button'>Test Series</button>
         <button className='lat-button'>Syllabus</button>
         <button className='lat-button'>Previous Year</button>
         <button className='lat-button'>Videos</button>
         <button className='lat-button'>Exam Pattern</button>
         </Link>
      </div>
    <div className='div1'>
      <Link  to="/Jobid65770a7a47c06059e60b03b7">
         <h3>{jobList[31]?.name} {jobList[31]?.date}</h3>
         <button className='lat-button'>Test Series</button>
         <button className='lat-button'>Syllabus</button>
         <button className='lat-button'>Previous Year</button>
         <button className='lat-button'>Videos</button>
         <button className='lat-button'>Exam Pattern</button>
         </Link>
      </div>
    <div className='div1'>
      <Link  to="/Jobid6577c85b47c06059e60b0481">
         <h3>{jobList[30]?.name} {jobList[30]?.date}</h3>
         <button className='lat-button'>Test Series</button>
         <button className='lat-button'>Syllabus</button>
         <button className='lat-button'>Previous Year</button>
         <button className='lat-button'>Videos</button>
         <button className='lat-button'>Exam Pattern</button>
         </Link>
      </div>
    <div className='div1'>
      <Link  to="/NTPC-Various-Post-Recruitment-2023">
         <h3>{jobList[29]?.name} {jobList[29]?.date}</h3>
         <button className='lat-button'>Test Series</button>
         <button className='lat-button'>Syllabus</button>
         <button className='lat-button'>Previous Year</button>
         <button className='lat-button'>Videos</button>
         <button className='lat-button'>Exam Pattern</button>
         </Link>
      </div>
    <div className='div1'>
      <Link  to="/Atomic-Energy-DPSDAE-JPA-/-JSK-Online-Form-2023">
         <h3>{jobList[28]?.name} {jobList[28]?.date}</h3>
         <button className='lat-button'>Test Series</button>
         <button className='lat-button'>Syllabus</button>
         <button className='lat-button'>Previous Year</button>
         <button className='lat-button'>Videos</button>
         <button className='lat-button'>Exam Pattern</button>
         </Link>
      </div>
    <div className='div1'>
      <Link  to="/CSIR-CASE-Section-Officer-SO-/-Assistant-Section-Officer-Exam-2023">
         <h3>{jobList[27]?.name} {jobList[27]?.date}</h3>
         <button className='lat-button'>Test Series</button>
         <button className='lat-button'>Syllabus</button>
         <button className='lat-button'>Previous Year</button>
         <button className='lat-button'>Videos</button>
         <button className='lat-button'>Exam Pattern</button>
         </Link>
      </div>
    <div className='div1'>
      <Link  to="/ISRO-NRSC-Technician-B-Recruitment-2023">
         <h3>{jobList[26]?.name} {jobList[26]?.date}</h3>
         <button className='lat-button'>Test Series</button>
         <button className='lat-button'>Syllabus</button>
         <button className='lat-button'>Previous Year</button>
         <button className='lat-button'>Videos</button>
         <button className='lat-button'>Exam Pattern</button>
         </Link>
      </div>
    <div className='div1'>
      <Link  to="/Bank-of-Baroda-recruitment-2023">
         <h3>{jobList[25]?.name} {jobList[25]?.date}</h3>
         <button className='lat-button'>Test Series</button>
         <button className='lat-button'>Syllabus</button>
         <button className='lat-button'>Previous Year</button>
         <button className='lat-button'>Videos</button>
         <button className='lat-button'>Exam Pattern</button>
         </Link>
      </div>
    <div className='div1'>
      <Link  to="/Staff-Nurse-Unani-(Male/Female)-Examination-2023">
         <h3>{jobList[24]?.name} {jobList[24]?.date}</h3>
         <button className='lat-button'>Test Series</button>
         <button className='lat-button'>Syllabus</button>
         <button className='lat-button'>Previous Year</button>
         <button className='lat-button'>Videos</button>
         <button className='lat-button'>Exam Pattern</button>
         </Link>
      </div>
    <div className='div1'>
      <Link  to="/NTA-Military-Nursing-Service-2023">
         <h3>{jobList[23]?.name} {jobList[23]?.date}</h3>
         <button className='lat-button'>Test Series</button>
         <button className='lat-button'>Syllabus</button>
         <button className='lat-button'>Previous Year</button>
         <button className='lat-button'>Videos</button>
         <button className='lat-button'>Exam Pattern</button>
         </Link>
      </div>
    <div className='div1'>
      <Link  to="/RRC-NR-Delhi -apprentice-Recruitment-2023">
         <h3>{jobList[22]?.name} {jobList[22]?.date}</h3>
         <button className='lat-button'>Test Series</button>
         <button className='lat-button'>Syllabus</button>
         <button className='lat-button'>Previous Year</button>
         <button className='lat-button'>Videos</button>
         <button className='lat-button'>Exam Pattern</button>
         </Link>
      </div>
    <div className='div1'>
      <Link  to="/IDBI-Bank-Limited-SO-Recruitment-2023">
         <h3>{jobList[21]?.name} {jobList[21]?.date}</h3>
         <button className='lat-button'>Test Series</button>
         <button className='lat-button'>Syllabus</button>
         <button className='lat-button'>Previous Year</button>
         <button className='lat-button'>Videos</button>
         <button className='lat-button'>Exam Pattern</button>
         </Link>
      </div>
    <div className='div1'>
      <Link  to="/Requirement-to-the-posts-of-Stenographer-(Grade-3),-Junior-Clerk-and-Peon/Hamal-notification-2023">
         <h3>{jobList[20]?.name} {jobList[20]?.date}</h3>
         <button className='lat-button'>Test Series</button>
         <button className='lat-button'>Syllabus</button>
         <button className='lat-button'>Previous Year</button>
         <button className='lat-button'>Videos</button>
         <button className='lat-button'>Exam Pattern</button>
         </Link>
      </div>
    <div className='div1'>
      <Link  to="/HARYANA-PUBLIC-SERVICE-COMMISSTION-2023">
         <h3>{jobList[19]?.name} {jobList[19]?.date}</h3>
         <button className='lat-button'>Test Series</button>
         <button className='lat-button'>Syllabus</button>
         <button className='lat-button'>Previous Year</button>
         <button className='lat-button'>Videos</button>
         <button className='lat-button'>Exam Pattern</button>
         </Link>
      </div>
    <div className='div1'>
      <Link  to="/BSTET-Bihar-Special-State-Eligibility-Test-2023-Apply-Online">
         <h3>{jobList[18]?.name} {jobList[18]?.date}</h3>
         <button className='lat-button'>Test Series</button>
         <button className='lat-button'>Syllabus</button>
         <button className='lat-button'>Previous Year</button>
         <button className='lat-button'>Videos</button>
         <button className='lat-button'>Exam Pattern</button>
         </Link>
      </div>
    <div className='div1'>
      <Link  to="/airforce-common-admission-test-(AFCAT)-01/2024">
         <h3>{jobList[17]?.name} {jobList[17]?.date}</h3>
         <button className='lat-button'>Test Series</button>
         <button className='lat-button'>Syllabus</button>
         <button className='lat-button'>Previous Year</button>
         <button className='lat-button'>Videos</button>
         <button className='lat-button'>Exam Pattern</button>
         </Link>
      </div>
      <div className='div1'>
      <Link  to="/RRC-NER-Apprentice-2022-2023">
         <h3>{jobList[16]?.name} {jobList[16]?.date}</h3>
         <button className='lat-button'>Test Series</button>
         <button className='lat-button'>Syllabus</button>
         <button className='lat-button'>Previous Year</button>
         <button className='lat-button'>Videos</button>
         <button className='lat-button'>Exam Pattern</button>
         </Link>
      </div>
    <div className='div1'>
      <Link  to="/RRC-SER-Apprentice-form-2023">
         <h3>{jobList[15]?.name} {jobList[15]?.date}</h3>
         <button className='lat-button'>Test Series</button>
         <button className='lat-button'>Syllabus</button>
         <button className='lat-button'>Previous Year</button>
         <button className='lat-button'>Videos</button>
         <button className='lat-button'>Exam Pattern</button>
         </Link>
      </div>
    <div className='div1'>
      <Link  to="/(NIOS)-Group-A-B,-C -Vacancy-2023">
         <h3>{jobList[14]?.name} {jobList[14]?.date}</h3>
         <button className='lat-button'>Test Series</button>
         <button className='lat-button'>Syllabus</button>
         <button className='lat-button'>Previous Year</button>
         <button className='lat-button'>Videos</button>
         <button className='lat-button'>Exam Pattern</button>
         </Link>
      </div>
    <div className='div1'>
      <Link  to="/Jobid65656cd87d2f49298c723aac">
         <h3>{jobList[13]?.name} {jobList[13]?.date}</h3>
         <button className='lat-button'>Test Series</button>
         <button className='lat-button'>Syllabus</button>
         <button className='lat-button'>Previous Year</button>
         <button className='lat-button'>Videos</button>
         <button className='lat-button'>Exam Pattern</button>
         </Link>
      </div>
    <div className='div1'>
      <Link  to="/CGPSC-State-service-exam-2023">
         <h3>{jobList[12]?.name} {jobList[12]?.date}</h3>
         <button className='lat-button'>Test Series</button>
         <button className='lat-button'>Syllabus</button>
         <button className='lat-button'>Previous Year</button>
         <button className='lat-button'>Videos</button>
         <button className='lat-button'>Exam Pattern</button>
         </Link>
      </div>
    <div className='div1'>
      <Link  to="/airforce-common-admission-test-(AFCAT)-01/2024">
         <h3>{jobList[11]?.name} {jobList[11]?.date}</h3>
         <button className='lat-button'>Test Series</button>
         <button className='lat-button'>Syllabus</button>
         <button className='lat-button'>Previous Year</button>
         <button className='lat-button'>Videos</button>
         <button className='lat-button'>Exam Pattern</button>
         </Link>
      </div>
    <div className='div1'>
      <Link  to="/Constable-(GD)-in-Central-Armed-Police-Forces (CAPFs),-SSF-,-Rifleman-(GD)-in-Assam-Rifles-and-Sepoy-in-Narcotics-Control-Bureau-Examination,-2024">
         <h3>{jobList[9]?.name} {jobList[9]?.date}</h3>
         <button className='lat-button'>Test Series</button>
         <button className='lat-button'>Syllabus</button>
         <button className='lat-button'>Previous Year</button>
         <button className='lat-button'>Videos</button>
         <button className='lat-button'>Exam Pattern</button>
         </Link>
      </div>
    <div className='div1'>
      <Link  to="/Intelligence-Bureau-ACIO-Grade-II-/-Executive-Online-Form-2023">
         <h3>{jobList[8]?.name} {jobList[8]?.date}</h3>
         <button className='lat-button'>Test Series</button>
         <button className='lat-button'>Syllabus</button>
         <button className='lat-button'>Previous Year</button>
         <button className='lat-button'>Videos</button>
         <button className='lat-button'>Exam Pattern</button>
         </Link>
      </div>
    <div className='div1'>
      <Link  to="/SBI-circle-based-officer-recruitment-2023">
         <h3>{jobList[7]?.name} {jobList[7]?.date}</h3>
         <button className='lat-button'>Test Series</button>
         <button className='lat-button'>Syllabus</button>
         <button className='lat-button'>Previous Year</button>
         <button className='lat-button'>Videos</button>
         <button className='lat-button'>Exam Pattern</button>
         </Link>
      </div>
    <div className='div1'>
      <Link  to="/SBI-JUNIOR-ASSOCIATES-CLERK-FOR-POST-8283-2023">
         <h3>{jobList[6]?.name} {jobList[6]?.date}</h3>
         <button className='lat-button'>Test Series</button>
         <button className='lat-button'>Syllabus</button>
         <button className='lat-button'>Previous Year</button>
         <button className='lat-button'>Videos</button>
         <button className='lat-button'>Exam Pattern</button>
         </Link>
      </div>
      <div className='div1'>
      <Link  to="/SIDBI-grade-A(Assistent-manager-recruitment-2023) -SIDBI-Advent-No.04-/Grade-A/2023-24-/-short-Details-of-notification.">
         <h3>{jobList[5]?.name} {jobList[5]?.date}</h3>
         <button className='lat-button'>Test Series</button>
         <button className='lat-button'>Syllabus</button>
         <button className='lat-button'>Previous Year</button>
         <button className='lat-button'>Videos</button>
         <button className='lat-button'>Exam Pattern</button>
         </Link>
      </div>
      <div className='div1'>
      <Link  to="/Airport-Authority-of-India-AAI-Recruitment-2023-Apply-Online-Junior-Executive-496-Post">
         <h3>{jobList[4]?.name} {jobList[4]?.date}</h3>
         <button className='lat-button'>Test Series</button>
         <button className='lat-button'>Syllabus</button>
         <button className='lat-button'>Previous Year</button>
         <button className='lat-button'>Videos</button>
         <button className='lat-button'>Exam Pattern</button>
         </Link>
      </div>
      <div className='div1'>
      <Link  to="/Railway-Banaras-Locomotive-Works-BLW-Apprentices-2023-Apply-Online-for-374-Post">
         <h3>{jobList[3]?.name} {jobList[3]?.date}</h3>
         <button className='lat-button'>Test Series</button>
         <button className='lat-button'>Syllabus</button>
         <button className='lat-button'>Previous Year</button>
         <button className='lat-button'>Videos</button>
         <button className='lat-button'>Exam Pattern</button>
         </Link>
      </div>
      <div className='div1'>
      <Link  to="/AIIMS-Deoghar-Non-Faculty-Recruitment-2023-Apply-Online-91-Various-Post-Form">
         <h3>{jobList[2]?.name} {jobList[2]?.date}</h3>
         <button className='lat-button'>Test Series</button>
         <button className='lat-button'>Syllabus</button>
         <button className='lat-button'>Previous Year</button>
         <button className='lat-button'>Videos</button>
         <button className='lat-button'>Exam Pattern</button>
         </Link>
      </div>
      <div className='div1'>
      <Link  to="/UPPSC-Additional-Private-Secretary-Recruitment-2023-Apply-Online-for-328-Post">
         <h3>{jobList[1]?.name}{jobList[1]?.date}</h3>
         <button className='lat-button'>Test Series</button>
         <button className='lat-button'>Syllabus</button>
         <button className='lat-button'>Previous Year</button>
         <button className='lat-button'>Videos</button>
         <button className='lat-button'>Exam Pattern</button>
         </Link>
      </div>
      <div className='div1'>
      <Link  to="/Jobid65432be3b3e4541526ab5051">
         <h3>{jobList[0]?.name} {jobList[0]?.date}</h3>
         <button className='lat-button'>Test Series</button>
         <button className='lat-button'>Syllabus</button>
         <button className='lat-button'>Previous Year</button>
         <button className='lat-button'>Videos</button>
         <button className='lat-button'>Exam Pattern</button>
         </Link>
      </div>
      </div>
      </div> 
      <Footer/>
    </>
    
  )
}



