import React from 'react'
import { Link } from 'react-router-dom'
import { useEffect, useState } from 'react'
import axios from 'axios'
import NavbarComponent from '../NavbarComponent'
import Footer from '../Footer'
import Heading from '../Heading'
import { Helmet } from 'react-helmet'
import './css/Latestjob.css'
import './Jobs/govermentjob/JobPage.css'
import loader from '../assets/loader.gif'
export default function Results() {
  
  const [isloading, setloading] = useState(true);
  const [resultList, setresultList] = useState([]);

  useEffect(() => {
    //console.log("hello")
    axios.get(' https://naukridekho-backend-92571da7a098.herokuapp.com/result')
      .then(res => {
        //console.log(res.data.resultData);
        setresultList(res.data.resultData)
        setloading(false);
      })
      .catch(err => {
        console.log(err);
        setloading(false);
      })
  }, []);

  return (
    <>
    <Helmet>
    <title>Results</title>
    <script async src="https://pagead2.googlesyndication.com/pagead/js/adsbygoogle.js?client=ca-pub-9504527545697458"
     crossorigin="anonymous"></script>
    <meta name='description'
    content="get updates of latest Result "
    />
    <meta name='keyword'
    content="result,latest result,government job result,"
    />
    
    <link rel="canonical" href='/Results'/>
   </Helmet>
      <NavbarComponent />
      <Heading />
      {isloading &&
        <img style={{ width: '300px', marginLeft: '38%' }} src={loader} />
      }
      {!isloading &&
        <div className='lat-page-body'>
          <div className='job-body'>
          <div className='div1'>
              <Link to="/BSEB-Bihar-Sakshamta-Pariksha-Result-2024">
                <h3>{resultList[82]?.name} {resultList[82]?.date}</h3>
              </Link>
            </div>
          <div className='div1'>
              <Link to="/MHA-IB-ACIO-2023-Result">
                <h3>{resultList[81]?.name} {resultList[81]?.date}</h3>
              </Link>
            </div>
          <div className='div1'>
              <Link to="/IIT-GATE-2024-Result-Score-Card">
                <h3>{resultList[80]?.name} {resultList[80]?.date}</h3>
              </Link>
            </div>
          <div className='div1'>
              <Link to="/NIC-Scientific-and-Technical-Post-Result-2024">
                <h3>{resultList[79]?.name} {resultList[79]?.date}</h3>
              </Link>
            </div>
          <div className='div1'>
              <Link to="/Patna-High-Court-Stenographer-2023-Final-Result">
                <h3>{resultList[78]?.name} {resultList[78]?.date}</h3>
              </Link>
            </div>
          <div className='div1'>
              <Link to="/Chandigarh-Police-Constable-IT-Result-2024">
                <h3>{resultList[77]?.name} {resultList[77]?.date}</h3>
              </Link>
            </div>
          <div className='div1'>
              <Link to="/Intelligence-Bureau-IB-SA/MT-and-MTS-Result-2024">
                <h3>{resultList[76]?.name} {resultList[76]?.date}</h3>
              </Link>
            </div>
          <div className='div1'>
              <Link to="/UKPSC-RO-ARO-2023-Pre-Result-Cutoff-Marks">
                <h3>{resultList[75]?.name} {resultList[75]?.date}</h3>
              </Link>
            </div>
          <div className='div1'>
              <Link to="/MHA-IB-SA-MTS-2022-Final-Result">
                <h3>{resultList[74]?.name} {resultList[74]?.date}</h3>
              </Link>
            </div>
          <div className='div1'>
              <Link to="/SBI-Apprentices-2023-Final-Result">
                <h3>{resultList[73]?.name} {resultList[73]?.date}</h3>
              </Link>
            </div>
          <div className='div1'>
              <Link to="/UPPSC-Staff-Nurse-2023-Pre-Result">
                <h3>{resultList[72]?.name} {resultList[72]?.date}</h3>
              </Link>
            </div>
          <div className='div1'>
              <Link to="/UPSSSC-Junior-Engineer-2018-Supplementary-Result">
                <h3>{resultList[71]?.name} {resultList[71]?.date}</h3>
              </Link>
            </div>
          <div className='div1'>
              <Link to="/SBI-Clerk-Pre-Result-2024">
                <h3>{resultList[70]?.name} {resultList[70]?.date}</h3>
              </Link>
            </div>
          <div className='div1'>
              <Link to="/CTET-January-2024-Result">
                <h3>{resultList[69]?.name} {resultList[69]?.date}</h3>
              </Link>
            </div>
          <div className='div1'>
              <Link to="/Bihar-BTSC-ANM-2022-Result-/-Score-Card">
                <h3>{resultList[68]?.name} {resultList[68]?.date}</h3>
              </Link>
            </div>
          <div className='div1'>
              <Link to="/DFCCIL-Various-Post-2023-Stage-II-Result">
                <h3>{resultList[67]?.name} {resultList[67]?.date}</h3>
              </Link>
            </div>
          <div className='div1'>
              <Link to="/BLW-Railway-Apprentices-2023-Merit-List">
                <h3>{resultList[66]?.name} {resultList[66]?.date}</h3>
              </Link>
            </div>
          <div className='div1'>
              <Link to="/Delhi-High-Court-Judicial-Services-Pre-2023-Result-with-Marks">
                <h3>{resultList[65]?.name} {resultList[65]?.date}</h3>
              </Link>
            </div>
          <div className='div1'>
              <Link to="/EMRS-Junior-Secretariat-Assistant-2023-Additional-Result">
                <h3>{resultList[64]?.name} {resultList[64]?.date}</h3>
              </Link>
            </div>
          <div className='div1'>
              <Link to="/AAI-Junior-Executive-ATS-2023-Result">
                <h3>{resultList[63]?.name} {resultList[63]?.date}</h3>
              </Link>
            </div>
          <div className='div1'>
              <Link to="/Allahabad-High-Court-APS-2021-Cutoff">
                <h3>{resultList[62]?.name} {resultList[62]?.date}</h3>
              </Link>
            </div>
          <div className='div1'>
              <Link to="/UPPCL-Technician-TG-II-Electrical-2022-Result">
                <h3>{resultList[61]?.name} {resultList[61]?.date}</h3>
              </Link>
            </div>
          <div className='div1'>
              <Link to="/MPESB-Various-Agriculture-Post-Result-2024">
                <h3>{resultList[60]?.name} {resultList[60]?.date}</h3>
              </Link>
            </div>
          <div className='div1'>
              <Link to="/IBPS-PO-13th-Mains-Result-2024">
                <h3>{resultList[59]?.name} {resultList[59]?.date}</h3>
              </Link>
            </div>
          <div className='div1'>
              <Link to="/NTA-UGC-NET-December-2023-Result">
                <h3>{resultList[58]?.name} {resultList[58]?.date}</h3>
              </Link>
            </div>
          <div className='div1'>
              <Link to="/IBPS-SO-13th-Pre-Result-2024">
                <h3>{resultList[57]?.name} {resultList[57]?.date}</h3>
              </Link>
            </div>
          <div className='div1'>
              <Link to="/SBI-PO-2023-Mains-Phase-II-Result">
                <h3>{resultList[56]?.name} {resultList[56]?.date}</h3>
              </Link>
            </div>
          <div className='div1'>
              <Link to="/SBI-PO-2023-Mains-Phase-II-Result">
                <h3>{resultList[55]?.name} {resultList[55]?.date}</h3>
              </Link>
            </div>
          <div className='div1'>
              <Link to="/CISF-Constable-Tradesman-2022-Result">
                <h3>{resultList[54]?.name} {resultList[54]?.date}</h3>
              </Link>
            </div>
          <div className='div1'>
              <Link to="/NTA-UGC-NET-/-JRF-December-2023-Exam-Result-Date">
                <h3>{resultList[53]?.name} {resultList[53]?.date}</h3>
              </Link>
            </div>
          <div className='div1'>
              <Link to="/UPSSSC-Instructor-2022-Eligibility-Result">
                <h3>{resultList[52]?.name} {resultList[52]?.date}</h3>
              </Link>
            </div>
          <div className='div1'>
              <Link to="/Railway-ECR-Apprentice-2023-Result">
                <h3>{resultList[51]?.name} {resultList[51]?.date}</h3>
              </Link>
            </div>
          <div className='div1'>
              <Link to="/SSC-Junior-Engineer-JE-2023-Final-Result">
                <h3>{resultList[50]?.name} {resultList[50]?.date}</h3>
              </Link>
            </div>
          <div className='div1'>
              <Link to="/IBPS-RRB-XII-Office-Assistant-Mains-Result-2024">
                <h3>{resultList[49]?.name} {resultList[49]?.date}</h3>
              </Link>
            </div>
          <div className='div1'>
              <Link to="/NTA-EPFO-SSA-2023-Final-Result">
                <h3>{resultList[48]?.name} {resultList[48]?.date}</h3>
              </Link>
            </div>
          <div className='div1'>
              <Link to="/UP-NHM-Staff-Nurse-2022-Result-(17000 Post)">
                <h3>{resultList[47]?.name} {resultList[47]?.date}</h3>
              </Link>
            </div>
          <div className='div1'>
              <Link to="/CISF-ASI-Steno-/-HC-Ministerial-2022-CBT-Exam-Result">
                <h3>{resultList[46]?.name} {resultList[46]?.date}</h3>
              </Link>
            </div>
          <div className='div1'>
              <Link to="/SSC-Delhi-Police-HC-Ministerial-Final-Result-2023">
                <h3>{resultList[44]?.name} {resultList[44]?.date}</h3>
              </Link>
            </div>
          <div className='div1'>
              <Link to="/SSC-MTS-/-Havaldar-2023-Final-Result-with-Marks">
                <h3>{resultList[43]?.name} {resultList[43]?.date}</h3>
              </Link>
            </div>
          <div className='div1'>
              <Link to="/NCR-Railway-RRC-Prayagraj-Apprentices-Merit-List-2023">
                <h3>{resultList[42]?.name} {resultList[42]?.date}</h3>
              </Link>
            </div>
          <div className='div1'>
              <Link to="/Pageidd658720737b2c7cdff234d1b2">
                <h3>{resultList[41]?.name} {resultList[41]?.date}</h3>
              </Link>
            </div>
          <div className='div1'>
              <Link to="/Pageid6585c1ecf952fb0d800b6249">
                <h3>{resultList[40]?.name} {resultList[40]?.date}</h3>
              </Link>
            </div>
          <div className='div1'>
              <Link to="/Pageid6585bdedf952fb0d800b622f">
                <h3>{resultList[39]?.name} {resultList[39]?.date}</h3>
              </Link>
            </div>
          <div className='div1'>
              <Link to="/Pageid65845f51351fbc71550574b5">
                <h3>{resultList[38]?.name} {resultList[38]?.date}</h3>
              </Link>
            </div>
          <div className='div1'>
              <Link to="/Pageid65845e2e351fbc715505749d">
                <h3>{resultList[37]?.name} {resultList[37]?.date}</h3>
              </Link>
            </div>
          <div className='div1'>
              <Link to="/Pageid65845ca0351fbc715505749b">
                <h3>{resultList[36]?.name} {resultList[36]?.date}</h3>
              </Link>
            </div>
          <div className='div1'>
              <Link to="/Pageid65832f55f531b4b750ae34e6">
                <h3>{resultList[35]?.name} {resultList[35]?.date}</h3>
              </Link>
            </div>
          <div className='div1'>
              <Link to="/Pageid65832baff531b4b750ae34e2">
                <h3>{resultList[34]?.name} {resultList[34]?.date}</h3>
              </Link>
            </div>
          <div className='div1'>
              <Link to="/Pageid65832baff531b4b750ae34e2">
                <h3>{resultList[33]?.name} {resultList[33]?.date}</h3>
              </Link>
            </div>
          <div className='div1'>
              <Link to="/Pageid65810f1180e67a73f78e3822">
                <h3>{resultList[32]?.name} {resultList[32]?.date}</h3>
              </Link>
            </div>
          <div className='div1'>
              <Link to="/Pageid65810c3880e67a73f78e37ec">
                <h3>{resultList[31]?.name} {resultList[31]?.date}</h3>
              </Link>
            </div>
          <div className='div1'>
              <Link to="/Pageid657c7c6291e3a09a6b3b1a23">
                <h3>{resultList[30]?.name} {resultList[30]?.date}</h3>
              </Link>
            </div>
          <div className='div1'>
              <Link to="/Pageid657b39ec82fbf0c0e7cb199c">
                <h3>{resultList[29]?.name} {resultList[29]?.date}</h3>
              </Link>
            </div>
          <div className='div1'>
              <Link to="/Pageid657adfc179d7362543de9d4c">
                <h3>{resultList[28]?.name} {resultList[28]?.date}</h3>
              </Link>
            </div>
          <div className='div1'>
              <Link to="/Pageid657886e60398ae575fa2f02c">
                <h3>{resultList[27]?.name} {resultList[27]?.date}</h3>
              </Link>
            </div>
          <div className='div1'>
              <Link to="/Pageid6575e23a868f14a1b3cf10bd">
                <h3>{resultList[26]?.name} {resultList[26]?.date}</h3>
              </Link>
            </div>
          <div className='div1'>
              <Link to="/Pageid6575e06d868f14a1b3cf1078">
                <h3>{resultList[25]?.name} {resultList[25]?.date}</h3>
              </Link>
            </div>
          <div className='div1'>
              <Link to="/Pageid6575de5f868f14a1b3cf1045">
                <h3>{resultList[24]?.name} {resultList[24]?.date}</h3>
              </Link>
            </div>
          <div className='div1'>
              <Link to="/Pageid65748f5e7b4ad8e67ef3c12f">
                <h3>{resultList[23]?.name} {resultList[23]?.date}</h3>
              </Link>
            </div>
          <div className='div1'>
              <Link to="/Pageid6573483a62f25dfc835fdefa">
                <h3>{resultList[22]?.name} {resultList[22]?.date}</h3>
              </Link>
            </div>
          <div className='div1'>
              <Link to="/Pageid6573308c62f25dfc835fdeb4">
                <h3>{resultList[21]?.name} {resultList[21]?.date}</h3>
              </Link>
            </div>
          <div className='div1'>
              <Link to="/Pageid6570b35b08506fd46394e80a">
                <h3>{resultList[20]?.name} {resultList[20]?.date}</h3>
              </Link>
            </div>
          <div className='div1'>
              <Link to="/Pageid6570aa0408506fd46394e66e">
                <h3>{resultList[19]?.name} {resultList[19]?.date}</h3>
              </Link>
            </div>
          <div className='div1'>
              <Link to="/Pageid6570a38f08506fd46394e551">
                <h3>{resultList[18]?.name} {resultList[18]?.date}</h3>
              </Link>
            </div>
          <div className='div1'>
              <Link to="/Pageid656f66e89ab81286ba2ffcb1">
                <h3>{resultList[17]?.name} {resultList[17]?.date}</h3>
              </Link>
            </div>
          <div className='div1'>
              <Link to="/Pageid656f54ea9ab81286ba2ff87">
                <h3>{resultList[16]?.name} {resultList[16]?.date}</h3>
              </Link>
            </div>
          <div className='div1'>
              <Link to="/Pageid656f51c59ab81286ba2ff86e">
                <h3>{resultList[15]?.name} {resultList[15]?.date}</h3>
              </Link>
            </div>
          <div className='div1'>
              <Link to="/Pageid656f30939ab81286ba2ff5e5">
                <h3>{resultList[14]?.name} {resultList[14]?.date}</h3>
              </Link>
            </div>
          <div className='div1'>
              <Link to="/Pageid656e0b96335f485f1bbeb9e0">
                <h3>{resultList[13]?.name} {resultList[13]?.date}</h3>
              </Link>
            </div>
          <div className='div1'>
              <Link to="/Pageid656ca7590beb0bfb3ea0718b">
                <h3>{resultList[12]?.name} {resultList[12]?.date}</h3>
              </Link>
            </div>
          <div className='div1'>
              <Link to="/Pageid656b61cc8f20c17da5b5e750">
                <h3>{resultList[11]?.name} {resultList[11]?.date}</h3>
              </Link>
            </div>
          <div className='div1'>
              <Link to="/Pageid65695fcf8b86ad1249ca4823">
                <h3>{resultList[10]?.name} {resultList[10]?.date}</h3>
              </Link>
            </div>
          <div className='div1'>
              <Link to="/Pageid656630ed28ba3728112ce88d">
                <h3>{resultList[9]?.name} {resultList[9]?.date}</h3>
              </Link>
            </div>
          <div className='div1'>
              <Link to="/Pageid65662eb128ba3728112ce88b">
                <h3>{resultList[8]?.name} {resultList[8]?.date}</h3>
              </Link>
            </div>
          <div className='div1'>
              <Link to="/Pageid656169f1eabef241d18f9544">
                <h3>{resultList[7]?.name} {resultList[7]?.date}</h3>
              </Link>
            </div>
          <div className='div1'>
              <Link to="/Pageid6560bb1b2c391fde12d3c447">
                <h3>{resultList[6]?.name} {resultList[6]?.date}</h3>
              </Link>
            </div>
          <div className='div1'>
              <Link to="/Pageid655d392bf5a4b9f8210a6703">
                <h3>{resultList[5]?.name} {resultList[5]?.date}</h3>
              </Link>
            </div>
          <div className='div1'>
              <Link to="/Pageid6547312c2441dbd7091f7d3e">
                <h3>{resultList[4]?.name} {resultList[4]?.date}</h3>
              </Link>
            </div>
          <div className='div1'>
              <Link to="/Pageid65472dd32441dbd7091f7d2b">
                <h3>{resultList[3]?.name} {resultList[3]?.date}</h3>
              </Link>
            </div>
          <div className='div1'>
              <Link to="/Pageid654701287b9877dc01782f3a">
                <h3>{resultList[2]?.name} {resultList[2]?.date}</h3>
              </Link>
            </div>
          <div className='div1'>
              <Link to="/Pageid6546fe907b9877dc01782f38">
                <h3>{resultList[1]?.name} {resultList[1]?.date}</h3>
              </Link>
            </div>
          <div className='div1'>
              <Link to="/Pageid6546817c929bd0db6475825">
                <h3>{resultList[0]?.name} {resultList[0]?.date}</h3>
              </Link>
            </div>
         
          </div>
        </div>
      }
      <Footer />
    </>

  )
}



